import React, { useEffect, useState } from "react";
import { ImageBackground } from "react-native";
import constants from "../config/constants";

function AppImageBackground({ source, ...otherProps }) {
  const [uri, setUri] = useState(null);
  useEffect(() => {
    setUri(
      source.uri
        ? source.uri.startsWith("http://")
          ? source.uri
              .replace("http://", "https://")
              .startsWith(constants.serverHttpBaseUrl.slice(0, 30))
            ? source.uri.replace("http://", "https://")
            : source.uri
          : source.uri
        : null
    );
  }, []);

  if (!source) return null;

  return <ImageBackground source={uri ? { uri } : source} {...otherProps} />;
}

export default AppImageBackground;
