import React from "react";
import { StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import AppTextInput from "../inputs/AppTextInput";
import AppErrorMessage from "./AppErrorMessage";
import numToEN from "../../tools/numToEN";

function AppFormField({
  name,
  enDigit = false,
  prefixLabel = undefined,
  ...otherProps
}) {
  const { errors, setFieldTouched, touched, setFieldValue, values } =
    useFormikContext();

  return (
    <>
      <AppTextInput
        onChangeText={(text) =>
          setFieldValue(name, enDigit ? numToEN(text) : text)
        }
        value={values[name]}
        onBlur={() => setFieldTouched(name)}
        prefixLabel={prefixLabel}
        {...otherProps}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({});

export default AppFormField;
