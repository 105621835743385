import { Alert, Platform } from "react-native";

import I18n from "../i18n";

function AppAlert2B(title, descrition, onPressAccept, onPressCancel) {
  if (Platform.OS === "web") {
    const res = window.confirm(`${title}\n${descrition}`);
    if (res) onPressAccept();
    else onPressCancel();
  }
  return Alert.alert(title, descrition, [
    {
      text: I18n.t("public.accept"),
      onPress: onPressAccept,
      style: "destructive",
    },
    {
      text: I18n.t("public.cancel"),
      onPress: onPressCancel,
      style: "cancel",
    },
  ]);
}

export default AppAlert2B;
