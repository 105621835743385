import React, { useEffect, useState, useContext } from "react";
import { StyleSheet, View, Linking, Platform } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { startActivityAsync, ActivityAction } from "expo-intent-launcher";
import Constants from "expo-constants";

import AppScreen from "../../components/AppScreen";
import AppOutlineIcon from "../../components/AppOutlineIcon";
import { AppListItem, AppListItemSeparator } from "../../components/lists";
import AppAlert2B from "../../components/AppAlert2B";
import useApi from "../../hooks/useApi";
import userApi from "../../api/user";
import colors from "../../config/colors";
import routes from "../../config/routes";
import useAuth from "../../auth/useAuth";
import constants from "../../config/constants";
import authStorage from "../../auth/storage";
import AuthContext from "../../auth/context";
import AppText from "../../components/AppText";
import AppFlatList from "../../components/AppFlatList";
import I18n from "../../i18n";

function AccountScreen({ navigation }) {
  const menuItems = [
    {
      title: I18n.t("account.qrCode"),
      icon: { name: "qr-code", backgroundColor: colors.success },
      space: true,
      targetScreen: routes.QR_CODE,
      visible: true,
    },
    {
      title: I18n.t("account.changePassword"),
      icon: { name: "lock-open", backgroundColor: colors.darkGreen },
      space: false,
      targetScreen: routes.PASSWORD_CHANGE,
      visible: true,
    },
    {
      title: I18n.t("account.changePhoneNumber"),
      icon: { name: "phone-iphone", backgroundColor: colors.darkRed },
      space: false,
      targetScreen: routes.PHONE_NUMBER_CHANGE,
      visible: true,
    },
    {
      title: I18n.t("account.sessionManagement"),
      icon: { name: "vpn-key", backgroundColor: colors.dark },
      space: false,
      targetScreen: routes.SESSION_MANAGEMENT,
      visible: true,
    },
    {
      title: I18n.t("account.selectLanguage"),
      icon: { name: "language", backgroundColor: colors.primaryop },
      space: false,
      targetScreen: routes.LANGUAGE_SELECT,
      visible: true,
    },
    {
      title: I18n.t("account.settings"),
      icon: { name: "settings", backgroundColor: colors.secondary },
      space: false,
      targetScreen: "",
      visible: Platform.OS !== "web",
    },
    {
      title: I18n.t("account.help"),
      icon: { name: "help", backgroundColor: colors.primary },
      space: true,
      targetScreen: "",
      visible: true,
    },
    {
      title: I18n.t("account.onlineChat"),
      icon: { name: "chat", backgroundColor: colors.yellow },
      space: false,
      targetScreen: routes.CHAT,
      visible: true,
    },
    {
      title: I18n.t("account.aboutUS"),
      icon: { name: "info-outline", backgroundColor: colors.info },
      space: false,
      targetScreen: "",
      visible: true,
    },
    {
      title: I18n.t("account.logout"),
      icon: { name: "logout", backgroundColor: colors.warning },
      space: true,
      targetScreen: "",
      visible: true,
    },
    // {
    //   title: I18n.t("account.deleteAccount"),
    //   icon: { name: "delete-forever", backgroundColor: colors.danger },
    //   space: true,
    //   targetScreen: "",
    //   visible: true,
    // },
  ];

  const { logOut } = useAuth();
  const isFocused = useIsFocused();
  const [user, setUser] = useState(null);
  const getUserInfoApi = useApi(userApi.getUserInfo);
  const logoutApi = useApi(userApi.logout);
  const deteleAcountApi = useApi(userApi.deteleAcount);
  const { setIsFirst } = useContext(AuthContext);

  useEffect(() => {
    if (isFocused) getUserInfo();
  }, [isFocused]);

  const getUserInfo = async () => {
    const response = await getUserInfoApi.request();
    if (response.ok) setUser(response.data);
  };

  const handleDeleteAccount = async () => {
    const response = await deteleAcountApi.request();
    if (response.ok) logOut();
  };

  const handleLogout = async () => {
    const response = await logoutApi.request({
      token: await authStorage.getToken(),
    });
    if (response.ok) logOut();
  };

  if (!user) return null;

  async function handleSelectItem(item) {
    switch (item.title) {
      case I18n.t("account.aboutUS"):
        await Linking.openURL(constants.websiteUrlAboutUs);
        return;
      case I18n.t("account.help"):
        setIsFirst(true);
        return;
      case I18n.t("account.settings"):
        if (Constants.manifest.extra.panel)
          return startActivityAsync(ActivityAction.SETTINGS);
        return Linking.openSettings();
      case I18n.t("account.logout"):
        return AppAlert2B(
          item.title,
          I18n.t("confirm.userLogout"),
          () => handleLogout(),
          () => null
        );
      case I18n.t("account.deleteAccount"):
        return AppAlert2B(
          item.title,
          I18n.t("confirm.userDelete"),
          () => handleDeleteAccount(),
          () => null
        );

      default:
        navigation.navigate(item.targetScreen);
    }
  }
  return (
    <AppScreen>
      <View style={styles.profileContainer}>
        <AppListItem
          title={user.first_name + " " + user.last_name}
          subTitle={user.phone_number}
          imageUrl={user.profile_pic}
          imageSize={70}
          onPress={() =>
            handleSelectItem({
              title: I18n.t("account.profile"),
              targetScreen: routes.PROFILE,
            })
          }
        />
      </View>
      <View style={styles.listContainer}>
        <AppFlatList
          data={menuItems}
          keyExtractor={(item) => item.title}
          ItemSeparatorComponent={AppListItemSeparator}
          renderItem={({ item }) =>
            item.visible ? (
              <AppListItem
                title={item.title}
                space={item.space}
                onPress={() => handleSelectItem(item)}
                IconComponent={
                  <AppOutlineIcon
                    name={item.icon.name}
                    backgroundColor={item.icon.backgroundColor}
                    type={item.icon.type}
                  />
                }
              />
            ) : null
          }
          ListFooterComponent={() => (
            <View style={styles.footer}>
              <AppText style={styles.versionText}>
                {Constants.manifest.name +
                  " " +
                  I18n.t("app.version") +
                  " " +
                  Constants.manifest.version}
              </AppText>
            </View>
          )}
        />
      </View>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  profileContainer: {
    width: "100%",
  },
  listContainer: {
    width: "100%",
    flex: 1,
  },
  footer: {
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5,
  },
  versionText: {
    color: colors.primary,
    textAlign: "center",
    fontSize: 9,
  },
});

export default AccountScreen;
