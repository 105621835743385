import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { AppListItem, AppListItemSeparator } from "../../components/lists";
import useApi from "../../hooks/useApi";
import productApi from "../../api/product";
import routes from "../../config/routes";
import AppFlatList from "../../components/AppFlatList";

function ProductList({ route, navigation }) {
  const [products, setProducts] = useState([]);
  const getProductListApi = useApi(productApi.getProductList);
  const { category } = route.params;

  useEffect(() => {
    navigation.setOptions({
      title: category.name,
    });
    getProductList();
  }, []);

  const getProductList = async () => {
    const response = await getProductListApi.request(category.pk);
    if (response.ok) setProducts(response.data);
  };

  return (
    <View style={styles.listContainer}>
      <AppFlatList
        data={products}
        keyExtractor={(item) => String(item.pk)}
        ItemSeparatorComponent={AppListItemSeparator}
        renderItem={({ item }) => (
          <AppListItem
            title={item.name}
            imageUrl={item.product_pic}
            imageSize={50}
            onPress={() =>
              navigation.navigate(routes.PRODUCT_DETAIL, { product: item })
            }
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    width: "100%",
    flex: 1,
  },
});

export default ProductList;
