import regData from "./registration";

const store = async (url, data) => {
  if (url.includes("family/deep/")) {
    const regs = data.zones.flat().map((item) => item.registrations);
    regs.flat().forEach((reg) => {
      regData.store(reg.pk, reg);
    });
  }
};

export default { store };
