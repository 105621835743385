import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { HuePicker, AlphaPicker, CirclePicker } from "react-color";

import colors from "../../../../config/colors";
import AppText from "../../../../components/AppText";
import routes from "../../../../config/routes";
import AppIcon from "../../../../components/AppIcon";
import AppCircleButton from "../../../../components/buttons/AppCircleButton";
import DeviceModal from "../DeviceModal";
import UserWsInstance from "../../../../websocket/user";
import registrationData from "../../../../data/registration";

function RGBStripDevice({ registration, reg, getRegData, float }) {
  const [visible, setVisible] = useState(false);
  const [channel, setChannel] = useState({ value: 0, data: {} });
  const navigation = useNavigation();

  useEffect(() => {
    setChannel(reg.device.channels[0]);
  }, [reg]);

  const onPressPower = (value) => {
    onPress({ value: value, data: channel.data });
  };
  const onColorChange = (data) => {
    const red = data.rgb["r"];
    const green = data.rgb["g"];
    const blue = data.rgb["b"];
    const brightness = data.rgb["a"] * 100;

    onPress({ value: channel.value, data: { red, green, blue, brightness } });
  };

  const onPress = async (content) => {
    const response = await UserWsInstance.send(
      {
        command: "update_state",
        pk: reg.pk,
        index: channel.index,
        ...content,
      },
      {
        regIDSub: reg.reg_id_sub,
        mdnsDomain: reg.device.mdns_domain,
        localIP: reg.device.local_ip,
      }
    );
    if (response.type === "LocalAPI") {
      if (response.ok) {
        await registrationData.updateChannel(reg.pk, {
          ...channel,
          ...content,
        });
        await getRegData();
      } else {
        await registrationData.offlineReg(reg.pk);
        await getRegData();
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.longTouch}
        onPress={() => setVisible(reg.device.is_online)}
        onLongPress={() =>
          navigation.navigate(routes.REGISTRATION_DETAIL, {
            registrationPK: registration.pk,
          })
        }
      >
        <View style={styles.container}>
          <View style={[styles.top, float && { flexDirection: "column" }]}>
            {!float && (
              <View style={styles.right}>
                <AppIcon
                  type="MaterialCommunityIcons"
                  name={
                    reg.device.is_online ? "cloud-check" : "cloud-off-outline"
                  }
                  style={styles.status}
                  color={reg.device.is_online ? colors.primary : colors.medium}
                  size={25}
                />
                <AppText numberOfLines={1} style={styles.name}>
                  {registration.name}
                </AppText>
              </View>
            )}
            <View style={styles.left}>
              <AppCircleButton
                onPress={onPressPower}
                value={channel.value}
                disabled={!reg.device.is_online}
                onColor={colors.primary}
                offColor={colors.medium}
                icon={channel.value ? "power" : "power-cycle"}
                text={undefined}
                withSound={true}
                width={50}
                height={50}
                type="MaterialCommunityIcons"
                debounce={800}
              />
            </View>
          </View>
        </View>
        {!reg.device.is_online && float && <View style={styles.offline} />}
      </TouchableOpacity>
      <DeviceModal setVisible={setVisible} visible={visible}>
        <TouchableOpacity activeOpacity={1} style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name={
                  reg.device.is_online ? "cloud-check" : "cloud-off-outline"
                }
                style={styles.status}
                color={reg.device.is_online ? colors.primary : colors.medium}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
          </View>
          {/* <View style={styles.power}>
            <AppCircleButton
              onPress={onPressPower}
              value={channel.value}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={channel.value ? "power" : "power-cycle"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="MaterialCommunityIcons"
              debounce={800}
            />
          </View> */}
          <View style={styles.bottom}>
            <HuePicker
              color={{
                r: channel.data.red,
                g: channel.data.green,
                b: channel.data.blue,
                a: channel.data.brightness * 0.01,
              }}
              onChange={onColorChange}
            />
            <View style={{ height: 10 }} />
            <AlphaPicker
              color={{
                r: channel.data.red,
                g: channel.data.green,
                b: channel.data.blue,
                a: channel.data.brightness * 0.01,
              }}
              onChange={onColorChange}
            />
            <View style={{ height: 10 }} />
            <CirclePicker
              color={{
                r: channel.data.red,
                g: channel.data.green,
                b: channel.data.blue,
                a: channel.data.brightness * 0.01,
              }}
              onChange={onColorChange}
            />
          </View>
        </TouchableOpacity>
      </DeviceModal>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    width: "100%",
  },
  longTouch: {
    borderRadius: 10,
    backgroundColor: colors.light,
  },
  top: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {},
  right: {
    flex: 0.4,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  status: {
    margin: 10,
  },
  left: {
    flex: 0.6,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: 10,
  },
  power: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 10,
  },
  bottom: {
    marginHorizontal: 10,
    marginVertical: 5,
    // flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  offline: {
    backgroundColor: colors.black,
    opacity: 0.6,
    position: "absolute",
    height: 90,
    width: 90,
    overflow: "hidden",
    borderRadius: 10,
  },
});
export default RGBStripDevice;
