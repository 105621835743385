import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native";

import {
  AppListItemSeparator,
  AppListItem,
} from "../../../../components/lists";
import colors from "../../../../config/colors";
import routes from "../../../../config/routes";
import AppOutlineIcon from "../../../../components/AppOutlineIcon";
import registrationApi from "../../../../api/registration";
import useApi from "../../../../hooks/useApi";
import AppLoadingIndicator from "../../../../components/lotties/AppLoadingIndicator";
import AppFlatList from "../../../../components/AppFlatList";
import I18n from "../../../../i18n";

function RegistrationDetail({ route, navigation }) {
  const { registrationPK } = route.params;
  const [registration, setRegistration] = useState(undefined);
  const getRegistrationApi = useApi(registrationApi.getRegistration);

  useEffect(() => {
    getRegistration();
  }, []);

  const getRegistration = async () => {
    const response = await getRegistrationApi.request(registrationPK);
    if (response.ok) setRegistration(response.data);
    else setRegistration(null);
  };

  if (registration === null) return null;
  if (registration === undefined) return <AppLoadingIndicator visible />;

  let menuItems = [
    {
      title: I18n.t("home.deviceAbout"),
      icon: { name: "info-outline", backgroundColor: colors.info },
      space: false,
      onClick: () =>
        navigation.navigate(routes.REGISTRATION_ABOUT, { registration }),
    },
  ];

  if (registration.permission.edit)
    menuItems = [
      ...menuItems,
      {
        title: I18n.t("home.deviceEdit"),
        icon: { name: "edit", backgroundColor: colors.darkGreen },
        space: false,
        onClick: () =>
          navigation.navigate(routes.REGISTRATION_EDIT, { registration }),
      },
    ];

  if (registration.permission.edit && registration.device.is_online) {
    menuItems = [
      ...menuItems,
      {
        title: I18n.t("home.deviceUpdate"),
        icon: { name: "sync", backgroundColor: colors.darkRed },
        space: false,
        onClick: () =>
          navigation.navigate(routes.REGISTRATION_UPDATE, { registration }),
      },
    ];
  }

  return (
    <AppFlatList
      data={menuItems}
      keyExtractor={(item) => item.title}
      ItemSeparatorComponent={AppListItemSeparator}
      renderItem={({ item }) => (
        <AppListItem
          title={item.title}
          space={item.space}
          onPress={item.onClick}
          IconComponent={
            <AppOutlineIcon
              name={item.icon.name}
              backgroundColor={item.icon.backgroundColor}
            />
          }
        />
      )}
    />
  );
}

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    flexDirection: "row-reverse",
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "space-between",
    backgroundColor: colors.white,
  },
});

export default RegistrationDetail;
