import React from "react";
import Lottie from "react-lottie";
import { Dimensions } from "react-native";

function AppQRScanner() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/animations/qr_scanner.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const dim =
    Dimensions.get("screen").height > Dimensions.get("screen").width
      ? Dimensions.get("screen").width
      : Dimensions.get("screen").height;

  return (
    <Lottie options={defaultOptions} width={dim * 0.8} height={dim * 0.8} />
  );
}

export default AppQRScanner;
