import React, { useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";

import AppText from "../../../../components/AppText";
import colors from "../../../../config/colors";
import AppIcon from "../../../../components/AppIcon";
import AppIconButton from "../../../../components/buttons/AppIconButton";
import useApi from "../../../../hooks/useApi";
import actionApi from "../../../../api/action";

function PlugActionCard({ action, handleDelete, getActionList }) {
  const updateActionApi = useApi(actionApi.updateAction);
  const durList = [
    -1, 5, 10, 20, 30, 60, 120, 300, 600, 1200, 1800, 2700, 3600,
  ];
  const [duration, setDuration] = useState(action.data.duration);

  const handleChangeValue = async () => {
    const response = await updateActionApi.request(action.pk, {
      value: action.value === 0 ? 1 : 0,
    });
    if (response.ok) getActionList();
  };

  const handleChangeDuration = async () => {
    let pos = durList.indexOf(duration);
    if (pos + 1 === durList.length) pos = -1;
    const newDur = durList[pos + 1];
    if (newDur !== -1) {
      const response = await updateActionApi.request(action.pk, {
        data: { ...action.data, duration: newDur },
      });
      if (response.ok) getActionList();
    }
    setDuration(newDur);
  };

  return (
    <View
      style={[styles.container, updateActionApi.loading && { opacity: 0.3 }]}
    >
      <View style={styles.right}>
        <AppText style={styles.title} numberOfLines={1}>
          {action.registration.name}
        </AppText>
      </View>

      <View style={styles.left}>
        <TouchableOpacity
          style={styles.durationContainer}
          onPress={handleChangeDuration}
        >
          {duration > 0 && (
            <AppText style={styles.durText}>
              {Math.floor(duration / 60)
                ? Math.floor(duration / 60) + " min"
                : duration + " s"}
            </AppText>
          )}
          <AppIcon
            size={30}
            name={duration > 0 ? "timer" : "timer-off"}
            color={colors.primary}
          />
        </TouchableOpacity>

        <TouchableOpacity onPress={handleChangeValue}>
          <View style={styles.stateContainer}>
            <AppIcon
              name={action.value ? "power-plug" : "power-plug-outline"}
              type="MaterialCommunityIcons"
              size={30}
              color={action.value ? colors.primary : colors.primary}
            />
          </View>
        </TouchableOpacity>
        <AppIconButton
          size={30}
          name="delete"
          color={colors.danger}
          style={styles.deleteIcon}
          onPress={handleDelete}
        />
      </View>
      <ActivityIndicator
        animating={updateActionApi.loading}
        color={colors.primary}
        style={styles.loading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    backgroundColor: colors.white,
  },
  title: {
    textAlign: "center",
  },
  stateContainer: {
    flexDirection: "row-reverse",
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    overflow: "hidden",
  },
  durationContainer: {
    flexDirection: "row-reverse",
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    overflow: "hidden",
    alignItems: "center",
  },
  left: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
    flex: 1,
  },
  durText: {
    fontSize: 15,
    marginHorizontal: 3,
  },
  deleteIcon: {
    marginRight: 20,
  },
  loading: {
    position: "absolute",
    right: "50%",
  },
});

export default PlugActionCard;
