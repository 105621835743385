import React from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";

import AppImageRounded from "../../../../components/AppImageRounded";
import AppText from "../../../../components/AppText";
import colors from "../../../../config/colors";
import memberApi from "../../../../api/member";
import useApi from "../../../../hooks/useApi";
import AppAlert2B from "../../../../components/AppAlert2B";
import AppIconButton from "../../../../components/buttons/AppIconButton";
import I18n from "../../../../i18n";

function MemberCard({ data, edit, getMembers, setScannerModal }) {
  const member = data.user ? data.user : data.pk ? data : null;

  if (!member)
    return (
      <View style={styles.container}>
        <AppIconButton
          name="add"
          size={50}
          border
          onPress={() => setScannerModal(true)}
        />
        <AppText style={styles.addText}>
          {I18n.t("home.familyMemberAdd")}
        </AppText>
      </View>
    );

  const updateMemberApi = useApi(memberApi.updateMember);
  const deleteMemberApi = useApi(memberApi.deleteMember);

  const handleRemove = async () => {
    return AppAlert2B(
      I18n.t("confirm.deleteMemberTitle"),
      I18n.t("confirm.deleteMember"),
      () => deleteMember(),
      () => null
    );
  };

  const deleteMember = async () => {
    const response = await deleteMemberApi.request(data.pk);
    if (response.ok) getMembers();
  };

  const handleAdmin = async () => {
    const response = await updateMemberApi.request(data.pk, {
      is_admin: !data.is_admin,
    });
    if (response.ok) getMembers();
  };

  return (
    <View
      style={[
        styles.container,
        (updateMemberApi.loading || deleteMemberApi.loading) && {
          opacity: 0.6,
        },
      ]}
    >
      <AppImageRounded uri={member.profile_pic} size={100} />
      <AppText style={styles.title} numberOfLines={1}>
        {member.first_name + " " + member.last_name}
      </AppText>
      <AppText style={styles.subtitle}>
        {data.is_admin === false
          ? I18n.t("home.familyMember")
          : data.is_admin === true
          ? I18n.t("home.familyAdmin")
          : I18n.t("home.familyOwner")}
      </AppText>

      {data.permission !== undefined && edit && (
        <>
          <AppIconButton
            name="delete-forever"
            color={colors.danger}
            size={25}
            style={styles.delete}
            onPress={handleRemove}
          />
          <AppIconButton
            name="star"
            color={data.is_admin ? colors.success : colors.medium}
            size={25}
            style={styles.admin}
            onPress={handleAdmin}
          />
        </>
      )}

      <ActivityIndicator
        animating={updateMemberApi.loading || deleteMemberApi.loading}
        color={colors.primary}
        style={styles.loading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: 10,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white,
    flex: 1,
    borderRadius: 10,
  },
  title: {
    fontSize: 15,
    marginTop: 5,
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 12,
  },
  delete: {
    position: "absolute",
    left: 10,
    bottom: 10,
  },
  admin: {
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  addText: {
    textAlign: "center",
    fontSize: 12,
    marginTop: 15,
  },
  loading: {
    position: "absolute",
    right: "50%",
  },
});

export default MemberCard;
