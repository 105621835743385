import React from "react";
import { StyleSheet, View } from "react-native";

import AppIcon from "./AppIcon";
import colors from "../config/colors";

function AppOutlineIcon({
  name,
  size = 40,
  backgroundColor = colors.black,
  iconColor = colors.white,
  ...otherProps
}) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AppIcon
        name={name}
        size={size * 0.7}
        color={iconColor}
        {...otherProps}
      />
    </View>
  );
}

const styles = StyleSheet.create({});

export default AppOutlineIcon;
