import React, { useState, useRef, useEffect, useCallback } from "react";
import { StyleSheet, Platform, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { useKeepAwake } from "expo-keep-awake";
import * as SplashScreen from "expo-splash-screen";
import Constants from "expo-constants";
import FlashMessage from "react-native-flash-message";

import AuthNavigator from "./app/navigation/AuthNavigator";
import AppNavigator from "./app/navigation/AppNavigator";
import AuthContext from "./app/auth/context";
import appId from "./app/identifier/appId";
import defaultStyles from "./app/config/styles";
import { StatusBar } from "expo-status-bar";
import useFont from "./app/hooks/useFont";
import getLocation from "./app/hooks/useLocation";
import authStorage from "./app/auth/storage";
import userApi from "./app/api/user";
import appApi from "./app/api/application";
import useApi from "./app/hooks/useApi";
import IntroScreen from "./app/screens/intro/IntroScreen";
import AppAlert1B from "./app/components/AppAlert1B";
import languageStorage from "./app/i18n/storage";
import I18n from "./app/i18n";

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [token, setToken] = useState();
  const [isReady, setIsReady] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const isFont = useFont();
  const flashRef = useRef(null);
  const statusApi = useApi(userApi.status);
  const getLatestAppApi = useApi(appApi.getLatestApp);

  useKeepAwake();

  useEffect(() => {
    appId.createAppId();
    checkAppVersion();
    startAsync();
  }, []);

  const startAsync = async () => {
    await restoreLanguage();
    await restoreToken();
    setIsReady(true);
  };

  const restoreLanguage = async () => {
    await languageStorage.loadLanguage();
  };

  const restoreToken = async () => {
    // await authStorage.removeToken();
    const location = Platform.OS !== "web" ? await getLocation() : undefined;
    const token = await authStorage.getToken();
    const response = await statusApi.request({
      token,
      device_name: Constants.deviceName,
      app_version: Constants.manifest.version,
      location: location,
    });
    if (response.status === 401) {
      await authStorage.removeToken();
      return;
    }
    setToken(token);
  };

  const checkAppVersion = async () => {
    const response = await getLatestAppApi.request(Platform.OS);
    if (response.ok)
      if (response.data.version > Constants.manifest.version)
        return AppAlert1B(
          I18n.t("app.newAppVersion"),
          I18n.t("app.version") +
            response.data.version +
            I18n.t("app.newAppVersionDescription") +
            "\n" +
            response.data.description
        );
  };

  const onLayoutRootView = useCallback(async () => {
    if (isReady && isFont) {
      await SplashScreen.hideAsync();
    }
  }, [isReady]);

  if (!isReady || !isFont) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ flex: 1 }}>
      <AuthContext.Provider value={{ token, setToken, setIsFirst }}>
        <StatusBar style="dark" />
        <NavigationContainer theme={defaultStyles.navigationStyle}>
          {token ? (
            isFirst ? (
              <IntroScreen setIsFirst={setIsFirst} />
            ) : (
              <AppNavigator />
            )
          ) : (
            <AuthNavigator />
          )}
        </NavigationContainer>
        <FlashMessage
          ref={flashRef}
          textStyle={styles.flashText}
          titleStyle={styles.flashTitle}
          hideStatusBar={Platform.OS === "ios"}
        />
      </AuthContext.Provider>
    </View>
  );
}

const styles = StyleSheet.create({
  flashText: {
    ...defaultStyles.textStyle,
    fontSize: 12,
  },
  flashTitle: {
    ...defaultStyles.textStyle,
    fontSize: 15,
  },
});
