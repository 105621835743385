import { useEffect, useState } from "react";
import * as Font from "expo-font";

const useFont = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  async function loadFonts() {
    await Font.loadAsync({
      Font1: require("../assets/fonts/shabnam.ttf"),
      Digital: require("../assets/fonts/digital.ttf"),
    });
    setFontsLoaded(true);
  }

  useEffect(() => {
    loadFonts();
  }, []);

  return fontsLoaded;
};

export default useFont;
