import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment-jalaali";

const prefix = "registration";
const expiryInHours = 365 * 24 * 50; // 50 years!

const store = async (key, value) => {
  try {
    const item = { value, timestamp: Date.now() };
    await AsyncStorage.setItem(prefix + key, JSON.stringify(item));
  } catch (error) {
    console.log(error);
  }
};

const isExpired = (item) => {
  const now = moment(Date.now());
  const storedTime = moment(item.timestamp);
  return now.diff(storedTime, "hours") > expiryInHours;
};

const get = async (key) => {
  try {
    const value = await AsyncStorage.getItem(prefix + key);
    const item = JSON.parse(value);

    if (!item) return null;

    if (isExpired(item)) {
      await AsyncStorage.removeItem(prefix + key);
      return null;
    }

    return item.value;
  } catch (error) {
    console.log(error);
  }
};

const updateChannel = async (regPK, channel) => {
  try {
    let regData = await get(regPK);
    regData.device.channels[channel.index] = channel;
    await store(regPK, regData);
  } catch (error) {
    console.log(error);
  }
};

const updateChannelsList = async (regPK, channels) => {
  try {
    let regData = await get(regPK);
    regData.device.channels = channels;
    await store(regPK, regData);
  } catch (error) {
    console.log(error);
  }
};

const offlineReg = async (regPK) => {
  try {
    let regData = await get(regPK);
    regData.device.is_online = false;
    await store(regPK, regData);
  } catch (error) {
    console.log(error);
  }
};

const onlineReg = async (regPK) => {
  try {
    let regData = await get(regPK);
    regData.device.is_online = true;
    await store(regPK, regData);
  } catch (error) {
    console.log(error);
  }
};

export default {
  store,
  get,
  updateChannel,
  offlineReg,
  onlineReg,
  updateChannelsList,
};
