import client from "./client";

const getCategoryList = () => client.get("category/list");

const getProductList = (categoryPK) => client.get("product/list/" + categoryPK);

const getProductDetail = (productPK) =>
  client.get("product/detail/" + productPK);

export default {
  getCategoryList,
  getProductList,
  getProductDetail,
};
