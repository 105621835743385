import client from "./client";

const getMessageList = () => client.get("message/support");

const postMessage = (formData) => client.post("message/support", formData);

export default {
  getMessageList,
  postMessage,
};
