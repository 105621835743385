import client from "./client";

const getRegistration = (pk) => client.get("registration/" + pk);

const updateRegistration = (pk, formData) =>
  client.put("registration/" + pk, formData);

const createRegistration = (formData) =>
  client.post("registration/0", formData);

const deleteRegistration = (pk) => client.delete("registration/" + pk);

const getRegistrationList = (familyPK) =>
  client.get("registration/list/" + familyPK);

export default {
  getRegistration,
  updateRegistration,
  deleteRegistration,
  createRegistration,
  getRegistrationList,
};
