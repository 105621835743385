import client from "./client";

const checkFirmware = (regPK) => client.get("firmware/update/" + regPK);
const updateFirmware = (regPK, formData) =>
  client.post("firmware/update/" + regPK, formData);

export default {
  checkFirmware,
  updateFirmware,
};
