import React from "react";
import { StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import AppErrorMessage from "./AppErrorMessage";
import AppPickerInput from "../inputs/AppPickerInput";

function AppPickerField({
  name,
  items,
  nameLabel,
  indexLabel,
  imageLabel,
  placeholder,
  onSelect = (item) => null,
  ...otherProps
}) {
  const { errors, touched, setFieldValue, values } = useFormikContext();
  return (
    <>
      <AppPickerInput
        data={items}
        selected={values[name]}
        setSelected={(item) => {
          setFieldValue(name, item);
          onSelect(item);
        }}
        nameLabel={nameLabel}
        indexLabel={indexLabel}
        imageLabel={imageLabel}
        placeholder={placeholder}
        {...otherProps}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({});

export default AppPickerField;
