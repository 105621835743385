import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import * as Yup from "yup";

import {
  AppForm,
  AppFormField,
  AppErrorMessage,
  AppDatePickerField,
} from "../../components/forms";
import AppLoadingIndicator from "../../components/lotties/AppLoadingIndicator";
import userApi from "../../api/user";
import useApi from "../../hooks/useApi";
import AppCircleImagePickerField from "../../components/forms/AppCircleImagePickerField";
import I18n from "../../i18n";
import AccountGoogleCard from "./AccountGoogleCard";

function AccountProfile({ navigation }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(I18n.t("error.required")),
    lastName: Yup.string().required(I18n.t("error.required")),
  });

  const getUserProfileApi = useApi(userApi.getUserProfile);
  const putUserProfileApi = useApi(userApi.putUserProfile);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    const response = await getUserProfileApi.request();
    if (response.ok) setUser(response.data);
  };

  const handleSubmit = async ({
    firstName,
    lastName,
    city,
    birthDate,
    profilePic,
  }) => {
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (city) formData.append("city", city);
    if (birthDate) formData.append("birth_date", birthDate);
    if (user.profile_pic !== profilePic)
      formData.append("profile_pic", profilePic);
    const response = await putUserProfileApi.request(formData);
    if (response.ok) navigation.goBack();
  };

  if (user === null) return null;

  return (
    <AppForm
      initialValues={{
        firstName: user.first_name,
        lastName: user.last_name,
        city: user.city ? user.city : undefined,
        birthDate: user.birth_date ? user.birth_date : undefined,
        profilePic: user.profile_pic,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      submitOnBack
      confirm
    >
      <AppLoadingIndicator visible={putUserProfileApi.loading} />
      <AppErrorMessage
        error={putUserProfileApi.error ? putUserProfileApi.data.error : null}
        visible={putUserProfileApi.error}
      />
      <AppCircleImagePickerField name="profilePic" size={150} />
      <AppFormField
        name="firstName"
        iconName="person"
        placeholder={I18n.t("form.firstName")}
      />
      <AppFormField
        name="lastName"
        iconName="person-search"
        placeholder={I18n.t("form.lastName")}
      />
      <AppDatePickerField
        name="birthDate"
        iconName="date-range"
        title={I18n.t("form.birthDate")}
        minDate="1300-01-01"
      />
      <AppFormField
        name="city"
        iconName="location-city"
        placeholder={I18n.t("form.city")}
      />
      <AccountGoogleCard />
    </AppForm>
  );
}

const styles = StyleSheet.create({});
export default AccountProfile;
