import React from "react";
import { StyleSheet } from "react-native";
import AppScreen from "../../components/AppScreen";
import AppText from "../../components/AppText";
import I18n from "../../i18n";

function PrivacyScreen() {
  return (
    <AppScreen>
      <AppText style={styles.text}>{I18n.t("auth.privacyDesc")} </AppText>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 15,
    textAlign: "center",
    marginHorizontal: 20,
    marginTop: 30,
  },
});
export default PrivacyScreen;
