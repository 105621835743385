import React, { useEffect } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import QRCode from "react-native-qrcode-svg";

import AppText from "../../components/AppText";
import useApi from "../../hooks/useApi";
import userApi from "../../api/user";
import logo from "../../assets/images/icon.png";
import I18n from "../../i18n";

function AccountQrCodePrinter() {
  const userInfoApi = useApi(userApi.getUserInfo);

  useEffect(() => {
    userInfoApi.request();
  }, []);

  return (
    <View style={styles.container}>
      <QRCode
        value={userInfoApi.data.user_id}
        size={
          Dimensions.get("screen").width > Dimensions.get("screen").height
            ? Dimensions.get("screen").height * 0.4
            : Dimensions.get("screen").width * 0.7
        }
        logo={logo}
      />
      <AppText style={styles.text}>
        {I18n.t("home.member.qrCodeDescription")}
      </AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    width: "80%",
  },
});

export default AccountQrCodePrinter;
