import React, { useState } from "react";
import { StyleSheet } from "react-native";

import AppScreen from "../../../components/AppScreen";
import CodeStep from "./CodeStep";
import PhoneNumberStep from "./PhoneNumberStep";
import SignupStep from "./SignupStep";

function SignUpScreen() {
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(0);

  function updateFormData(data) {
    setFormData({ ...formData, ...data });
  }
  function nextStep() {
    if (step < 2) setStep(step + 1);
  }
  function previousStep() {
    if (step > 0) setStep(step - 1);
  }

  const context = { formData, updateFormData, nextStep, previousStep };

  return (
    <AppScreen>
      {step === 0 && <PhoneNumberStep context={context} />}
      {step === 1 && <CodeStep context={context} />}
      {step === 2 && <SignupStep context={context} />}
    </AppScreen>
  );
}

const styles = StyleSheet.create({});

export default SignUpScreen;
