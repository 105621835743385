import React from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import AppButton from "../../../../components/buttons/AppButton";
import colors from "../../../../config/colors";
import useApi from "../../../../hooks/useApi";
import actionApi from "../../../../api/action";
import I18n from "../../../../i18n";

function PlugActionEdit({ reg, scene }) {
  const createActionApi = useApi(actionApi.createAction);
  const navigation = useNavigation();

  const handleSubmit = async (channel, registration) => {
    const response = await createActionApi.request(scene.pk, {
      channel: channel.pk,
      registration: registration.pk,
    });
    if (response.ok) navigation.goBack();
  };

  return (
    <View>
      <AppButton
        color={colors.primary}
        onPress={() => handleSubmit(reg.device.channels[0], reg)}
        title={I18n.t("public.accept")}
      />
    </View>
  );
}
const styles = StyleSheet.create({});

export default PlugActionEdit;
