import React from "react";
import { View, StyleSheet } from "react-native";
import moment from "moment-jalaali";

import AppOutlineIcon from "../../components/AppOutlineIcon";
import AppText from "../../components/AppText";
import colors from "../../config/colors";
import AppIconButton from "../../components/buttons/AppIconButton";
import AppAlert2B from "../../components/AppAlert2B";
import I18n from "../../i18n";

function AccountSessionItem({ session, handleDelete }) {
  return (
    <View style={styles.container}>
      <View style={styles.platformContainer}>
        {["android", "ios"].includes(session.platform) ? (
          <AppOutlineIcon
            name={session.platform === "ios" ? "apple" : "android"}
            type="MaterialCommunityIcons"
            size={50}
            backgroundColor={session.is_online ? colors.success : colors.medium}
          />
        ) : (
          <AppOutlineIcon
            name={session.platform === "web" ? "logo-pwa" : "cube"}
            type="Ionicons"
            size={50}
            backgroundColor={session.is_online ? colors.success : colors.medium}
          />
        )}
        {session.is_online ? (
          <AppText style={styles.online}>{I18n.t("public.online")}</AppText>
        ) : (
          <AppText style={styles.offline}>{I18n.t("public.offline")}</AppText>
        )}
      </View>
      <View style={styles.infoContainer}>
        <AppText style={styles.text1}>{session.device_name}</AppText>
        <AppText style={styles.text2}>
          {I18n.t("app.version") + ": "}
          {session.app_version ? session.app_version : "-"}
        </AppText>
        <AppText style={styles.text3}>
          {I18n.t("app.lastOnline") + (session.last_online ? ": \n" : ": ")}
          {session.last_online
            ? moment(session.last_online, "YYYY-MM-DDTHH:mm:ssZ").format(
                "jYYYY/jMM/jDD - HH:mm"
              )
            : "-"}
        </AppText>
      </View>
      <View style={styles.terminationContainer}>
        <AppIconButton
          name="delete-forever"
          size={40}
          color={session.is_online ? colors.medium : colors.danger}
          disabled={session.is_online}
          onPress={() =>
            AppAlert2B(
              I18n.t("confirm.deleteSessionTitle"),
              I18n.t("confirm.deleteSession"),
              () => handleDelete(session.pk),
              () => null
            )
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    marginVertical: 7,
    borderRadius: 10,
    overflow: "hidden",
    flex: 1,
    flexDirection: "row-reverse",
    paddingVertical: 10,
  },
  platformContainer: {
    flex: 1 / 5,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderLeftWidth: 1,
    borderColor: colors.light,
  },
  infoContainer: {
    flex: 3 / 5,
    flexDirection: "column",
    justifyContent: "center",
    flexDirection: "column",
  },
  terminationContainer: {
    flex: 1 / 5,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRightWidth: 1,
    borderColor: colors.light,
  },

  text1: {
    fontSize: 15,
    textAlign: "right",
    marginRight: 10,
  },
  text2: {
    fontSize: 12,
    textAlign: "right",
    marginRight: 10,
  },
  text3: {
    fontSize: 12,
    textAlign: "right",
    marginRight: 10,
  },

  online: {
    marginTop: 3,
    fontSize: 10,
    color: colors.success,
  },
  offline: {
    marginTop: 3,
    fontSize: 10,
    color: colors.medium,
  },
});

export default AccountSessionItem;
