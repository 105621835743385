export default {
  primary: "#B0233C",
  secondary: "#FFCC00",
  primaryop: "#2DBD57",
  secondaryop: "#808080",

  disabled: "#5555",

  black: "#000",
  white: "#fff",

  medium: "#6e6969",
  light: "#f7f7f7",
  dark: "#0c0c0c",

  danger: "#ff4444",
  success: "#00BF4D",
  warning: "#ffbb33",
  info: "#33b5e5",
  yellow: "#ffa500",
  darkGreen: "#065535",
  darkRed: "#800000",
};
