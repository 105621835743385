import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import SceneList from "./SceneList";
import routes from "../../config/routes";
import colors from "../../config/colors";
import familyData from "../../data/family";
import AppIconButton from "../../components/buttons/AppIconButton";

function SceneScreen() {
  const navigation = useNavigation();
  useEffect(() => {
    addButton();
  }, []);

  const addButton = async () => {
    const family = await familyData.get();
    if (family.permission.edit)
      navigation.setOptions({
        headerRight: () => (
          <AppIconButton
            name="add"
            color_bg={colors.white}
            style={styles.addButton}
            border
            onPress={() =>
              navigation.navigate(routes.SCENE_EDIT, { scene: undefined })
            }
          />
        ),
      });
  };

  return (
    <View style={styles.listContainer}>
      <SceneList />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    alignItems: "center",
    flex: 1,
  },
  addButton: {
    marginHorizontal: 15,
  },
});

export default SceneScreen;
