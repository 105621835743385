import TimePicker from "rc-time-picker";
import moment from "moment-jalaali";
import "rc-time-picker/assets/index.css";

function AppTimePicker({ date, onConfirm }) {
  const format = "h:mm a";
  return (
    <TimePicker
      showSecond={false}
      defaultValue={moment(date)}
      className="xxx"
      onChange={onConfirm}
      format={format}
      use12Hours={true}
      allowEmpty={false}
    />
  );
}

export default AppTimePicker;
