import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import actionApi from "../../api/action";
import useApi from "../../hooks/useApi";
import I18n from "../../i18n";
import AppPickerInput from "../../components/inputs/AppPickerInput";
import PlugActionEdit from "./device/plug/PlugActionEdit";
import AppText from "../../components/AppText";
import Switch2gActionEdit from "./device/switch/Switch2gActionEdit";
import Switch1gActionEdit from "./device/switch/Switch1gActionEdit";
import Switch3gActionEdit from "./device/switch/Switch3gActionEdit";

function ActionCreate({ route, navigation }) {
  const { scene } = route.params;
  const getAvailableActionListApi = useApi(actionApi.getAvailableActionList);
  const [registrations, setRegistrations] = useState([]);
  const [reg, setReg] = useState(undefined);

  useEffect(() => {
    navigation.setOptions({
      title: I18n.t("scenes.actionCreate"),
    });
    getAvailableActionList();
  }, []);

  const getAvailableActionList = async () => {
    const response = await getAvailableActionListApi.request(scene.pk);
    if (response.ok) setRegistrations(response.data);
  };

  if (!registrations) return null;

  return (
    <View style={styles.container}>
      <AppPickerInput
        data={registrations}
        selected={reg}
        setSelected={(item) => {
          setReg(item);
        }}
        nameLabel="name"
        indexLabel="pk"
        imageLabel="device.product.product_pic"
        placeholder={I18n.t("form.deviceName")}
      />
      {reg ? (
        reg.device.product.pk === 1 ? (
          <PlugActionEdit reg={reg} scene={scene} />
        ) : reg.device.product.pk === 2 ? (
          <Switch2gActionEdit reg={reg} scene={scene} />
        ) : reg.device.product.pk === 3 ? (
          <Switch1gActionEdit reg={reg} scene={scene} />
        ) : reg.device.product.pk === 4 ? (
          <Switch3gActionEdit reg={reg} scene={scene} />
        ) : (
          <AppText style={styles.notSuppText}>
            {I18n.t("products.notSupported")}
          </AppText>
        )
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    flexGrow: 1,
  },
  notSuppText: {
    textAlign: "center",
    margin: 20,
    fontSize: 12,
  },
});

export default ActionCreate;
