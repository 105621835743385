import React from "react";
import { StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import AppErrorMessage from "./AppErrorMessage";
import AppDatePickerInput from "../inputs/AppDatePickerInput";

function AppDatePickerField({ name, ...otherProps }) {
  const { errors, setFieldTouched, touched, setFieldValue, values } =
    useFormikContext();

  return (
    <>
      <AppDatePickerInput
        value={values[name]}
        setValue={(data) => setFieldValue(name, data)}
        onBlur={() => setFieldTouched(name)}
        {...otherProps}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({});

export default AppDatePickerField;
