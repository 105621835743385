import React from "react";
// import { FlatList } from "react-native";
import { FlatList } from "react-native-gesture-handler";

function AppFlatList(props) {
  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      nestedScrollEnabled={true}
      {...props}
    />
  );
}

export default AppFlatList;
