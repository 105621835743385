import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import AppText from "../../../../components/AppText";
import AppIconButton from "../../../../components/buttons/AppIconButton";
import colors from "../../../../config/colors";

function CurtainDuration({ data, onPressBtn }) {
  const [dur, setDur] = useState(data.duration);

  const incDur = () => {
    const duration = dur < 301 ? dur + 1 : dur;
    setDur(duration);
    onPressBtn(0, { duration });
  };

  const decDur = () => {
    const duration = dur > 1 ? dur - 1 : dur;
    setDur(duration);
    onPressBtn(0, { duration });
  };

  return (
    <View style={styles.container}>
      <AppIconButton
        type="FontAwesome"
        name="plus-square"
        size={40}
        color={colors.medium}
        style={styles.btn}
        onPress={incDur}
      />
      <AppText style={styles.dur}>{dur} s</AppText>
      <AppIconButton
        type="FontAwesome"
        name="minus-square"
        size={40}
        color={colors.medium}
        style={styles.btn}
        onPress={decDur}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row-reverse",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  dur: {
    fontSize: 20,
    margin: 10,
  },
  btn: {
    margin: 10,
  },
});

export default CurtainDuration;
