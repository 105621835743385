import { create } from "apisauce";

const getModuleInfo = (local_url) => {
  const api = create({
    baseURL: local_url + "/api",
  });

  return api.get("/registration/info");
};

const postCredentials = (local_url, ssid, password, regID) => {
  const api = create({
    baseURL: local_url + "/api",
  });

  const form = new FormData();
  form.append("ssid", ssid);
  form.append("password", password);
  form.append("reg_id", regID);
  return api.post("/registration/credentials", form);
};

export default { getModuleInfo, postCredentials };
