import React from "react";
import { StyleSheet, View } from "react-native";

import AppPickerInput from "../../components/inputs/AppPickerInput";

function HomeFamilyDropdown({ families, selected, setSelected }) {
  return (
    <View style={styles.container}>
      <AppPickerInput
        data={families}
        selected={selected}
        setSelected={setSelected}
        nameLabel="name"
        indexLabel="pk"
        imageLabel="family_pic"
        iconLable="icon_name"
        pushBottom={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
});

export default HomeFamilyDropdown;
