import { create } from "apisauce";
import { Platform } from "react-native";

const TIME_OUT = 5000;

const updateState = (message, data) => {
  const api = create({
    baseURL: Platform.OS === "ios" ? data.mdnsDomain : "http://" + data.localIP,
    timeout: TIME_OUT,
  });
  const form = new FormData();
  form.append("index", message.index);
  form.append("value", message.value);
  form.append("reg_id_sub", data.regIDSub);
  return api.post("/api/state/update", form);
};

const getState = (data) => {
  const api = create({
    baseURL: Platform.OS === "ios" ? data.mdnsDomain : "http://" + data.localIP,
    timeout: TIME_OUT,
  });
  const form = new FormData();
  form.append("reg_id_sub", data.regIDSub);
  return api.post("/api/state/get", form);
};

const updateCredential = (data) => {
  const api = create({
    baseURL: Platform.OS === "ios" ? data.mdnsDomain : "http://" + data.localIP,
    timeout: TIME_OUT,
  });
  const form = new FormData();
  form.append("ssid", data.ssid);
  form.append("password", data.password);
  form.append("reg_id_sub", data.regIDSub);
  return api.post("/api/credential/update", form);
};

export default { updateState, getState, updateCredential };
