import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import AppText from "../AppText";
import colors from "../../config/colors";
import AppGradientView from "../animations/AppGradientView";

function AppButton({
  title,
  color = colors.primary,
  onPress,
  disabled = false,
  style = null,
  textSize = 17,
}) {
  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: color, ...style }]}
      onPress={onPress}
      activeOpacity={0.65}
      disabled={disabled}
    >
      <AppGradientView style={styles.gradient}>
        <AppText style={[styles.text, { fontSize: textSize }]}>{title}</AppText>
      </AppGradientView>
      {disabled && <View style={styles.disabled} />}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: "100%",
    borderRadius: 10,
    marginVertical: 10,
    overflow: "hidden",
  },
  gradient: {
    padding: 10,
  },
  text: {
    color: colors.white,
    textAlign: "center",
  },
  disabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: colors.white,
    opacity: 0.4,
  },
});

export default AppButton;
