import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import DropDownPicker from "react-native-dropdown-picker";

import defaultStyle from "../../config/styles";
import colors from "../../config/colors";
import AppImageRounded from "../../components/AppImageRounded";
import AppIcon from "../AppIcon";

const maxListHeight = 2500;
const itemHeight = 50;

function AppPickerInput({
  data,
  selected,
  setSelected,
  nameLabel,
  indexLabel,
  imageLabel,
  openNOL,
  onOpenNOL,
  placeholder = undefined,
  disabled = false,
  nolap = false,
  pushBottom = true,
  iconLable = undefined,
}) {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    prepareItems();
  }, [data]);

  const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

  const prepareItems = async () => {
    let temp = [];
    await data.forEach((item) => {
      temp.push({
        label: item[nameLabel],
        value: item[indexLabel],
        icon: () =>
          imageLabel && !item[iconLable] ? (
            <AppImageRounded uri={resolvePath(item, imageLabel)} size={42} />
          ) : iconLable ? (
            <AppIcon
              name={item[iconLable]}
              size={42}
              color={colors.dark}
              style={styles.icon}
            />
          ) : null,
      });
    });
    setItems(temp);
  };

  const setItemValue = (callback) => {
    const selected = data.find((item) => item[indexLabel] === callback());
    setSelected(selected);
  };

  return (
    <View
      style={[
        styles.container,
        (open || openNOL) && { zIndex: 2 },
        (open || openNOL) &&
          pushBottom && {
            marginBottom:
              data.length * itemHeight > maxListHeight
                ? maxListHeight
                : data.length * itemHeight,
          },
      ]}
    >
      <DropDownPicker
        open={nolap ? openNOL : open}
        value={selected ? selected[indexLabel] : undefined}
        items={items}
        setOpen={nolap ? onOpenNOL : setOpen}
        setValue={setItemValue}
        containerStyle={styles.pickerContainer}
        textStyle={styles.textStyle}
        style={styles.picker}
        maxHeight={maxListHeight}
        iconContainerStyle={styles.picContainerStyle}
        arrowIconContainerStyle={styles.arrowIconContainerStyle}
        tickIconContainerStyle={styles.arrowIconContainerStyle}
        dropDownDirection="BOTTOM"
        dropDownContainerStyle={styles.dropDownContainerStyle}
        listItemContainerStyle={styles.listItemContainerStyle}
        language="FA"
        placeholder={placeholder}
        placeholderStyle={defaultStyle.textStyle}
        disabled={disabled}
        listMode="SCROLLVIEW"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row-reverse",
    alignItems: "center",
    borderRadius: 10,
    margin: 2,
    marginVertical: 10,
    width: "100%",
    backgroundColor: colors.white,
  },
  picker: {
    borderWidth: 0,
    flexDirection: "row-reverse",
    borderRadius: 10,
    borderColor: colors.primary,
    height: itemHeight,
    width: "100%",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  pickerContainer: {
    flex: 1,
    alignItems: "center",
  },
  textStyle: {
    ...defaultStyle.textStyle,
  },
  picContainerStyle: {
    marginLeft: 10,
    marginRight: 0,
  },
  arrowIconContainerStyle: {
    marginLeft: 0,
    marginRight: 10,
  },
  dropDownContainerStyle: {
    backgroundColor: colors.white,
    borderTopWidth: 0,
    borderWidth: 0,
    borderColor: colors.primary,
    borderRadius: 10,
  },
  listItemContainerStyle: {
    height: itemHeight,
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  icon: {
    borderWidth: 1,
    borderColor: colors.dark,
    borderRadius: 100,
  },
});

export default AppPickerInput;
