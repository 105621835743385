import React from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";
import uuid from "react-native-uuid";

import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppPasswordField,
} from "../../../components/forms";
import useApi from "../../../hooks/useApi";
import registrationApi from "../../../api_local/registration";
import I18n from "../../../i18n";

function ProductWifi({ setRegID, nextStep, productDetail, ssid }) {
  const validationSchema = Yup.object().shape({
    ssid: Yup.string().required(I18n.t("error.required")),
    password: Yup.string().required(I18n.t("error.required")),
  });

  const postCredentialsApi = useApi(registrationApi.postCredentials);

  const handleSubmit = ({ ssid, password }) => {
    const regID = uuid.v4();
    postCredentialsApi.request(
      productDetail.firmware.local_url,
      ssid,
      password,
      regID
    );
    setRegID(regID);
    nextStep();
  };

  return (
    <AppForm
      initialValues={{
        ssid: ssid,
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppLoadingIndicator visible={false} />
      <AppFormField
        name="ssid"
        placeholder={I18n.t("form.wifiName")}
        iconName="router"
      />
      <AppPasswordField
        name="password"
        iconName="lock"
        placeholder={I18n.t("form.password")}
      />
      <View style={styles.button}>
        <AppSubmitButton title={I18n.t("public.accept")} />
      </View>
    </AppForm>
  );
}

const styles = StyleSheet.create({
  button: {
    width: "100%",
    marginVertical: 30,
  },
});

export default ProductWifi;
