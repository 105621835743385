import React from "react";
import DatePicker from "react-datepicker2";

function AppDatePicker({ selected, onDateChange }) {
  return (
    <DatePicker
      onChange={(value) => onDateChange(value)}
      value={selected}
      isGregorian={false}
      timePicker={false}
      showTodayButton={false}
    />
  );
}

export default AppDatePicker;
