import React from "react";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";

import AppIcon from "../AppIcon";
import colors from "../../config/colors";

function AppListItemAction({ onPress, name, color }) {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[styles.container, { backgroundColor: color }]}>
        <AppIcon name={name} size={35} color={colors.white} />
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 70,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default AppListItemAction;
