import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import AppScreen from "../../components/AppScreen";
import { AppListItem, AppListItemSeparator } from "../../components/lists";
import useApi from "../../hooks/useApi";
import productApi from "../../api/product";
import routes from "../../config/routes";
import AppFlatList from "../../components/AppFlatList";

function CategoryList({ navigation }) {
  const [categories, setCategories] = useState([]);
  const getCategoryListApi = useApi(productApi.getCategoryList);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    const response = await getCategoryListApi.request();
    if (response.ok) setCategories(response.data);
  };

  return (
    <AppScreen>
      <View style={styles.listContainer}>
        <AppFlatList
          data={categories}
          keyExtractor={(item) => String(item.pk)}
          ItemSeparatorComponent={AppListItemSeparator}
          renderItem={({ item }) => (
            <AppListItem
              title={item.name}
              imageUrl={item.category_pic}
              imageSize={50}
              onPress={() =>
                navigation.navigate(routes.PRODUCT_LIST, { category: item })
              }
            />
          )}
        />
      </View>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    width: "100%",
    flex: 1,
  },
});

export default CategoryList;
