import React, { useEffect, useState } from "react";
import { ActivityIndicator, StyleSheet, TouchableOpacity } from "react-native";
import { Audio } from "expo-av";

import AppIcon from "../AppIcon";
import AppText from "../AppText";
import beep from "../../assets/sounds/click.wav";

function AppCircleButton({
  text,
  icon,
  value,
  onColor,
  offColor,
  onPress,
  disabled,
  width = 30,
  height = 30,
  withSound = false,
  type = undefined,
  debounce = 0,
}) {
  const [sound, setSound] = useState();
  const [debTime, setDebTime] = useState(false);
  const [dis, setDis] = useState(disabled);

  const playSound = async () => {
    const { sound } = await Audio.Sound.createAsync(beep);
    setSound(sound);
    await sound.playAsync();
  };

  useEffect(() => {
    return sound
      ? () => {
          sound.unloadAsync();
        }
      : undefined;
  }, [sound]);

  const handlePress = () => {
    setDebTime(true);
    setDis(true);

    onPress(value ? 0 : 1);
    if (withSound) playSound();

    setTimeout(() => {
      setDebTime(false);
      setDis(disabled);
    }, debounce * 5);
  };

  useEffect(() => {
    setTimeout(() => {
      setDebTime(false);
      setDis(disabled);
    }, debounce);
  }, [value, disabled]);

  return (
    <TouchableOpacity
      onPress={handlePress}
      activeOpacity={0.65}
      disabled={dis}
      touchSoundDisabled
      style={[
        styles.container,
        {
          borderWidth: 3,
          borderColor: value ? onColor : offColor,
          height: height,
          width: width,
        },
      ]}
    >
      {text ? (
        <AppText>{text}</AppText>
      ) : icon && !debTime ? (
        <AppIcon
          name={icon}
          size={30}
          type={type}
          color={value ? onColor : offColor}
        />
      ) : debTime ? (
        <ActivityIndicator color={value ? onColor : offColor} />
      ) : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
});

export default AppCircleButton;
