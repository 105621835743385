import React from "react";
import AppImage from "./AppImage";

function AppImageRounded({
  uri,
  size,
  borderColor,
  borderWidth,
  style,
  source,
}) {
  return (
    <AppImage
      style={[
        {
          width: size,
          height: size,
          borderRadius: size,
          borderWidth: borderWidth,
          borderColor: borderColor,
        },
        style,
      ]}
      source={source ? source : { uri: uri }}
    />
  );
}

export default AppImageRounded;
