import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
} from "react-native";
import { Formik } from "formik";
import { useFormikContext } from "formik";
import { useNavigation } from "@react-navigation/native";
import { HeaderBackButton } from "@react-navigation/stack";
import AppAlert2B from "../AppAlert2B";
import I18n from "../../i18n";

const SubmitOnBack = ({ isSubmit = false, confirm = false, setIsSubmit }) => {
  const { submitForm } = useFormikContext();
  const navigation = useNavigation();
  useEffect(() => {
    if (isSubmit) {
      if (confirm)
        AppAlert2B(
          I18n.t("confirm.backAndSave"),
          "",
          () => {
            submitForm();
            setIsSubmit(false);
          },
          () => navigation.goBack()
        );
      else {
        submitForm();
        setIsSubmit(false);
      }
    }
  }, [isSubmit, submitForm]);
  return null;
};

function AppForm({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  style = {},
  formRef,
  submitOnBack = false,
  confirm = false,
}) {
  const navigation = useNavigation();
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    submitOnBack &&
      navigation.setOptions({
        headerLeft: (props) => (
          <HeaderBackButton
            {...props}
            onPress={() => {
              setIsSubmit(true);
            }}
          />
        ),
      });
  }, []);

  return (
    <ScrollView
      style={[styles.scrollContainer, style]}
      contentContainerStyle={styles.scrollContent}
      showsVerticalScrollIndicator={false}
      nestedScrollEnabled={true}
      alwaysBounceVertical={false}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
        keyboardVerticalOffset={100}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          ref={formRef}
        >
          {() => (
            <>
              {children}
              {submitOnBack && (
                <SubmitOnBack
                  isSubmit={isSubmit}
                  setIsSubmit={setIsSubmit}
                  confirm={confirm}
                />
              )}
            </>
          )}
        </Formik>
      </KeyboardAvoidingView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },

  scrollContainer: {
    width: "100%",
    paddingHorizontal: 20,
  },
  scrollContent: {
    flexGrow: 1,
    alignItems: "center",
    paddingVertical: 10,
  },
});

export default AppForm;
