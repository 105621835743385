import client from "./client";

const getSceneList = () => client.get("scene/list");

const getScene = (scenePK) => client.get("scene/" + scenePK);

const createScene = (formData) => client.post("scene/0", formData);

const updateScene = (scenePK, formData) =>
  client.put("scene/" + scenePK, formData);

const deleteScene = (scenePK) => client.delete("scene/" + scenePK);

export default {
  getSceneList,
  getScene,
  createScene,
  updateScene,
  deleteScene,
};
