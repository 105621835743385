import React from "react";
import { SafeAreaView, StyleSheet, View, Platform } from "react-native";
import Constants from "expo-constants";

import AppLoadingIndicator from "./lotties/AppLoadingIndicator";
import colors from "../config/colors";

function AppScreen({ children, style, loading }) {
  return (
    <>
      <AppLoadingIndicator visible={loading} />
      {Platform.OS !== "web" && !Constants.manifest.extra.panel ? (
        <SafeAreaView style={[styles.screen]}>
          <View style={[styles.view, style]}>{children}</View>
        </SafeAreaView>
      ) : (
        <View style={[styles.view, style]}>{children}</View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    paddingTop: Constants.statusBarHeight,
    backgroundColor: colors.light,
  },
  view: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default AppScreen;
