import React from "react";
import { StyleSheet, View } from "react-native";
import moment from "moment-jalaali";

import AppText from "../../../../components/AppText";
import { AppListItemSeparator } from "../../../../components/lists";
import colors from "../../../../config/colors";
import AppFlatList from "../../../../components/AppFlatList";
import I18n from "../../../../i18n";

function RegistrationAbout({ route }) {
  const { registration } = route.params;
  const infoList = [
    {
      name: I18n.t("home.registration.about.productName"),
      value: registration.device.product.name,
      index: 1,
    },
    {
      name: I18n.t("home.registration.about.deviceName"),
      value: registration.name,
      index: 2,
    },
    {
      name: I18n.t("home.registration.about.numberOfChannels"),
      value: registration.device.channels.length,
      index: 3,
    },
    {
      name: I18n.t("home.registration.about.family"),
      value: registration.family.name,
      index: 4,
    },
    {
      name: I18n.t("home.registration.about.zone"),
      value: registration.zone.name,
      index: 5,
    },
    {
      name: I18n.t("home.registration.about.status"),
      value: registration.device.is_online ? "متصل" : "غیر متصل",
      index: 6,
    },
    {
      name: I18n.t("home.registration.about.id"),
      value: registration.device.device_id.substring(0, 8),
      index: 7,
    },
    {
      name: I18n.t("home.registration.about.macAddress"),
      value: registration.device.mac_address,
      index: 8,
    },
    {
      name: I18n.t("home.registration.about.version"),
      value: registration.device.firmware.version,
      index: 9,
    },
    {
      name: I18n.t("home.registration.about.signalPower"),
      value: registration.device.rssi ? registration.device.rssi + "%" : "-",
      index: 10,
    },
    {
      name: I18n.t("home.registration.about.localIP"),
      value: registration.device.local_ip ? registration.device.local_ip : "-",
      index: 11,
    },
    {
      name: I18n.t("home.registration.about.warantyStertDate"),
      value: moment(
        registration.device.warranty_start_date,
        "YYYY-M-D"
      ).isValid()
        ? moment(registration.device.warranty_start_date, "YYYY-M-D").format(
            "jYYYY/jM/jD"
          )
        : null,
      index: 12,
    },
    {
      name: I18n.t("home.registration.about.warantyEndDate"),
      value: moment(
        registration.device.warranty_start_date,
        "YYYY-M-D"
      ).isValid()
        ? moment(registration.device.warranty_start_date, "YYYY-M-D")
            .add(registration.device.warranty_duration)
            .format("jYYYY/jM/jD")
        : null,
      index: 13,
    },
  ];

  return (
    <AppFlatList
      data={infoList}
      keyExtractor={(item) => String(item.index)}
      ItemSeparatorComponent={AppListItemSeparator}
      renderItem={({ item }) => (
        <View style={styles.textContainer}>
          <AppText>{item.name}</AppText>
          <AppText>{item.value}</AppText>
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    flexDirection: "row-reverse",
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "space-between",
    backgroundColor: colors.white,
  },
});

export default RegistrationAbout;
