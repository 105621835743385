import React, { useState } from "react";
import { StyleSheet, View, Platform } from "react-native";
import moment from "moment-jalaali";

import AppIcon from "../AppIcon";
import AppText from "../AppText";
import colors from "../../config/colors";
import defaultStyle from "../../config/styles";
import AppTimePicker from "../pickers/AppTimePicker";

function AppTimePickerInput({
  title,
  iconName,
  value,
  setValue,
  width = "100%",
}) {
  const [visible, setVisible] = useState(false);

  return (
    <View style={[styles.main, { width: width }]}>
      <View style={styles.container}>
        {iconName && <AppIcon name={iconName} size={20} style={styles.icon} />}
        <AppText style={styles.text}>{title}</AppText>
        {Platform.OS !== "web" && (
          <View>
            <AppText
              style={styles.timeText}
              onPress={() => setVisible(!visible)}
            >
              {moment(
                moment().format("YYYY-MM-DD") + "T" + value,
                "YYYY-MM-DDTHH:mm:ssZ"
              ).format("HH:mm")}
            </AppText>
          </View>
        )}

        {Platform.OS !== "web" && (
          <AppTimePicker
            date={moment(
              moment().format("YYYY-MM-DD") + "T" + value,
              "YYYY-MM-DDTHH:mm:ssZ"
            ).toDate()}
            isVisible={visible}
            mode="time"
            onConfirm={(value) => {
              setValue(moment(value).set({ second: 0 }).format("HH:mm:ssZ"));
              setVisible(false);
            }}
            onCancel={() => setVisible(false)}
          />
        )}
      </View>
      {Platform.OS === "web" && (
        <AppTimePicker
          date={moment(
            moment().format("YYYY-MM-DD") + "T" + value,
            "YYYY-MM-DDTHH:mm:ssZ"
          ).toDate()}
          onConfirm={(value) => {
            setValue(moment(value).set({ second: 0 }).format("HH:mm:ssZ"));
            setVisible(false);
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    padding: 13,
    marginVertical: 10,
    borderRadius: 10,
  },

  container: {
    width: "100%",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-around",
  },
  text: {
    ...defaultStyle.textStyle,
    textAlign: "right",
    flex: 1,
  },
  timeText: {
    ...defaultStyle.textStyle,
    textAlign: "left",
    color: colors.black,
    fontSize: 16,
  },
  icon: {
    marginLeft: 10,
  },
  picker: {
    flex: 1,
  },
  selectButton: {
    marginVertical: 0,
  },
});

export default AppTimePickerInput;
