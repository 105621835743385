import React, { useRef, useEffect } from "react";
import { StyleSheet, Animated } from "react-native";

function AppGrowView({ toValue, children, style }) {
  const growAnim = useRef(new Animated.Value(0)).current;

  const growAction = () => {
    Animated.timing(growAnim, {
      toValue: toValue,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    growAction();
  });

  return (
    <Animated.View
      style={[
        { transform: [{ scale: growAnim }, { perspective: 1000 }] },
        style,
      ]}
    >
      {children}
    </Animated.View>
  );
}

const styles = StyleSheet.create({});

export default AppGrowView;
