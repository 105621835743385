import React from "react";
import { StyleSheet, Text } from "react-native";
import defaultStyle from "../config/styles";

function AppText({ children, style, ...otherProps }) {
  return (
    <Text style={[defaultStyle.textStyle, style]} {...otherProps}>
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({});

export default AppText;
