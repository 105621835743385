import { create } from "apisauce";
import authStorage from "../auth/storage";
import cache from "../data/cache";
import api from "../data/api";
import constants from "../config/constants";

const TIME_OUT = 2000;
const whiteStatus = [401];

const apiClient = create({
  baseURL: constants.serverHttpBaseUrl,
  timeout: TIME_OUT * 3,
});

apiClient.addAsyncRequestTransform(async (request) => {
  const token = await authStorage.getToken();
  if (!token) return;
  request.headers["Authorization"] = "Token " + token;
});

const get = apiClient.get;
apiClient.get = async (url, params, axiosConfig) => {
  const response = await get(url, params, {
    ...axiosConfig,
    timeout: TIME_OUT,
  });

  if (response.ok || whiteStatus.includes(response.status)) {
    cache.store(url, response.data);
    api.store(url, response.data);
    return response;
  }
  const data = await cache.get(url);
  return data ? { ok: true, data } : response;
};

export default apiClient;
