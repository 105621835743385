import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import UserWsInstance from "../../websocket/user";
import registrationData from "../../data/registration";
import localApi from "../../api_local/local";
import PlugDevice from "./device/plug/PlugDevice";
import Switch2gDevice from "./device/switch/Switch2gDevice";
import Switch1gDevice from "./device/switch/Switch1gDevice";
import Switch3gDevice from "./device/switch/Switch3gDevice";
import CoolerDevice from "./device/cooler/CoolerDevice";
import ThermostatDevice from "./device/thermostat/ThermostatDevice";
import CurtainDevice from "./device/curtain/CurtainDevice";
import RGBStripDevice from "./device/rgb_strip/RGBStripDevice";
import AppText from "../../components/AppText";
import I18n from "../../i18n";
import colors from "../../config/colors";

const TRY_MAX = 5;
const TRY_DUR = 10000;

function HomeDevice({ registration }) {
  const [reg, setReg] = useState(registration);

  useEffect(() => {
    const callback = {
      command: "new_state" + registration.pk,
      func: (data) => updateState(data.registration),
    };
    setReg(registration);
    const getRegDataTimeOut = setTimeout(() => getRegData(), 10);

    UserWsInstance.addCallback(callback);
    UserWsInstance.send({
      command: "get_state",
      pk: registration.pk,
    });
    let pullIter = 0;

    const interval = setInterval(async () => {
      if (UserWsInstance.connected) {
        pullIter = 0;
        return;
      }
      const response = await localApi.getState({
        regIDSub: registration.reg_id_sub,
        mdnsDomain: registration.device.mdns_domain,
        localIP: registration.device.local_ip,
      });
      if (response.ok) {
        await registrationData.updateChannelsList(
          registration.pk,
          response.data
        );
        await registrationData.onlineReg(registration.pk);
        pullIter = 0;
      } else {
        if (pullIter >= TRY_MAX)
          await registrationData.offlineReg(registration.pk);
        else pullIter = pullIter + 1;
      }
      await getRegData();
    }, TRY_DUR);
    return () => {
      UserWsInstance.removeCallback(callback);
      clearInterval(interval);
      clearTimeout(getRegDataTimeOut);
    };
  }, [registration.pk]);

  useEffect(() => {
    const timeOut = setTimeout(() => getRegData(), 1000);
    return clearTimeout(timeOut);
  }, [registration.device.channels]);

  const updateState = async (reg) => {
    if (reg.pk === registration.pk) {
      await registrationData.store(reg.pk, reg);
      await getRegData();
    }
  };

  const getRegData = async () => {
    const regData = await registrationData.get(registration.pk);
    if (regData) setReg(regData);
  };

  const productPK = registration.device.product.pk;

  if (productPK === 1)
    return (
      <PlugDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  if (productPK === 2)
    return (
      <Switch2gDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  // if (productPK === 3)
  //   return (
  //     <Switch1gDevice
  //       registration={registration}
  //       reg={reg}
  //       getRegData={getRegData}
  //     />
  //   );

  // if (productPK === 4)
  //   return (
  //     <Switch3gDevice
  //       registration={registration}
  //       reg={reg}
  //       getRegData={getRegData}
  //     />
  //   );

  if (productPK === 5)
    return (
      <CoolerDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  if (productPK === 6)
    return (
      <ThermostatDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  if (productPK === 4)
    return (
      <CurtainDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  if (productPK === 3)
    return (
      <RGBStripDevice
        registration={registration}
        reg={reg}
        getRegData={getRegData}
      />
    );

  return (
    <View style={styles.notSupportedContainer}>
      <AppText style={styles.txt1}>{registration.name}</AppText>
      <AppText style={styles.txt2}>{I18n.t("products.notSupported")}</AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  txt1: {
    fontSize: 15,
    textAlign: "center",
  },
  txt2: {
    fontSize: 11,
    textAlign: "center",
    marginTop: 10,
  },
  notSupportedContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default HomeDevice;
