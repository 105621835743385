import React from "react";
import { StyleSheet } from "react-native";
import { FlatGrid } from "react-native-super-grid";
import Constants from "expo-constants";

import faImage from "../../assets/images/language/fa.jpeg";
import enImage from "../../assets/images/language/en.jpeg";
import LanguageItem from "./LanguageItem";

const langs = [
  { code: "en", name: "english", img: enImage },
  { code: "fa", name: "farsi", img: faImage },
];

function LanguageSelect() {
  return (
    <FlatGrid
      alwaysBounceVertical={false}
      showsVerticalScrollIndicator={false}
      itemDimension={Constants.manifest.extra.panel ? 200 : 130}
      data={langs}
      spacing={10}
      style={styles.gridView}
      renderItem={({ item }) => <LanguageItem lang={item} />}
    />
  );
}
const styles = StyleSheet.create({
  gridView: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
});

export default LanguageSelect;
