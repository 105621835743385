import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import colors from "../../../../config/colors";
import AppText from "../../../../components/AppText";
import routes from "../../../../config/routes";
import AppIcon from "../../../../components/AppIcon";
import AppCircleButton from "../../../../components/buttons/AppCircleButton";
import DeviceModal from "../DeviceModal";
import UserWsInstance from "../../../../websocket/user";
import registrationData from "../../../../data/registration";

function ThermostatDevice({ registration, reg, getRegData }) {
  const [visible, setVisible] = useState(false);
  const [channel, setChannel] = useState({ value: 0, data: {} });
  const navigation = useNavigation();

  useEffect(() => {
    setChannel(reg.device.channels[0]);
  }, [reg]);

  const onPressFan = () => {
    onPress({
      data: {
        ...channel.data,
        fan: channel.data.fan < 4 ? channel.data.fan + 1 : 0,
        auto: 0,
      },
    });
  };
  const onPressDTempPlus = () => {
    onPress({
      data: { ...channel.data, dtemp: channel.data.dtemp + 1 },
    });
  };
  const onPressDTempMinus = () => {
    onPress({
      data: { ...channel.data, dtemp: channel.data.dtemp - 1 },
    });
  };
  const onPressMode = (mode) => {
    onPress({ data: { ...channel.data, mode } });
  };

  const onPress = async (content) => {
    const response = await UserWsInstance.send(
      {
        command: "update_state",
        pk: reg.pk,
        index: channel.index,
        ...content,
      },
      {
        regIDSub: reg.reg_id_sub,
        mdnsDomain: reg.device.mdns_domain,
        localIP: reg.device.local_ip,
      }
    );
    if (response.type === "LocalAPI") {
      if (response.ok) {
        await registrationData.updateChannel(reg.pk, {
          ...channel,
          ...content,
        });
        await getRegData();
      } else {
        await registrationData.offlineReg(reg.pk);
        await getRegData();
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.longTouch}
        onPress={() => setVisible(reg.device.is_online)}
        onLongPress={() =>
          navigation.navigate(routes.REGISTRATION_DETAIL, {
            registrationPK: registration.pk,
          })
        }
      >
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
            <View style={styles.left}>
              <AppText style={styles.tempS}>
                {channel.data.temp}
                <AppText style={styles.celsi1S}>°C</AppText>
              </AppText>
              <AppCircleButton
                onPress={onPressFan}
                value={channel.data.fan}
                disabled={!reg.device.is_online}
                onColor={colors.primary}
                offColor={colors.medium}
                icon={
                  channel.data.fan === 0
                    ? "fan-off"
                    : channel.data.fan === 1
                    ? "fan-speed-1"
                    : channel.data.fan === 2
                    ? "fan-speed-2"
                    : channel.data.fan === 3
                    ? "fan-speed-3"
                    : channel.data.fan === 4
                    ? "fan-auto"
                    : ""
                }
                text={undefined}
                withSound={true}
                width={50}
                height={50}
                type="MaterialCommunityIcons"
                debounce={800}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <DeviceModal setVisible={setVisible} visible={visible}>
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
          </View>
          <View style={styles.bottom}>
            <AppCircleButton
              onPress={onPressDTempMinus}
              value={channel.data.dtemp}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={"angle-down"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="Fontisto"
              debounce={300}
            />
            <View style={styles.tempContainer}>
              <AppText style={styles.temp}>
                {channel.data.temp}
                <AppText style={styles.celsi1}>°C</AppText>
              </AppText>
              <AppText style={styles.dtemp}>
                <AppText style={styles.setDtemp}>set </AppText>
                {channel.data.dtemp}
                <AppText style={styles.celsi2}>°C</AppText>
              </AppText>
            </View>
            <AppCircleButton
              onPress={onPressDTempPlus}
              value={channel.data.dtemp}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={"angle-up"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="Fontisto"
              debounce={300}
            />
          </View>
          <View style={styles.bottom}>
            <AppCircleButton
              onPress={onPressFan}
              value={channel.data.fan}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={
                channel.data.fan === 0
                  ? "fan-off"
                  : channel.data.fan === 1
                  ? "fan-speed-1"
                  : channel.data.fan === 2
                  ? "fan-speed-2"
                  : channel.data.fan === 3
                  ? "fan-speed-3"
                  : channel.data.fan === 4
                  ? "fan-auto"
                  : ""
              }
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="MaterialCommunityIcons"
              debounce={800}
            />
            <AppCircleButton
              onPress={onPressMode}
              value={channel.data.mode}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.primary}
              icon={
                channel.data.mode === 0
                  ? "snow-sharp"
                  : channel.data.mode === 1
                  ? "md-sunny"
                  : ""
              }
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="Ionicons"
              debounce={800}
            />
          </View>
        </View>
      </DeviceModal>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    width: "100%",
  },
  longTouch: {
    borderRadius: 10,
    backgroundColor: colors.light,
  },
  top: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {},
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  status: {
    margin: 5,
  },
  left: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: 10,
  },
  bottom: {
    marginHorizontal: 10,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  temp: {
    fontSize: 80,
    fontFamily: "Digital",
    color: colors.primary,
  },
  tempS: {
    fontSize: 45,
    fontFamily: "Digital",
    color: colors.primary,
    marginLeft: 10,
  },
  dtemp: {
    fontSize: 40,
    fontFamily: "Digital",
    color: colors.medium,
  },
  celsi1: {
    fontSize: 40,
    fontFamily: "Digital",
    color: colors.primary,
  },
  celsi1S: {
    fontSize: 22,
    fontFamily: "Digital",
    color: colors.primary,
  },
  celsi2: {
    fontSize: 20,
    fontFamily: "Digital",
    color: colors.medium,
  },
  setDtemp: {
    fontSize: 15,
    fontFamily: "Digital",
    color: colors.medium,
  },
  tempContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
});
export default ThermostatDevice;
