import React from "react";
import { StyleSheet } from "react-native";
import SegmentedControlTab from "react-native-segmented-control-tab";
import colors from "../config/colors";

function AppSegmentControlTab({ values, index, setIndex }) {
  return (
    <SegmentedControlTab
      values={values}
      selectedIndex={index}
      onTabPress={(index) => setIndex(index)}
      borderRadius={10}
      tabsContainerStyle={styles.tabsContainerStyle}
      tabStyle={styles.tabStyle}
      tabTextStyle={styles.tabTextStyle}
      activeTabStyle={styles.activeTabStyle}
    />
  );
}

const styles = StyleSheet.create({
  tabsContainerStyle: {
    paddingHorizontal: 20,
    // paddingVertical: 10,
  },
  tabStyle: {
    borderColor: colors.primary,
  },
  tabTextStyle: {
    fontFamily: "Font1",
    fontSize: 13,
    color: colors.primary,
  },
  activeTabStyle: {
    backgroundColor: colors.primary,
  },
});

export default AppSegmentControlTab;
