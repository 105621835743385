import React from "react";
import { StyleSheet, View } from "react-native";
import { useFormikContext } from "formik";

import AppCheckBox from "../AppCheckBox";
import AppErrorMessage from "./AppErrorMessage";

function AppCheckField({ title, name }) {
  const { errors, touched, setFieldValue, values } = useFormikContext();

  return (
    <View style={styles.container}>
      <AppCheckBox
        title={title}
        checked={values[name]}
        onPress={() => setFieldValue(name, !values[name])}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default AppCheckField;
