import * as Location from "expo-location";

const getLocation = async () => {
  try {
    const { granted } = await Location.requestForegroundPermissionsAsync();
    if (!granted) return undefined;
    const lastKnownPosition = await Location.getLastKnownPositionAsync();
    if (!lastKnownPosition) return undefined;
    return lastKnownPosition;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export default getLocation;
