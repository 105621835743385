export default {
  // serverHttpBaseUrl: "http://172.27.5.132/backend/main_app/api",
  // serverWsBaseUrl: "ws://172.27.5.132/backend/ws/main_app/ws",

  serverHttpBaseUrl: "https://dev1.lex-it.io/backend/main_app/api",
  serverWsBaseUrl: "wss://dev1.lex-it.io/backend/ws/main_app/ws",

  websiteUrl: "https://lex-it.io",
  websiteUrlAboutUs: "https://lex-it.io/about-us",
  websiteUrlAgreement: "https://lex-it.io/policies",

  googleOAuth2Config: {
    androidClientId:
      "360057659560-tlhdphi6g4tefv74cqbo07nls9kaekmo.apps.googleusercontent.com",
    iosClientId:
      "360057659560-sgrdm4q9pn01r5996a8sah6mlul0m573.apps.googleusercontent.com",
    expoClientId:
      "360057659560-j8c20pta6cqo8qo88ui12mdv8ihv743l.apps.googleusercontent.com",
    webClientId:
      "360057659560-ev1hih6gortkbm4vs5ksoohilje1da1v.apps.googleusercontent.com",
  },
};
