import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import Constants from "expo-constants";
import { startActivityAsync, ActivityAction } from "expo-intent-launcher";

import AppButton from "../../components/buttons/AppButton";
import AppText from "../../components/AppText";
import AppGrowView from "../../components/animations/AppGrowView";
import colors from "../../config/colors";
import routes from "../../config/routes";
import AppImage from "../../components/AppImage";
import AppImageBackground from "../../components/AppImageBackground";
import AppIconButton from "../../components/buttons/AppIconButton";
import I18n from "../../i18n";

function WelcomeScreen({ navigation }) {
  return (
    <AppImageBackground
      blurRadius={0}
      source={
        Constants.manifest.extra.panel
          ? require("../../assets/backgrounds/welcome2.jpg")
          : require("../../assets/backgrounds/welcome1.jpg")
      }
      style={styles.background}
    >
      <AppGrowView toValue={1} style={styles.logoContainer}>
        <AppImage
          style={styles.logo}
          source={require("../../assets/images/logo.png")}
        />
      </AppGrowView>
      <View style={styles.buttonsContainer}>
        <AppButton
          title={I18n.t("auth.login")}
          color={colors.primary}
          onPress={() => navigation.navigate(routes.LOGIN)}
        />
        <AppButton
          title={I18n.t("auth.signup")}
          color={colors.primaryop}
          onPress={() => navigation.navigate(routes.SIGNUP)}
        />
      </View>
      <AppText style={styles.versionText}>
        {Constants.manifest.name +
          " " +
          I18n.t("app.version") +
          " " +
          Constants.manifest.version}
      </AppText>
      {Constants.manifest.extra.panel && (
        <AppIconButton
          name="settings"
          size={30}
          color={colors.light}
          style={styles.settingButton}
          onPress={() => {
            startActivityAsync(ActivityAction.SETTINGS);
          }}
        />
      )}

      <AppIconButton
        name="language"
        size={30}
        color={colors.light}
        style={styles.languageButton}
        onPress={() => navigation.navigate(routes.LANGUAGE_SELECT)}
      />
    </AppImageBackground>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logoContainer: {
    position: "absolute",
    top: 60,
    alignItems: "center",
  },
  logo: {
    aspectRatio: 2,
    width: "90%",
    height: Dimensions.get("window").height * 0.12,
    maxWidth: 500,
    marginTop: 15,
  },
  buttonsContainer: {
    width: "90%",
    marginBottom: 55,
  },
  versionText: {
    position: "absolute",
    color: colors.white,
    textAlign: "center",
    fontSize: 9,
    bottom: 20,
  },
  settingButton: {
    position: "absolute",
    bottom: 15,
    right: 10,
  },
  languageButton: {
    position: "absolute",
    bottom: 15,
    left: 10,
  },
});

export default WelcomeScreen;
