import React, { useEffect } from "react";
import { Platform } from "react-native";
import Constants from "expo-constants";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import AccountNavigator from "../navigation/AccountNavigator";
import AppIcon from "../components/AppIcon";
import defaultStyles from "../config/styles";
import routes from "../config/routes";
import UserWsInstance from "../websocket/user";
import HomeNavigator from "./HomeNavigator";
import ProductsNavigator from "./ProductsNavigator";
import SceneNavigator from "./SceneNavigator";
import ProductRegistration from "../screens/products/registration/ProductRegistration";
import I18n from "../i18n";
// import useNotifications from "../hooks/useNotifications";

const Tab = createBottomTabNavigator();

function AppNavigator() {
  // useNotifications();

  useEffect(() => {
    wsConnect();
  }, []);

  const wsConnect = async () => {
    await UserWsInstance.connect();
  };

  const getTabBarVisibility = (navigation, name) => {
    if (Platform.OS === "web") return true;
    const current = navigation
      .dangerouslyGetState()
      .routes.find((item) => item.name === name);
    if (current.state) return current.state.index === 0;
    return true;
  };

  if (Platform.OS === "web" && window.location.pathname === "/registration") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mac_address = urlParams.get("mac_address");
    const reg_id = urlParams.get("reg_id");
    if (mac_address && reg_id)
      return (
        <ProductRegistration moduleInfo={{ mac_address }} regID={reg_id} />
      );
  }

  return (
    <Tab.Navigator
      tabBarOptions={defaultStyles.tabBarStyle}
      initialRouteName={routes.HOME}
      backBehavior="initialRoute"
    >
      <Tab.Screen
        name="Account"
        component={AccountNavigator}
        options={({ navigation }) => ({
          title: I18n.t("app.account"),
          tabBarIcon: ({ color, size }) => (
            <AppIcon name="person" color={color} size={size} />
          ),
          tabBarVisible: getTabBarVisibility(navigation, "Account"),
        })}
      />
      {!Constants.manifest.extra.panel && (
        <Tab.Screen
          name="Products"
          component={ProductsNavigator}
          options={({ navigation }) => ({
            title: I18n.t("app.products"),
            tabBarIcon: ({ color, size }) => (
              <AppIcon name="device-hub" color={color} size={size} />
            ),
            tabBarVisible: getTabBarVisibility(navigation, "Products"),
          })}
        />
      )}
      <Tab.Screen
        name="Scenes"
        component={SceneNavigator}
        options={({ navigation }) => ({
          title: I18n.t("app.scenes"),
          tabBarIcon: ({ color, size }) => (
            <AppIcon name="wb-incandescent" color={color} size={size} />
          ),
          tabBarVisible: getTabBarVisibility(navigation, "Scenes"),
        })}
      />
      <Tab.Screen
        name="Home"
        component={HomeNavigator}
        options={({ navigation }) => ({
          title: I18n.t("app.home"),
          tabBarIcon: ({ color, size }) => (
            <AppIcon name="home" color={color} size={size} />
          ),
          tabBarVisible: getTabBarVisibility(navigation, "Home"),
        })}
      />
    </Tab.Navigator>
  );
}

export default AppNavigator;
