import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import defaultStyles from "../config/styles";
import SceneScreen from "../screens/scene/SceneScreen";
import ActionCreate from "../screens/scene/ActionCreate";
import SceneEdit from "../screens/scene/SceneEdit";
import I18n from "../i18n";

const Stack = createStackNavigator();

function SceneNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultStyles.stackScreenStyle}>
      <Stack.Screen
        name="Scenes"
        options={{
          // headerShown: false,
          title: I18n.t("scenes.sceneScreen"),
        }}
        component={SceneScreen}
      />
      <Stack.Screen
        options={{
          title: I18n.t("scenes.sceneEdit"),
        }}
        name={"SceneEdit"}
        component={SceneEdit}
      />
      <Stack.Screen
        options={{
          title: I18n.t("scenes.actionCreate"),
        }}
        name={"ActionCreate"}
        component={ActionCreate}
      />
    </Stack.Navigator>
  );
}

export default SceneNavigator;
