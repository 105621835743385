import React from "react";
import { StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import AppPasswordInput from "../inputs/AppPasswordInput";
import AppErrorMessage from "./AppErrorMessage";

function AppFormField({ name, prefixLabel = undefined, ...otherProps }) {
  const { errors, setFieldTouched, touched, setFieldValue, values } =
    useFormikContext();

  return (
    <>
      <AppPasswordInput
        onChangeText={(text) => setFieldValue(name, text)}
        value={values[name]}
        onBlur={() => setFieldTouched(name)}
        prefixLabel={prefixLabel}
        {...otherProps}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({});

export default AppFormField;
