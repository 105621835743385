import React, { useState } from "react";
import moment from "moment-jalaali";
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
  Modal,
  Platform,
  TouchableOpacity,
} from "react-native";
import colors from "../../config/colors";
import AppText from "../AppText";
import AppIcon from "../AppIcon";
import defaultStyle from "../../config/styles";
import AppDatePicker from "../pickers/AppDatePicker";

function AppDatePickerInput({
  title,
  value,
  setValue,
  iconName,
  minDate = "1400-01-01",
  width = "100%",
}) {
  const [visible, setVisible] = useState(false);

  const onSelect = (date) => {
    setValue(date.format("YYYY-MM-DD"));
    setVisible(false);
  };

  return (
    <>
      <TouchableWithoutFeedback
        onPress={() => Platform.OS !== "web" && setVisible(true)}
      >
        <View style={[styles.main, { width: width }]}>
          <View style={styles.container}>
            {iconName && (
              <AppIcon name={iconName} size={20} style={styles.icon} />
            )}
            <AppText style={styles.text}>{title}</AppText>
            {Platform.OS !== "web" && (
              <View>
                {value && (
                  <AppText style={[styles.dateText]}>
                    {moment(value, "YYYY-MM-DD").format("jYYYY/jMM/jDD")}
                  </AppText>
                )}
              </View>
            )}
            {Platform.OS === "web" && (
              <View style={{ position: "absolute" }}>
                <AppDatePicker
                  selected={value ? moment(value) : moment()}
                  onDateChange={onSelect}
                />
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>

      <Modal
        animationType="slide"
        transparent
        visible={visible}
        onRequestClose={() => setVisible(false)}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          activeOpacity={1}
          onPressOut={() => {
            setVisible(false);
          }}
        >
          {visible && Platform.OS !== "web" && (
            <View style={styles.pickerContainer}>
              <AppDatePicker
                value={value}
                minDate={minDate}
                onSelect={onSelect}
              />
            </View>
          )}
        </TouchableOpacity>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  main: {
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    padding: 13,
    marginVertical: 10,
    borderRadius: 10,
  },
  container: {
    width: "100%",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-around",
  },
  text: {
    ...defaultStyle.textStyle,
    textAlign: "right",
    flex: 1,
  },
  dateText: {
    ...defaultStyle.textStyle,
    textAlign: "left",
    color: colors.black,
    fontSize: 16,
  },
  icon: {
    marginLeft: 10,
  },

  pickerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AppDatePickerInput;
