import React from "react";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Ionicons,
  FontAwesome,
  Fontisto,
} from "@expo/vector-icons";

import colors from "../config/colors";
import { View } from "react-native";

function AppIcon({
  name,
  size,
  color = colors.medium,
  type = "MaterialIcons",
  style,
  border = false,
  radius = 1000,
}) {
  const styleContainer = border && {
    borderRadius: radius,
    borderColor: color,
    borderWidth: 1,
  };

  if (type === "MaterialIcons")
    return (
      <View style={styleContainer}>
        <MaterialIcons name={name} size={size} style={style} color={color} />
      </View>
    );

  if (type === "FontAwesome")
    return (
      <View style={styleContainer}>
        <FontAwesome name={name} size={size} style={style} color={color} />
      </View>
    );

  if (type === "Fontisto")
    return (
      <View style={styleContainer}>
        <Fontisto name={name} size={size} style={style} color={color} />
      </View>
    );

  if (type === "MaterialCommunityIcons")
    return (
      <View style={styleContainer}>
        <MaterialCommunityIcons
          name={name}
          size={size}
          style={style}
          color={color}
        />
      </View>
    );

  if (type === "Ionicons")
    return (
      <View style={styleContainer}>
        <Ionicons name={name} size={size} style={style} color={color} />
      </View>
    );

  return null;
}

export default AppIcon;
