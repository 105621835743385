import React from "react";
import { View, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";

import AppText from "../../components/AppText";
import AppIconButton from "../../components/buttons/AppIconButton";
import routes from "../../config/routes";
import I18n from "../../i18n";

function HomeZoneAdd({ familyPK }) {
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <AppIconButton
        name="add"
        size={60}
        border
        onPress={() =>
          navigation.navigate(routes.ZONE_EDIT, {
            familyPK,
            zonePK: null,
          })
        }
      />
      <AppText style={styles.text}>{I18n.t("home.zoneCreate")}</AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: 16,
    marginTop: 30,
  },
});

export default HomeZoneAdd;
