import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native";
import NetInfo from "@react-native-community/netinfo";

import AppScreen from "../../../components/AppScreen";
import ProductBlink from "./ProductBlink";
import ProductConnection from "./ProductConnection";
import ProductWifi from "./ProductWifi";
import ProductRegistration from "./ProductRegistration";
import AppWizardIndicator from "../../../components/AppWizardIndicator";

function RegistrationWizard({ productDetail }) {
  const [index, setIndex] = useState(0);
  const [moduleInfo, setModuleInfo] = useState({});
  const [regID, setRegID] = useState();
  const [ssid, setSSID] = useState(undefined);

  useEffect(() => {
    NetInfo.fetch().then((state) => {
      if (state.details.ssid !== null && state.details.ssid !== undefined)
        setSSID(state.details.ssid);
      else setSSID("");
    });
  }, []);

  const nextStep = () => {
    setIndex(index + 1);
  };

  const resetStep = () => {
    setIndex(0);
  };

  if (ssid === undefined) return null;

  return (
    <AppScreen>
      {index === 0 && (
        <ProductBlink productDetail={productDetail} nextStep={nextStep} />
      )}
      {index === 1 && (
        <ProductConnection
          productDetail={productDetail}
          setModuleInfo={setModuleInfo}
          nextStep={nextStep}
        />
      )}
      {index === 2 && (
        <ProductWifi
          nextStep={nextStep}
          setRegID={setRegID}
          productDetail={productDetail}
          ssid={ssid}
        />
      )}
      {index === 3 && (
        <ProductRegistration
          resetStep={resetStep}
          moduleInfo={moduleInfo}
          regID={regID}
        />
      )}
      <AppWizardIndicator total={4} now={index} />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  animationContainer: {
    flex: 1,
    width: "100%",
  },
  text: {
    marginBottom: 50,
    textAlign: "center",
  },
});

export default RegistrationWizard;
