import client from "./client";

const getMemberList = (familyPK) => client.get("member/list/" + familyPK);
const getMember = (pk) => client.get("member/" + pk);
const deleteMember = (pk) => client.delete("member/" + pk);
const createMember = (familyPK, formData) =>
  client.post("member/" + familyPK, formData);
const updateMember = (pk, formData) => client.put("member/" + pk, formData);

export default {
  getMemberList,
  getMember,
  deleteMember,
  createMember,
  updateMember,
};
