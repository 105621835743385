import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import familyApi from "../../../api/family";
import useApi from "../../../hooks/useApi";
import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import {
  AppErrorMessage,
  AppForm,
  AppCircleImagePickerField,
  AppFormField,
} from "../../../components/forms";
import FamilyMembers from "./FamilyMembers";
import colors from "../../../config/colors";
import AppAlert2B from "../../../components/AppAlert2B";
import routes from "../../../config/routes";
import I18n from "../../../i18n";
import AppIconButton from "../../../components/buttons/AppIconButton";
import familyData from "../../../data/family";

function FamilySettings({ route, navigation }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(I18n.t("error.required")),
  });

  const { family } = route.params;
  const updateFamilyApi = useApi(familyApi.updateFamily);
  const createFamilyApi = useApi(familyApi.createFamily);
  const deleteFamilyApi = useApi(familyApi.deleteFamily);

  useEffect(() => {
    navigation.setOptions({
      title: family ? family.name : I18n.t("home.familyCreate"),
      headerRight: () =>
        family &&
        (family.permission.delete ? (
          <AppIconButton
            name="delete-forever"
            color={colors.white}
            style={styles.delete}
            onPress={() => handleAction("delete")}
            size={25}
          />
        ) : family.permission.leave ? (
          <AppIconButton
            name="exit-to-app"
            color={colors.white}
            style={styles.delete}
            onPress={() => handleAction("leave")}
            size={25}
          />
        ) : null),
    });
  }, []);

  const handleAction = (action) => {
    switch (action) {
      case "delete": {
        return AppAlert2B(
          I18n.t("confirm.deleteFamilyTitle"),
          I18n.t("confirm.deleteFamily"),
          () => handleDelete(),
          () => null
        );
      }
      case "leave": {
        return AppAlert2B(
          I18n.t("confirm.leaveFamilyTitle"),
          I18n.t("confirm.leaveFamily"),
          () => handleDelete(),
          () => null
        );
      }
    }
  };

  const handleDelete = async () => {
    await deleteFamilyApi.request(family.pk);
    await familyData.remove();
    navigation.reset({
      index: 0,
      routes: [{ name: routes.HOME }],
    });
  };

  const handleSubmit = async ({ name, familyPic }) => {
    const formData = new FormData();
    formData.append("name", name);
    if (!family) formData.append("family_pic", familyPic);
    else if (family.family_pic !== familyPic)
      formData.append("family_pic", familyPic);
    if (family) await updateFamilyApi.request(family.pk, formData);
    else await createFamilyApi.request(formData);
    navigation.reset({
      index: 0,
      routes: [{ name: routes.HOME }],
    });
  };

  return (
    <>
      <AppForm
        initialValues={{
          name: family ? family.name : "",
          familyPic: family ? family.family_pic : "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        style={styles.formStyle}
        submitOnBack={family ? family.permission?.edit : true}
        confirm={!family}
      >
        <AppLoadingIndicator
          visible={createFamilyApi.loading || updateFamilyApi.loading}
        />
        <AppErrorMessage
          error={
            createFamilyApi.error
              ? createFamilyApi.data.error
              : updateFamilyApi.error
              ? updateFamilyApi.data.error
              : null
          }
          visible={createFamilyApi.error || updateFamilyApi.error}
        />
        <AppCircleImagePickerField
          name="familyPic"
          size={150}
          disabled={family ? !family.permission.edit : false}
        />

        <AppFormField
          name="name"
          iconName="family-restroom"
          placeholder={I18n.t("form.familyName")}
          editable={family ? family.permission.edit : true}
        />
      </AppForm>
      {family && <FamilyMembers familyPK={family.pk} />}
    </>
  );
}

const styles = StyleSheet.create({
  formStyle: {
    flexGrow: 0,
  },
  delete: {
    marginRight: 10,
  },
});

export default FamilySettings;
