import AsyncStorage from "@react-native-async-storage/async-storage";

const prefix = "selected_family";

const store = async (value) => {
  try {
    const item = { value, timestamp: Date.now() };
    await AsyncStorage.setItem(prefix, JSON.stringify(item));
  } catch (error) {
    console.log(error);
  }
};

const get = async () => {
  try {
    const value = await AsyncStorage.getItem(prefix);
    const item = JSON.parse(value);

    if (!item) return null;

    return item.value;
  } catch (error) {
    console.log(error);
  }
};

const remove = async () => {
  try {
    await AsyncStorage.removeItem(prefix);
  } catch (error) {
    console.log(error);
  }
};

export default { store, get, remove };
