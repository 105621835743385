import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import colors from "../../../../config/colors";
import AppText from "../../../../components/AppText";
import routes from "../../../../config/routes";
import AppIcon from "../../../../components/AppIcon";
import AppCircleButton from "../../../../components/buttons/AppCircleButton";
import DeviceModal from "../DeviceModal";
import UserWsInstance from "../../../../websocket/user";
import registrationData from "../../../../data/registration";

function CoolerDevice({ registration, reg, getRegData }) {
  const [visible, setVisible] = useState(false);
  const [channel, setChannel] = useState({ value: 0, data: {} });
  const navigation = useNavigation();

  useEffect(() => {
    setChannel(reg.device.channels[0]);
  }, [reg]);

  const onPressPower = (value) => {
    onPress({ value: value, data: channel.data });
  };
  const onPressPump = (pump) => {
    onPress({ value: channel.value, data: { ...channel.data, pump } });
  };
  const onPressFan1 = (fan1) => {
    onPress({ value: channel.value, data: { ...channel.data, fan: fan1 } });
  };
  const onPressFan2 = (fan2) => {
    onPress({
      value: channel.value,
      data: { ...channel.data, fan: fan2 ? 2 : 0 },
    });
  };

  const onPress = async (content) => {
    const response = await UserWsInstance.send(
      {
        command: "update_state",
        pk: reg.pk,
        index: channel.index,
        ...content,
      },
      {
        regIDSub: reg.reg_id_sub,
        mdnsDomain: reg.device.mdns_domain,
        localIP: reg.device.local_ip,
      }
    );
    if (response.type === "LocalAPI") {
      if (response.ok) {
        await registrationData.updateChannel(reg.pk, {
          ...channel,
          ...content,
        });
        await getRegData();
      } else {
        await registrationData.offlineReg(reg.pk);
        await getRegData();
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.longTouch}
        onPress={() => setVisible(true)}
        onLongPress={() =>
          navigation.navigate(routes.REGISTRATION_DETAIL, {
            registrationPK: registration.pk,
          })
        }
      >
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
            <View style={styles.left}>
              <AppCircleButton
                onPress={onPressPower}
                value={channel.value}
                disabled={!reg.device.is_online}
                onColor={colors.primary}
                offColor={colors.medium}
                icon={channel.value ? "power" : "power-cycle"}
                text={undefined}
                withSound={true}
                width={50}
                height={50}
                type="MaterialCommunityIcons"
                debounce={800}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <DeviceModal setVisible={setVisible} visible={visible}>
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
            <View style={styles.left}>
              <AppCircleButton
                onPress={onPressPower}
                value={channel.value}
                disabled={!reg.device.is_online}
                onColor={colors.primary}
                offColor={colors.medium}
                icon={channel.value ? "power" : "power-cycle"}
                text={undefined}
                withSound={true}
                width={50}
                height={50}
                type="MaterialCommunityIcons"
                debounce={800}
              />
            </View>
          </View>
          <View style={styles.bottom}>
            <AppCircleButton
              onPress={onPressPump}
              value={channel.data.pump && channel.value}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={"pump"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="MaterialCommunityIcons"
              debounce={800}
            />
            <AppCircleButton
              onPress={onPressFan1}
              value={channel.data.fan === 1 && channel.value}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={"fan-speed-1"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="MaterialCommunityIcons"
              debounce={800}
            />
            <AppCircleButton
              onPress={onPressFan2}
              value={channel.data.fan === 2 && channel.value}
              disabled={!reg.device.is_online}
              onColor={colors.primary}
              offColor={colors.medium}
              icon={"fan-speed-2"}
              text={undefined}
              withSound={true}
              width={50}
              height={50}
              type="MaterialCommunityIcons"
              debounce={800}
            />
          </View>
        </View>
      </DeviceModal>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    width: "100%",
  },
  longTouch: {
    borderRadius: 10,
    backgroundColor: colors.light,
  },
  top: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {},
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  status: {
    margin: 5,
  },
  left: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: 10,
  },
  bottom: {
    marginHorizontal: 10,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
export default CoolerDevice;
