import client from "./client";

const getGoogleAccount = () => client.get("user/google_account");
const createGoogleAccount = (access_token) =>
  client.post("user/google_account", { access_token });
const deleteGoogleAccount = () => client.delete("user/google_account");

export default {
  getGoogleAccount,
  createGoogleAccount,
  deleteGoogleAccount,
};
