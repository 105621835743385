import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import defaultStyles from "../config/styles";
import CategoryList from "../screens/products/CategoryList";
import ProductList from "../screens/products/ProductList";
import ProductDetail from "../screens/products/ProductDetail";
import I18n from "../i18n";

const Stack = createStackNavigator();

function ProductsNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultStyles.stackScreenStyle}>
      <Stack.Screen
        name="Products"
        options={{
          headerShown: false,
          title: I18n.t("products.categoryList"),
        }}
        component={CategoryList}
      />
      <Stack.Screen
        options={{
          title: I18n.t("products.productList"),
        }}
        name={"ProductList"}
        component={ProductList}
      />
      <Stack.Screen
        name={"ProductDetail"}
        options={{
          title: I18n.t("products.productDetail"),
        }}
        component={ProductDetail}
      />
    </Stack.Navigator>
  );
}

export default ProductsNavigator;
