import * as Updates from "expo-updates";
import { Platform } from "react-native";
import AppStore from "../data/AppStore";
import I18n from ".";

const key = "language";
const defaultLanguage = "fa";

const setLanguage = async (lang) => {
  try {
    await AppStore.setItem(key, lang);
    I18n.locale = lang;
    if (Platform.OS === "web") window.location.reload();
    else Updates.reloadAsync();
  } catch (error) {
    console.log("Error storing the language", error);
  }
};

const loadLanguage = async () => {
  try {
    const lang = await AppStore.getItem(key);
    if (lang) {
      I18n.locale = JSON.parse(lang);
      return;
    }
    I18n.locale = defaultLanguage;
    return;
  } catch (error) {
    console.log("Error getting the language", error);
  }
};

export default { setLanguage, loadLanguage };
