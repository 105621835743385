import React from "react";
import { StyleSheet, View } from "react-native";

import useApi from "../../hooks/useApi";
import actionApi from "../../api/action";
import PlugActionCard from "./device/plug/PlugActionCard";
import Switch2gActionCard from "./device/switch/Switch2gActionCard";
import Switch1gActionCard from "./device/switch/Switch1gActionCard";
import Switch3gActionCard from "./device/switch/Switch3gActionCard";
import colors from "../../config/colors";

function ActionItem({ action, getActionList }) {
  const deleteActionApi = useApi(actionApi.deleteAction);

  const handleDelete = async () => {
    const response = await deleteActionApi.request(action.pk);
    if (response.ok) getActionList();
  };

  const productPK = action.registration.device.product.pk;

  return (
    <View style={styles.container}>
      {productPK === 1 && (
        <PlugActionCard
          action={action}
          handleDelete={handleDelete}
          getActionList={getActionList}
        />
      )}
      {productPK === 2 && (
        <Switch2gActionCard
          action={action}
          handleDelete={handleDelete}
          getActionList={getActionList}
        />
      )}
      {productPK === 3 && (
        <Switch1gActionCard
          action={action}
          handleDelete={handleDelete}
          getActionList={getActionList}
        />
      )}
      {productPK === 4 && (
        <Switch3gActionCard
          action={action}
          handleDelete={handleDelete}
          getActionList={getActionList}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: colors.white,
    margin: 5,
  },
});

export default ActionItem;
