import { useEffect, useState } from "react";
import { Dimensions } from "react-native";

export function useOrientation() {
  const [orientation, setOrientation] = useState(
    Dimensions.get("window").height > Dimensions.get("window").width
      ? "PORTRAIT"
      : "LANDSCAPE"
  );

  useEffect(() => {
    Dimensions.addEventListener("change", ({ window: { width, height } }) => {
      if (width < height) {
        setOrientation("PORTRAIT");
      } else {
        setOrientation("LANDSCAPE");
      }
    });
  }, []);

  return orientation;
}
