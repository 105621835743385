import React, { useState, useEffect } from "react";
import { StyleSheet, View, Dimensions, StatusBar } from "react-native";
import AppIntroSlider from "react-native-app-intro-slider";
import Constants from "expo-constants";

import colors from "../../config/colors";
import AppImage from "../../components/AppImage";
import I18n from "../../i18n";
import useApi from "../../hooks/useApi";
import introductionApi from "../../api/introduction";
import AppText from "../../components/AppText";

function IntroScreen({ setIsFirst }) {
  const [intros, setIntros] = useState(undefined);
  const getIntroductionListApi = useApi(introductionApi.getIntroductionList);

  useEffect(() => {
    getIntroductionList();
  }, []);

  const getIntroductionList = async () => {
    const response = await getIntroductionListApi.request(
      Constants.manifest.version
    );
    if (response?.ok) setIntros(response.data.length ? response.data : null);
    else setIntros(null);
  };

  if (intros === null) {
    setIsFirst(false);
  }

  const _Item = ({ item }) => (
    <View style={[styles.slide, { backgroundColor: item.bg_color }]}>
      <AppText style={styles.title}>{item.title}</AppText>
      <AppImage source={{ uri: item.image }} style={styles.image} />
      <AppText style={styles.text}>{item.description}</AppText>
    </View>
  );

  return (
    <View style={styles.conntainer}>
      <StatusBar translucent backgroundColor="transparent" />
      {intros && (
        <AppIntroSlider
          renderItem={_Item}
          data={intros}
          onDone={() => setIsFirst(false)}
          renderNextButton={() => (
            <AppText style={styles.button}>{I18n.t("intro.next")}</AppText>
          )}
          renderDoneButton={() => (
            <AppText style={styles.button}>{I18n.t("intro.done")}</AppText>
          )}
          renderPrevButton={() => (
            <AppText style={styles.button}>{I18n.t("intro.previous")}</AppText>
          )}
          renderSkipButton={() => (
            <AppText style={styles.button}>{I18n.t("intro.skip")}</AppText>
          )}
          showPrevButton
          showSkipButton
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  conntainer: {
    flex: 1,
    backgroundColor: colors.primaryop,
  },
  slide: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 50,
    height: Dimensions.get("window").height,
    backgroundColor: colors.primaryop,
  },
  image: {
    marginVertical: 10,
    resizeMode: "contain",
    height: "60%",
    width: "90%",
  },
  text: {
    color: colors.white,
    textAlign: "center",
    marginHorizontal: 20,
  },
  title: {
    fontSize: 22,
    color: colors.white,
    textAlign: "center",
  },
  button: {
    color: colors.white,
    margin: 10,
    fontSize: 17,
  },
});

export default IntroScreen;
