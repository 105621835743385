import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View, Linking } from "react-native";
import * as Yup from "yup";
import { useNavigation } from "@react-navigation/native";

import AppText from "../../../components/AppText";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppErrorMessage,
  AppPickerField,
  AppCheckField,
} from "../../../components/forms";
import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import colors from "../../../config/colors";
import countryApi from "../../../api/country";
import codeApi from "../../../api/code";
import useApi from "../../../hooks/useApi";
import routes from "../../../config/routes";
import validations from "../../../config/validations";
import I18n from "../../../i18n";
import constants from "../../../config/constants";

function PhoneNumberStep({ context }) {
  const validationSchema = Yup.object().shape({
    phoneNumber: validations.phoneNumber(),
    isAgree: validations.isAgree(),
  });

  const navigation = useNavigation();
  const [countries, setCountries] = useState([]);
  const [selCountry, setSelCountry] = useState({});
  const sendCodeApi = useApi(codeApi.sendCode);
  const getCountryListApi = useApi(countryApi.getCountryList);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    const response = await getCountryListApi.request();
    if (response.ok) {
      setSelCountry(response.data[0]);
      setCountries(response.data);
    }
  };

  const handleSubmit = async ({ phoneNumber, country }) => {
    const response = await sendCodeApi.request({
      phone_number: phoneNumber,
      country: country.pk,
      reason: 1,
    });
    if (response.ok) {
      context.updateFormData({
        phoneNumber: phoneNumber,
        country: country,
        reason: 1,
      });
      context.nextStep();
    }
  };

  if (countries.length === 0) return null;

  return (
    <AppForm
      initialValues={{
        phoneNumber: context.formData.phoneNumber,
        country: countries[0],
        isAgree: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppText style={styles.title}>{I18n.t("form.phoneNumber")}</AppText>
      <AppLoadingIndicator visible={sendCodeApi.loading} />
      <AppErrorMessage
        error={sendCodeApi.error ? sendCodeApi.data.error : null}
        visible={sendCodeApi.error}
      />
      <AppPickerField
        name="country"
        placeholder={I18n.t("form.country")}
        items={countries}
        nameLabel="name"
        indexLabel="pk"
        imageLabel="country_pic"
        onSelect={setSelCountry}
      />
      <AppFormField
        name="phoneNumber"
        iconName="phone"
        placeholder={I18n.t("form.phoneNumber")}
        keyboardType="number-pad"
        prefixLabel={selCountry.code}
        enDigit
      />
      <AppCheckField
        name="isAgree"
        title={
          <>
            {I18n.t("auth.agreement")}{" "}
            <AppText
              style={styles.review}
              onPress={() => Linking.openURL(constants.websiteUrlAgreement)}
            >
              {I18n.t("auth.rules")}
            </AppText>
            {" - "}
            <AppText
              style={styles.review}
              onPress={() => navigation.navigate(routes.PRIVACY)}
            >
              {I18n.t("auth.privacy")}
            </AppText>{" "}
            {I18n.t("auth.agreementOf")}
          </>
        }
      />

      <View style={styles.buttonsContainer}>
        <AppSubmitButton title={I18n.t("public.accept")} />
        <View style={styles.textContainer}>
          <AppText style={styles.text}>
            {I18n.t("auth.alreadyRegistered")}
          </AppText>
          <TouchableOpacity onPress={() => navigation.navigate(routes.LOGIN)}>
            <AppText style={styles.textButton}>
              {I18n.t("auth.loginToAccount")}
            </AppText>
          </TouchableOpacity>
        </View>
      </View>
    </AppForm>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
    fontSize: 25,
    alignSelf: "flex-end",
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
  },
  textButton: {
    color: colors.primaryop,
    marginRight: 10,
    fontSize: 12,
  },
  review: {
    fontSize: 13,
    color: colors.primary,
    textDecorationLine: "underline",
  },
});

export default PhoneNumberStep;
