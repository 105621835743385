import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import colors from "../../../../config/colors";
import AppText from "../../../../components/AppText";
import routes from "../../../../config/routes";
import SwitchChannel from "./SwitchChannel";
import AppIcon from "../../../../components/AppIcon";

function Switch1gDevice({ registration, reg, getRegData }) {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={styles.longTouch}
      onLongPress={() =>
        navigation.navigate(routes.REGISTRATION_DETAIL, {
          registrationPK: registration.pk,
        })
      }
    >
      <View style={styles.container}>
        <View style={styles.top}>
          <View style={styles.right}>
            <AppIcon
              type="MaterialCommunityIcons"
              name="record"
              style={styles.status}
              color={reg.device.is_online ? colors.success : colors.danger}
              size={25}
            />
            <AppText numberOfLines={1} style={styles.name}>
              {registration.name}
            </AppText>
          </View>
          <View style={styles.left}>
            <SwitchChannel
              channel={reg.device.channels[0]}
              reg={reg}
              getRegData={getRegData}
            />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
  },
  longTouch: {
    borderRadius: 10,
    backgroundColor: colors.light,
  },
  name: {},
  top: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  status: {
    margin: 5,
  },
  left: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: 10,
  },
});
export default Switch1gDevice;
