import { Alert, Platform } from "react-native";

import I18n from "../i18n";

function AppAlert1B(title, descrition, onPressAccept) {
  if (Platform.OS === "web") {
    alert(`${title}\n${descrition}`);
    if (onPressAccept) onPressAccept();
  }
  return Alert.alert(title, descrition, [
    {
      text: I18n.t("public.accept"),
      onPress: onPressAccept,
      style: "default",
    },
  ]);
}

export default AppAlert1B;
