import React from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import AppScreen from "../../components/AppScreen";
import {
  AppForm,
  AppSubmitButton,
  AppErrorMessage,
  AppPasswordField,
} from "../../components/forms";
import userApi from "../../api/user";
import useApi from "../../hooks/useApi";
import validations from "../../config/validations";
import useMessage from "../../hooks/useMessage";
import I18n from "../../i18n";

function AccountPasswordChange({ navigation }) {
  const validationSchema = Yup.object().shape({
    passwordOld: validations.password(),
    passwordNew: validations.password(),
  });

  const passwordChangeApi = useApi(userApi.passwordChange);

  const handleSubmit = async ({ passwordOld, passwordNew }) => {
    const response = await passwordChangeApi.request({
      password_old: passwordOld,
      password_new: passwordNew,
    });
    if (response.ok) {
      useMessage().showAppMessage({
        message: response.data.message,
        type: "success",
      });
      navigation.goBack();
    }
  };

  return (
    <AppScreen loading={passwordChangeApi.loading}>
      <AppForm
        initialValues={{
          passwordNew: "",
          passwordOld: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <AppErrorMessage
          error={passwordChangeApi.error ? passwordChangeApi.data.error : null}
          visible={passwordChangeApi.error}
        />
        <AppPasswordField
          name="passwordOld"
          iconName="lock"
          placeholder={I18n.t("form.currentPassword")}
        />
        <AppPasswordField
          name="passwordNew"
          iconName="lock"
          placeholder={I18n.t("form.newPassword")}
        />

        <View style={styles.buttonsContainer}>
          <AppSubmitButton title={I18n.t("public.accept")} />
        </View>
      </AppForm>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
  },
});

export default AccountPasswordChange;
