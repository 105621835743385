import React from "react";
import { useFormikContext } from "formik";

import AppIconButton from "../buttons/AppIconButton";

function AppIconSubmitButton({ name, ...otherProps }) {
  const { handleSubmit } = useFormikContext();

  return <AppIconButton name={name} onPress={handleSubmit} {...otherProps} />;
}

export default AppIconSubmitButton;
