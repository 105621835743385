import { create } from "apisauce";
import { Platform } from "react-native";

const otaUpdate = (apiEP, firmware, auth) => {
  const form = new FormData();
  form.append("firmware", firmware);
  const api = create({
    baseURL:
      Platform.OS === "ios" ? apiEP.mdns_domain : "http://" + apiEP.local_ip,
    headers: {
      Authorization: auth,
      "Content-Type": "multipart/form-data",
    },
  });
  return api.post("/ota", form);
};

export default { otaUpdate };
