import client from "./client";

const login = (formData) => client.post("user/login", formData);
const logout = (formData) => client.post("user/logout", formData);
const signup = (formData) => client.post("user/signup", formData);
const status = (formData) => client.post("user/status", formData);
const deteleAcount = () => client.delete("user/delete");

const getUserInfo = () => client.get("user/info");
const getUserProfile = () => client.get("user/profile");
const putUserProfile = (formData) => client.put("user/profile", formData);

const passwordChange = (formData) =>
  client.post("user/password/change", formData);

const phoneNumberChange = (formData) =>
  client.post("user/phone_number/change", formData);

const passwordRecover = (formData) =>
  client.post("user/password/recover", formData);

export default {
  login,
  logout,
  signup,
  deteleAcount,
  status,

  getUserInfo,
  getUserProfile,
  putUserProfile,

  passwordChange,
  phoneNumberChange,
  passwordRecover,
};
