import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import colors from "../../../../config/colors";
import AppText from "../../../../components/AppText";
import routes from "../../../../config/routes";
import AppIcon from "../../../../components/AppIcon";
import AppCircleButton from "../../../../components/buttons/AppCircleButton";
import UserWsInstance from "../../../../websocket/user";
import DeviceModal from "../DeviceModal";
import CurtainDuration from "./CurtainDuration";
import registrationData from "../../../../data/registration";

function CurtainDevice({ registration, reg, getRegData }) {
  const [visible, setVisible] = useState(false);
  const [channel, setChannel] = useState({ value: 0, data: {} });
  const navigation = useNavigation();

  useEffect(() => {
    setChannel(reg.device.channels[0]);
  }, [reg]);

  const onPressBtn = (value, data) => {
    onPress({ value: value, data: data });
  };

  const onPress = async (content) => {
    const response = await UserWsInstance.send(
      {
        command: "update_state",
        pk: reg.pk,
        index: channel.index,
        ...content,
      },
      {
        regIDSub: reg.reg_id_sub,
        mdnsDomain: reg.device.mdns_domain,
        localIP: reg.device.local_ip,
      }
    );
    if (response.type === "LocalAPI") {
      if (response.ok) {
        await registrationData.updateChannel(reg.pk, {
          ...channel,
          ...content,
        });
        await getRegData();
      } else {
        await registrationData.offlineReg(reg.pk);
        await getRegData();
      }
    }
  };

  return (
    <>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.longTouch}
        onPress={() => setVisible(reg.device.is_online)}
        onLongPress={() =>
          navigation.navigate(routes.REGISTRATION_DETAIL, {
            registrationPK: registration.pk,
          })
        }
      >
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
            <View style={styles.left}>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(2, channel.data)}
                  value={channel.value === 2}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"curtains-closed"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(0, channel.data)}
                  value={channel.value === 0}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"stop"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(1, channel.data)}
                  value={channel.value === 1}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"curtains"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <DeviceModal setVisible={setVisible} visible={visible}>
        <View style={styles.container}>
          <View style={styles.top}>
            <View style={styles.right}>
              <AppIcon
                type="MaterialCommunityIcons"
                name="record"
                style={styles.status}
                color={reg.device.is_online ? colors.success : colors.danger}
                size={25}
              />
              <AppText numberOfLines={1} style={styles.name}>
                {registration.name}
              </AppText>
            </View>
            <View style={styles.left}>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(2)}
                  value={channel.value === 2}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"curtains-closed"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(0)}
                  value={channel.value === 0}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"stop"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
              <View style={styles.btn}>
                <AppCircleButton
                  onPress={() => onPressBtn(1)}
                  value={channel.value === 1}
                  disabled={!reg.device.is_online}
                  onColor={colors.primary}
                  offColor={colors.medium}
                  icon={"curtains"}
                  text={undefined}
                  withSound={true}
                  width={50}
                  height={50}
                  type="MaterialCommunityIcons"
                  debounce={800}
                />
              </View>
            </View>
          </View>
          <View style={styles.top}>
            <CurtainDuration data={channel.data} onPressBtn={onPressBtn} />
          </View>
        </View>
      </DeviceModal>
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    width: "100%",
  },
  longTouch: {
    borderRadius: 10,
    backgroundColor: colors.light,
  },
  top: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {},
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  status: {
    margin: 5,
  },
  left: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: 10,
  },
  btn: {
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default CurtainDevice;
