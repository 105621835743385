import React from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import Lottie from "react-lottie";
import colors from "../../config/colors";

function AppLoadingIndicator({ visible = false }) {
  if (!visible) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../assets/animations/loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const dim =
    Dimensions.get("screen").height > Dimensions.get("screen").width
      ? Dimensions.get("screen").width
      : Dimensions.get("screen").height;

  return (
    <View style={styles.overlay}>
      <Lottie options={defaultOptions} width={dim * 0.7} height={dim * 0.7} />
    </View>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    backgroundColor: colors.white,
    height: "100%",
    opacity: 0.8,
    width: "100%",
    zIndex: 1,
  },
});

export default AppLoadingIndicator;
