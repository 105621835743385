import React, { useState, useEffect } from "react";
import { StyleSheet, Dimensions, Platform, View } from "react-native";
import CarouselWeb, { Pagination } from "react-native-snap-carousel";
import Carousel from "react-native-reanimated-carousel";

import HomeZone from "./HomeZone";
import HomeZoneAdd from "./HomeZoneAdd";
import useApi from "../../hooks/useApi";
import familyApi from "../../api/family";

function HomeFamily({ family }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [perm, setPerm] = useState(undefined);
  const getFamilyPermissionApi = useApi(familyApi.getFamilyPermission);

  const screenWidth = Dimensions.get("screen").width;

  useEffect(() => {
    getPerm();
  }, [family.pk]);

  const getPerm = async () => {
    const response = await getFamilyPermissionApi.request(family.pk);
    if (response.ok) setPerm(response.data.permission);
  };

  if (!perm) return null;

  const itemList = perm.edit ? [...family.zones, family] : family.zones;

  const width = Dimensions.get("window").width;
  if (!family.zones) return null;
  if (Platform.OS === "web")
    return (
      <>
        <CarouselWeb
          layout={"default"}
          sliderWidth={screenWidth}
          itemWidth={screenWidth}
          data={itemList}
          renderItem={({ item, index }) => {
            if (index < itemList.length - 1 || !perm.edit)
              return <HomeZone key={index} zone={item} family={family} />;
            return <HomeZoneAdd key={index} familyPK={family.pk} />;
          }}
          onSnapToItem={(index) => setActiveIndex(index)}
          inactiveSlideScale={1}
        />
        <Pagination
          activeDotIndex={activeIndex}
          dotsLength={itemList.length}
          containerStyle={styles.pagination}
        />
      </>
    );
  return (
    <View style={{ flex: 1 }}>
      <Carousel
        loop={true}
        width={width}
        autoPlay={false}
        data={itemList}
        onSnapToItem={(index) => setActiveIndex(index)}
        renderItem={({ item, index }) => {
          if (index < itemList.length - 1 || !perm.edit)
            return <HomeZone key={index} zone={item} family={family} />;
          return <HomeZoneAdd key={index} familyPK={family.pk} />;
        }}
      />
      <Pagination
        activeDotIndex={activeIndex}
        dotsLength={itemList.length}
        containerStyle={styles.pagination}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  pagination: {
    width: "100%",
    paddingBottom: 5,
    paddingTop: 5,
    position: "absolute",
    bottom: 5,
  },
});

export default HomeFamily;
