import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import WelcomeScreen from "../screens/auth/WelcomeScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import SignUpScreen from "../screens/auth/signup/SignUpScreen";
import defaultStyles from "../config/styles";
import PasswordRecover from "../screens/auth/PasswordRecover";
import LanguageSelect from "../screens/general/LanguageSelect";
import I18n from "../i18n";
import PrivacyScreen from "../screens/auth/PrivacyScreen";

const Stack = createStackNavigator();

function AuthNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultStyles.stackScreenStyle}>
      <Stack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{
          headerShown: false,
          title: I18n.t("auth.welcome"),
        }}
      />
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          title: I18n.t("auth.login"),
        }}
      />
      <Stack.Screen
        name="SignUp"
        component={SignUpScreen}
        options={{
          title: I18n.t("auth.signup"),
        }}
      />
      <Stack.Screen
        name="PasswordRecover"
        component={PasswordRecover}
        options={{
          title: I18n.t("auth.recoverPassword"),
        }}
      />
      <Stack.Screen
        name="LanguageSelect"
        component={LanguageSelect}
        options={{
          title: I18n.t("auth.selectLanguage"),
        }}
      />
      <Stack.Screen
        name="Privacy"
        component={PrivacyScreen}
        options={{
          title: I18n.t("auth.privacy"),
        }}
      />
    </Stack.Navigator>
  );
}

export default AuthNavigator;
