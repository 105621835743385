import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import Constants from "expo-constants";

import AppText from "../../components/AppText";
import AppImageRounded from "../../components/AppImageRounded";
import colors from "../../config/colors";
import languageStorage from "../../i18n/storage";
import I18n from "../../i18n";

function LanguageItem({ lang }) {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        lang.code === I18n.locale ? { borderWidth: 2 } : {},
      ]}
      onPress={async () => await languageStorage.setLanguage(lang.code)}
    >
      <AppImageRounded
        source={lang.img}
        size={Constants.manifest.extra.panel ? 100 : 70}
      />
      <AppText style={styles.text}>{I18n.t("general." + lang.name)}</AppText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderColor: colors.primary,
  },
  text: {
    fontSize: 15,
    marginTop: 10,
  },
});

export default LanguageItem;
