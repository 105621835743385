import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";

import AppSegmentControlTab from "../../components/AppSegmentControlTab";
import AppText from "../../components/AppText";
import productApi from "../../api/product";
import useApi from "../../hooks/useApi";
import AppIframe from "../../components/AppIframe";
import RegistrationWizard from "./registration/RegistrationWizard";
import familyData from "../../data/family";
import I18n from "../../i18n";

function ProductDetail({ route, navigation }) {
  const { product } = route.params;
  const [index, setIndex] = useState(2);
  const [hasAddPerm, setAddPerm] = useState(true);
  const [productDetail, setProductDetail] = useState(null);
  const getProductDetailApi = useApi(productApi.getProductDetail);
  const mediaHeight = 250;

  useEffect(() => {
    navigation.setOptions({
      title: product.name,
    });
    getProductDetail();
    checkAdminPerm();
  }, []);

  const getProductDetail = async () => {
    const response = await getProductDetailApi.request(product.pk);
    if (response.ok) setProductDetail(response.data);
  };

  const checkAdminPerm = async () => {
    const { permission } = await familyData.get();
    if (permission.edit) {
      setAddPerm(true);
      setIndex(2);
    } else {
      setAddPerm(false);
      setIndex(1);
    }
  };

  if (!productDetail) return null;

  return (
    <>
      <View style={styles.segmentControl}>
        <AppSegmentControlTab
          values={
            hasAddPerm
              ? [
                  I18n.t("products.tutorials"),
                  I18n.t("products.descriptions"),
                  I18n.t("products.registration"),
                ]
              : [I18n.t("products.tutorials"), I18n.t("products.descriptions")]
          }
          index={index}
          setIndex={setIndex}
        />
      </View>
      {index === 2 && <RegistrationWizard productDetail={productDetail} />}
      {index === 1 && (
        <>
          <ScrollView
            showsVerticalScrollIndicator={false}
            alwaysBounceVertical={false}
          >
            <AppText style={styles.text}>
              {productDetail.description_text_1}
            </AppText>
            {productDetail.description_video_1 && (
              <AppIframe
                src={productDetail.description_video_1}
                height={mediaHeight}
              />
            )}
            <AppText style={styles.text}>
              {productDetail.description_text_2}
            </AppText>
            {productDetail.description_video_2 && (
              <AppIframe
                src={productDetail.description_video_2}
                height={mediaHeight}
              />
            )}
          </ScrollView>
        </>
      )}
      {index === 0 && (
        <>
          <ScrollView
            showsVerticalScrollIndicator={false}
            alwaysBounceVertical={false}
          >
            <AppText style={styles.text}>
              {productDetail.tutorial_text_1}
            </AppText>
            {productDetail.tutorial_video_1 && (
              <AppIframe
                src={productDetail.tutorial_video_1}
                height={mediaHeight}
              />
            )}
            <AppText style={styles.text}>
              {productDetail.tutorial_text_2}
            </AppText>
            {productDetail.tutorial_video_2 && (
              <AppIframe
                src={productDetail.tutorial_video_2}
                height={mediaHeight}
              />
            )}
          </ScrollView>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  segmentControl: {
    width: "100%",
    marginVertical: 15,
    zIndex: 1,
  },
  text: {
    textAlign: "right",
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});

export default ProductDetail;
