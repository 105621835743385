import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import * as Updates from "expo-updates";
import Constants from "expo-constants";

import HomeFamily from "./HomeFamily";
import HomeFamilyDropdown from "./HomeFamilyDropdown";
import AppIconButton from "../../components/buttons/AppIconButton";
import AppScreen from "../../components/AppScreen";
import routes from "../../config/routes";
import useApi from "../../hooks/useApi";
import familyApi from "../../api/family";
import familyData from "../../data/family";
import colors from "../../config/colors";
import I18n from "../../i18n";
import { useOrientation } from "../../hooks/useOrientation";

function HomeScreen({ navigation }) {
  const [families, setFamilies] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const getFamilyListApi = useApi(familyApi.getFamilyList);
  const getFamilyDeepApi = useApi(familyApi.getFamilyDeep);
  const orientation = useOrientation();

  useEffect(() => {
    getFamilyList();
  }, []);

  const getFamilyList = async () => {
    const response = await getFamilyListApi.request();
    if (response.ok) {
      setFamilies(response.data);
      const sel = await familyData.get();
      if (sel) {
        selectFamily(response.data.find((item) => item.pk === sel.pk));
      } else {
        restoreFamily(response.data);
      }
    }
  };

  const restoreFamily = async (fams) => {
    const sel = await familyData.get();
    selectFamily(sel ? sel : fams[0]);
  };

  const selectFamily = async (item) => {
    if (item)
      if (item.pk === 0) {
        navigation.navigate(routes.FAMILY_SETTINGS, {
          family: null,
        });
        return;
      }
    await familyData.store(item);
    const response = await getFamilyDeepApi.request(item.pk);
    if (response.ok) setSelected(response.data);
  };

  if (families.length === 0) return null;

  return (
    <AppScreen key={orientation}>
      <View style={styles.header}>
        <HomeFamilyDropdown
          families={[
            ...families,
            { pk: 0, name: I18n.t("home.familyCreate"), icon_name: "add" },
          ]}
          selected={selected}
          setSelected={(item) => {
            selectFamily(item);
            // getFamilyList();
          }}
        />

        {selected && (
          <AppIconButton
            size={30}
            name="ios-people-sharp"
            type="Ionicons"
            color={colors.medium}
            style={styles.familyButton}
            onPress={() =>
              navigation.navigate(routes.FAMILY_SETTINGS, {
                family: selected,
              })
            }
          />
        )}

        {Constants.manifest.extra.panel && (
          <AppIconButton
            size={30}
            name="refresh"
            type="FontAwesome"
            color={colors.medium}
            style={styles.familyButton}
            onPress={
              () => Updates.reloadAsync()
              // navigation.reset({
              //   index: 0,
              //   routes: [{ name: routes.HOME }],
              // })
            }
          />
        )}
      </View>
      {selected && <HomeFamily family={selected} />}
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  header: {
    zIndex: 1,
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  familyButton: {
    margin: 10,
  },
});

export default HomeScreen;
