import React from "react";
import { StyleSheet, View } from "react-native";
import Checkbox from "expo-checkbox";

import AppText from "./AppText";
import colors from "../config/colors";

function AppCheckBox({ title, checked, onPress, style }) {
  return (
    <View style={[styles.container, style]}>
      <Checkbox
        style={styles.checkbox}
        value={checked}
        onValueChange={onPress}
        color={colors.primary}
      />
      <AppText style={styles.text}>{title}</AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row-reverse",
    alignSelf: "flex-end",
    alignItems: "center",
    marginVertical: 10,
  },
  text: {
    fontSize: 12,
    flex: 0.9,
  },

  checkbox: {
    margin: 10,
  },
});

export default AppCheckBox;
