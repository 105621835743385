import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";
import moment from "moment-jalaali";

import {
  AppErrorMessage,
  AppForm,
  AppFormField,
  AppSwitchField,
  AppDatePickerField,
  AppTimePickerField,
  AppPickerField,
} from "../../components/forms";
import sceneApi from "../../api/scene";
import useApi from "../../hooks/useApi";
import AppLoadingIndicator from "../../components/lotties/AppLoadingIndicator";
import colors from "../../config/colors";
import familyData from "../../data/family";
import I18n from "../../i18n";
import AppIconButton from "../../components/buttons/AppIconButton";
import AppAlert2B from "../../components/AppAlert2B";
import ActionList from "./ActionList";
import AppText from "../../components/AppText";

function SceneEdit({ route, navigation }) {
  const repeatTypes = [
    {
      title: I18n.t("scenes.oneTime"),
      type: 1,
    },
    {
      title: I18n.t("scenes.daily"),
      type: 2,
    },
    {
      title: I18n.t("scenes.weekly"),
      type: 3,
    },
    {
      title: I18n.t("scenes.monthly"),
      type: 4,
    },
    {
      title: I18n.t("scenes.yearly"),
      type: 5,
    },
  ];
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(I18n.t("error.required")),
  });

  const { scene } = route.params;
  const createSceneApi = useApi(sceneApi.createScene);
  const updateSceneApi = useApi(sceneApi.updateScene);
  const deleteSceneApi = useApi(sceneApi.deleteScene);
  const [momenta, setMomenta] = useState(
    scene
      ? scene.moment_set[0]
      : {
          time: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          active: false,
          repeat: 1,
        }
  );

  useEffect(() => {
    navigation.setOptions({
      title: scene ? I18n.t("scenes.sceneEdit") : I18n.t("scenes.sceneCreate"),
      headerRight: () =>
        scene && (
          <AppIconButton
            name="delete-forever"
            size={30}
            color={colors.white}
            style={styles.delete}
            onPress={() =>
              AppAlert2B(
                I18n.t("confirm.deleteSceneTitle"),
                I18n.t("confirm.deleteScene"),
                () => handleDelete(),
                () => null
              )
            }
          />
        ),
    });
  }, []);

  const handleDelete = async () => {
    const response = await deleteSceneApi.request(scene.pk);
    if (response.ok) navigation.goBack();
  };

  const handleSubmit = async ({ name, description, time, date }) => {
    const family = await familyData.get();
    const formData = {
      family: family.pk,
      name: name,
      description: description,
      moment_set: [{ ...momenta, time: date + "T" + time }],
    };
    if (scene) {
      const response = await updateSceneApi.request(scene.pk, formData);
      if (response.ok) navigation.goBack();
    } else {
      const response = await createSceneApi.request(formData);
      if (response.ok) navigation.goBack();
    }
  };

  return (
    <AppForm
      initialValues={{
        name: scene ? scene.name : undefined,
        description: scene ? scene.description : undefined,
        active: momenta ? momenta.active : false,
        date: momenta
          ? moment(momenta.time, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        time: momenta
          ? moment(momenta.time, "YYYY-MM-DDTHH:mm:ssZ").format("HH:mm:ssZ")
          : moment().format("HH:mm:ssZ"),
        repeat: momenta ? repeatTypes[momenta.repeat - 1] : undefined,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      style={styles.form}
      submitOnBack
      confirm={!scene}
    >
      <AppLoadingIndicator
        visible={createSceneApi.loading || updateSceneApi.loading}
      />
      <AppErrorMessage
        error={
          createSceneApi.error
            ? createSceneApi.data.error
            : updateSceneApi.error
            ? updateSceneApi.data.error
            : null
        }
        visible={createSceneApi.error || updateSceneApi.error}
      />

      <AppFormField
        name="name"
        iconName="wb-incandescent"
        placeholder={I18n.t("form.sceneName")}
      />

      <AppSwitchField
        title={I18n.t("form.automated")}
        name="active"
        onChange={(active) => setMomenta({ ...momenta, active })}
        iconName="auto-awesome"
      />

      {momenta.active && (
        <>
          <AppTimePickerField
            title={I18n.t("form.time")}
            name="time"
            iconName="timer"
          />
          <AppDatePickerField
            title={I18n.t("form.date")}
            name="date"
            iconName="calendar-today"
          />

          <View style={styles.formRow}>
            <View style={{ flexGrow: 1 }}>
              <AppPickerField
                name="repeat"
                placeholder={I18n.t("form.repeat")}
                items={repeatTypes}
                nameLabel="title"
                indexLabel="type"
                imageLabel=""
                onSelect={(repeat) =>
                  setMomenta({ ...momenta, repeat: repeat.type })
                }
              />
            </View>
          </View>
        </>
      )}

      {scene && (
        <View style={styles.actions}>
          <AppText style={styles.actionsText}>
            {I18n.t("scenes.sceneActions")}
          </AppText>
          <ActionList scene={scene} />
        </View>
      )}
    </AppForm>
  );
}

const styles = StyleSheet.create({
  formRow: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    width: "100%",
  },
  delete: {
    marginRight: 10,
  },
  form: {
    flexGrow: 0,
  },
  actions: {
    width: "100%",
  },
  actionsText: {
    fontSize: 14,
    textAlign: "center",
    color: colors.primary,
    margin: 5,
  },
});

export default SceneEdit;
