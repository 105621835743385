import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import registrationApi from "../../../../api/registration";
import zoneApi from "../../../../api/zone";
import useApi from "../../../../hooks/useApi";
import AppLoadingIndicator from "../../../../components/lotties/AppLoadingIndicator";
import {
  AppErrorMessage,
  AppForm,
  AppSubmitButton,
  AppPickerField,
  AppFormField,
} from "../../../../components/forms";
import colors from "../../../../config/colors";
import AppButton from "../../../../components/buttons/AppButton";
import AppAlert2B from "../../../../components/AppAlert2B";
import routes from "../../../../config/routes";
import I18n from "../../../../i18n";

function RegistrationEdit({ route, navigation }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(I18n.t("error.required")),
    zone: Yup.object().required(I18n.t("error.required")),
  });

  const { registration } = route.params;
  const [zones, setZones] = useState([]);
  const updateRegistrationApi = useApi(registrationApi.updateRegistration);
  const deleteRegistrationApi = useApi(registrationApi.deleteRegistration);
  const getZoneListApi = useApi(zoneApi.getZoneList);

  useEffect(() => {
    getZoneList();
  }, []);

  const getZoneList = async () => {
    const response = await getZoneListApi.request(registration.family.pk);
    if (response.ok) setZones(response.data);
  };

  const handleSubmit = async ({ name, zone }) => {
    const response = await updateRegistrationApi.request(registration.pk, {
      name,
      zone_pk: zone.pk,
    });
    if (response.ok)
      navigation.reset({
        index: 0,
        routes: [{ name: routes.HOME }],
      });
  };

  const handleDelete = async () => {
    await deleteRegistrationApi.request(registration.pk);
    navigation.reset({
      index: 0,
      routes: [{ name: routes.HOME }],
    });
  };

  return (
    <AppForm
      initialValues={{
        name: registration.name,
        zone: registration.zone,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppLoadingIndicator visible={updateRegistrationApi.loading} />
      <AppErrorMessage
        error={
          updateRegistrationApi.error ? updateRegistrationApi.data.error : null
        }
        visible={updateRegistrationApi.error}
      />
      <AppPickerField
        name="zone"
        placeholder={I18n.t("form.zone")}
        iconName="home"
        items={zones}
        nameLabel="name"
        indexLabel="pk"
        imageLabel=""
      />
      <AppFormField
        name="name"
        iconName="device-hub"
        placeholder={I18n.t("form.deviceName")}
      />

      <View style={styles.buttonsContainer}>
        <View style={styles.button}>
          <AppSubmitButton title={I18n.t("public.accept")} />
        </View>
        <View style={styles.button}>
          <AppButton
            title={I18n.t("home.registrationDelete")}
            color={colors.danger}
            onPress={() => {
              return AppAlert2B(
                I18n.t("confirm.deleteDeviceTitle"),
                I18n.t("confirm.deleteDevice"),
                () => handleDelete(),
                () => null
              );
            }}
          />
        </View>
      </View>
    </AppForm>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-around",
  },
  button: {
    flex: 1,
    marginHorizontal: 5,
  },
});

export default RegistrationEdit;
