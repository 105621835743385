import ReconnectingWebSocket from "reconnecting-websocket";

import authStorage from "../auth/storage";
import constants from "../config/constants";
import useMessage from "../hooks/useMessage";
import localApi from "../api_local/local";
import I18n from "../i18n";

class UserWebsocket {
  static instance = null;

  static getInstance() {
    if (!UserWebsocket.instance) {
      UserWebsocket.instance = new UserWebsocket();
    }
    return UserWebsocket.instance;
  }

  constructor() {
    this.ws = null;
    this.connected = false;
    this.callbackes = [];
  }

  async connect() {
    const token = await authStorage.getToken();
    this.ws = new ReconnectingWebSocket(
      constants.serverWsBaseUrl + "/user/?id=" + token
    );

    this.ws.addEventListener("open", () => {
      console.log("WS Connected :)");
      this.connected = true;
      useMessage().showAppMessage({
        message: I18n.t("public.connected"),
        type: "success",
      });
    });

    this.ws.addEventListener("close", () => {
      console.log("WS Disconnected :(");
      this.connected = false;
      if (this.ws)
        if (this.ws.retryCount === 1)
          useMessage().showAppMessage({
            message: I18n.t("public.connecting"),
            type: "warning",
            duration: 10000,
          });
    });

    this.ws.addEventListener("message", (message) => {
      const data = JSON.parse(message.data);

      if (data.command === "notification")
        useMessage().showAppMessage({
          message: data.message,
          description: data.description,
          type: data.type,
        });
      if (data.command === "new_state") {
        this.callbackes.forEach((callback) => {
          if (callback.command === data.command + data.registration.pk)
            callback.func(data);
        });
      }
      if (data.command === "update_scene") {
        this.callbackes.forEach((callback) => {
          if (callback.command === data.command + data.pk)
            callback.func(data.state);
        });
      }
      if (data.command === "firmware_update") {
        this.callbackes.forEach((callback) => {
          if (callback.command === data.command + data.pk) callback.func(data);
        });
      }
      if (data.command === "refresh") {
        this.reconnect();
      }
    });
  }

  async disconnect() {
    this.ws.close();
    this.ws = null;
  }

  async reconnect() {
    this.disconnect();
    this.connect();
  }

  async send(message, data = undefined) {
    if (this.connected) {
      this.ws.send(JSON.stringify(message));
      return { type: "WS" };
    } else if (data) {
      const response = await localApi.updateState(message, data);
      return { type: "LocalAPI", ok: response.ok };
    }
  }

  addCallback(callback) {
    this.callbackes = [...this.callbackes, callback];
  }

  removeCallback(callback) {
    this.callbackes = this.callbackes.filter(
      (call) => call.command !== callback.command
    );
  }
}

const UserWsInstance = UserWebsocket.getInstance();

export default UserWsInstance;
