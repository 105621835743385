import React, { useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";

import useApi from "../../hooks/useApi";
import actionApi from "../../api/action";
import ActionItem from "./ActionItem";
import colors from "../../config/colors";
import routes from "../../config/routes";
import I18n from "../../i18n";
import AppText from "../../components/AppText";
import AppIcon from "../../components/AppIcon";

function ActionList({ scene }) {
  const isFocused = useIsFocused();
  const getactionApi = useApi(actionApi.getActionList);
  const [actions, setActions] = useState(undefined);
  const navigation = useNavigation();

  useEffect(() => {
    if (scene && isFocused) getActionList();
  }, [isFocused]);

  const getActionList = async () => {
    const response = await getactionApi.request(scene.pk);
    if (response.ok) setActions(response.data);
  };

  if (!actions) return null;

  return [...actions, { addNew: true }].map((item, key) => (
    <View key={key} style={styles.container}>
      {!item.addNew ? (
        <ActionItem action={item} getActionList={getActionList} />
      ) : (
        <TouchableOpacity
          style={styles.addButton}
          onPress={() =>
            navigation.navigate(routes.ACTION_CREATE, {
              scene: scene,
            })
          }
        >
          <AppText style={styles.addText}>
            {I18n.t("scenes.actionCreate")}
          </AppText>
          <AppIcon name="add" size={30} color={colors.primary} border />
        </TouchableOpacity>
      )}
    </View>
  ));
}

const styles = StyleSheet.create({
  container: {},
  addButton: {
    flex: 1,
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: colors.white,
    margin: 5,
    padding: 10,
  },
  addText: {
    fontSize: 13,
    marginLeft: 15,
    color: colors.primary,
  },
});

export default ActionList;
