import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useIsFocused } from "@react-navigation/native";

import useApi from "../../hooks/useApi";
import sceneApi from "../../api/scene";
import SceneListItem from "./SceneListItem";
import familyData from "../../data/family";
import AppFlatList from "../../components/AppFlatList";
import AppText from "../../components/AppText";
import I18n from "../../i18n";
import { useOrientation } from "../../hooks/useOrientation";

function SceneList() {
  const isFocused = useIsFocused();
  const sceneListApi = useApi(sceneApi.getSceneList);
  const [scenes, setScenes] = useState([]);
  const orientation = useOrientation();

  useEffect(() => {
    if (isFocused) getSceneList();
  }, [isFocused]);

  const getSceneList = async () => {
    const response = await sceneListApi.request();
    if (response.ok) {
      const family = await familyData.get();
      setScenes(response.data.filter((item) => item.family.pk === family.pk));
    }
  };

  if (!scenes.length)
    return (
      <View style={styles.noSceneContainer}>
        <AppText>{I18n.t("scenes.noScene")}</AppText>
      </View>
    );

  return (
    <AppFlatList
      data={scenes}
      style={styles.list}
      keyExtractor={(item) => String(item.pk)}
      numColumns={orientation === "PORTRAIT" ? 1 : 2}
      key={orientation}
      renderItem={({ item }) => <SceneListItem scene={item} />}
    />
  );
}

const styles = StyleSheet.create({
  noSceneContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    width: "100%",
  },
});

export default SceneList;
