import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import AppScreen from "../../components/AppScreen";
import AppButton from "../../components/buttons/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppErrorMessage,
  AppPickerField,
  AppPasswordField,
} from "../../components/forms";
import colors from "../../config/colors";
import userApi from "../../api/user";
import useApi from "../../hooks/useApi";
import countryApi from "../../api/country";
import codeApi from "../../api/code";
import validations from "../../config/validations";
import routes from "../../config/routes";
import useMessage from "../../hooks/useMessage";
import I18n from "../../i18n";
import AppText from "../../components/AppText";

function PasswordRecover({ navigation }) {
  const validationSchemaPhoneNumber = Yup.object().shape({
    phoneNumber: validations.phoneNumber(),
  });

  const validationSchemaCode = Yup.object().shape({
    code: validations.code(),
  });

  const validationSchemaPassword = Yup.object().shape({
    password: validations.password(),
  });

  const [step, setStep] = useState("PHONE");
  const [countries, setCountries] = useState([]);
  const [selCountry, setSelCountry] = useState({});
  const [formData, setFormData] = useState({});
  const sendCodeApi = useApi(codeApi.sendCode);
  const verifyCodeApi = useApi(codeApi.verifyCode);
  const passwordRecoverApi = useApi(userApi.passwordRecover);
  const getCountryListApi = useApi(countryApi.getCountryList);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    const response = await getCountryListApi.request();
    if (response.ok) {
      setSelCountry(response.data[0]);
      setCountries(response.data);
    }
  };

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const handleSubmitPhoneNumber = async ({ phoneNumber, country }) => {
    const response = await sendCodeApi.request({
      phone_number: phoneNumber,
      country: country.pk,
      reason: 3,
    });
    if (response.ok) {
      updateFormData({ phoneNumber: phoneNumber, country: country, reason: 3 });
      setStep("CODE");
    }
  };

  const handleSubmitCode = async ({ code }) => {
    const response = await verifyCodeApi.request({
      phone_number: formData["phoneNumber"],
      country: formData["country"].pk,
      reason: formData["reason"],
      code: code,
    });
    if (response.ok) {
      updateFormData({ code: code });
      setStep("PASSWORD");
    }
  };

  const handleSubmitPassword = async ({ password }) => {
    const response = await passwordRecoverApi.request({
      phone_number: formData["phoneNumber"],
      country: formData["country"].pk,
      code: formData["code"],
      reason: formData["reason"],
      password: password,
    });
    if (response.ok) {
      useMessage().showAppMessage({
        message: response.data.message,
        type: "success",
      });
      navigation.navigate(routes.LOGIN);
    }
  };

  if (countries.length === 0) return null;

  return (
    <AppScreen
      loading={
        sendCodeApi.loading ||
        verifyCodeApi.loading ||
        passwordRecoverApi.loading
      }
    >
      {step === "PHONE" && (
        <AppForm
          initialValues={{
            phoneNumber: formData.phoneNumber,
            country: countries[0],
          }}
          onSubmit={handleSubmitPhoneNumber}
          validationSchema={validationSchemaPhoneNumber}
        >
          <AppErrorMessage
            error={sendCodeApi.error ? sendCodeApi.data.error : null}
            visible={sendCodeApi.error}
          />
          <AppPickerField
            name="country"
            placeholder={I18n.t("form.country")}
            items={countries}
            nameLabel="name"
            indexLabel="pk"
            imageLabel="country_pic"
            onSelect={setSelCountry}
          />
          <AppFormField
            name="phoneNumber"
            iconName="phone"
            placeholder={I18n.t("form.phoneNumber")}
            keyboardType="number-pad"
            prefixLabel={selCountry.code}
            enDigit
          />

          <View style={styles.buttonsContainer}>
            <AppSubmitButton title={I18n.t("public.accept")} />
          </View>
        </AppForm>
      )}
      {step === "CODE" && (
        <AppForm
          initialValues={{ code: "" }}
          onSubmit={handleSubmitCode}
          validationSchema={validationSchemaCode}
        >
          <AppErrorMessage
            error={verifyCodeApi.error ? verifyCodeApi.data.error : null}
            visible={verifyCodeApi.error}
          />
          <AppFormField
            name="code"
            iconName="qr-code"
            placeholder={I18n.t("form.code")}
            keyboardType="number-pad"
          />

          <AppText style={styles.subTitle}>
            {I18n.t("auth.registrationCodeSentTo")}
            {formData.phoneNumber}
          </AppText>

          <View style={styles.buttonsContainer}>
            <View style={styles.button}>
              <AppSubmitButton title={I18n.t("public.accept")} />
            </View>
            <View style={styles.button}>
              <AppButton
                title={I18n.t("public.back")}
                color={colors.secondary}
                onPress={() => setStep("PHONE")}
              />
            </View>
          </View>
        </AppForm>
      )}
      {step === "PASSWORD" && (
        <AppForm
          initialValues={{ password: "" }}
          onSubmit={handleSubmitPassword}
          validationSchema={validationSchemaPassword}
        >
          <AppErrorMessage
            error={
              passwordRecoverApi.error ? passwordRecoverApi.data.error : null
            }
            visible={passwordRecoverApi.error}
          />

          <AppPasswordField
            name="password"
            iconName="lock"
            placeholder={I18n.t("form.newPassword")}
          />

          <View style={styles.buttonsContainer}>
            <AppSubmitButton title={I18n.t("public.accept")} />
          </View>
        </AppForm>
      )}
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  subTitle: {
    fontSize: 12,
    textAlign: "center",
  },
  button: {
    width: "45%",
  },
});

export default PasswordRecover;
