import { showMessage } from "react-native-flash-message";
import colors from "../config/colors";

const useMessage = () => {
  const showAppMessage = ({
    message,
    type,
    description = "",
    duration = 1800,
  }) => {
    showMessage({
      message: message,
      description: description,
      type: type,
      icon: { icon: type, position: "right" },
      duration: duration,
      backgroundColor: colors[type],
    });
  };

  return { showAppMessage };
};

export default useMessage;
