import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import AppText from "../../components/AppText";
import HomeDevice from "./HomeDevice";
import colors from "../../config/colors";
import AppImageBackground from "../../components/AppImageBackground";
import routes from "../../config/routes";
import AppIcon from "../../components/AppIcon";
import AppFlatList from "../../components/AppFlatList";
import { useOrientation } from "../../hooks/useOrientation";
import bg from "../../assets/backgrounds/bg1.jpg";

function HomeZone({ zone, family }) {
  const navigation = useNavigation();
  const orientation = useOrientation();

  // const test = [
  //   {
  //     device: {
  //       mac_address: "4C:75:25:2S:6D:DA",
  //       is_online: true,
  //       channels: [
  //         {
  //           type: 5,
  //           index: 0,
  //           data: {
  //             temp: 27,
  //             dtemp: 26,
  //             fan: 0,
  //             mode: 2,
  //             auto: 0,
  //           },
  //           value: 1,
  //         },
  //       ],
  //       mdns_domain: "http://LEGA30_4C75252S6DDA.local",
  //       local_ip: "192.168.1.105",
  //       rssi: 90,
  //       product: {
  //         category: 3,
  //         name: "کلید ترموستاتیک لگاسی",
  //         channels_list: "5",
  //         pk: 6,
  //       },
  //     },
  //     name: "ترموستات من",
  //     pk: 12345,
  //     reg_id_sub: "a20f5524",
  //   },

  //   {
  //     device: {
  //       mac_address: "4C:75:25:2S:4D:DA",
  //       is_online: true,
  //       channels: [
  //         {
  //           type: 5,
  //           index: 0,
  //           data: {
  //             duration: 0,
  //           },
  //           value: 1,
  //         },
  //       ],
  //       mdns_domain: "http://LEGA30_4C7525AS6DDA.local",
  //       local_ip: "192.168.1.109",
  //       rssi: 80,
  //       product: {
  //         category: 4,
  //         name: "کلید پرده لگاسی",
  //         channels_list: "5",
  //         pk: 7,
  //       },
  //     },
  //     name: "پرده من",
  //     pk: 12345,
  //     reg_id_sub: "a20f5524",
  //   },
  // ];

  return (
    <AppImageBackground
      source={bg}
      style={styles.mainContainer}
      resizeMode="cover"
    >
      <TouchableOpacity
        style={styles.titleContainer}
        onPress={() =>
          navigation.navigate(routes.ZONE_EDIT, {
            familyPK: family.pk,
            zonePK: zone.pk,
          })
        }
        disabled={!family.permission.edit}
      >
        <AppText numberOfLines={1} style={styles.title}>
          {zone.name}
        </AppText>
        {family.permission.edit && (
          <View style={styles.edit}>
            <AppIcon name="edit" size={15} color={colors.medium} />
          </View>
        )}
      </TouchableOpacity>

      <AppFlatList
        data={zone.registrations}
        keyExtractor={(item) => String(item.pk)}
        numColumns={orientation === "PORTRAIT" ? 1 : 2}
        key={orientation}
        renderItem={({ item }) => (
          <View style={styles.item}>
            <HomeDevice registration={item} />
          </View>
        )}
        style={styles.list}
      />
    </AppImageBackground>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
  },
  titleContainer: {
    backgroundColor: colors.white,
    width: "60%",
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: colors.primary,
  },
  title: {
    fontSize: 14,
  },
  list: {
    width: "100%",
    marginTop: 5,
    paddingBottom: 25,
    flex: 1,
  },
  edit: {
    position: "absolute",
    left: 10,
  },
  item: {
    marginHorizontal: 10,
    marginVertical: 5,
    flex: 1,
  },
});
export default HomeZone;
