import React from "react";
import { StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import AppErrorMessage from "./AppErrorMessage";
import AppCircleImagePickerInput from "../inputs/AppCircleImagePickerInput";

function AppCircleImagePickerField({ name, ...otherProps }) {
  const {
    errors,
    setFieldTouched,
    touched,
    setFieldValue,
    values,
  } = useFormikContext();

  return (
    <>
      <AppCircleImagePickerInput
        onChange={(url) => setFieldValue(name, url)}
        value={values[name]}
        onBlur={() => setFieldTouched(name)}
        {...otherProps}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({});

export default AppCircleImagePickerField;
