import AppStore from "../data/AppStore";
import uuid from "react-native-uuid";

const key = "appId";

const createAppId = async () => {
  try {
    if (!(await getAppId())) {
      const id = uuid.v4();
      await AppStore.setItem(key, id);
    }
  } catch (error) {
    console.log("Error storing the app id", error);
  }
};

const getAppId = async () => {
  try {
    const id = await AppStore.getItem(key);
    return JSON.parse(id);
  } catch (error) {
    console.log("Error getting the app id", error);
  }
};

const removeAppId = async () => {
  try {
    return await AppStore.deleteItem(key);
  } catch (error) {
    console.log("Error removing the app id", error);
  }
};

export default {
  createAppId,
  getAppId,
  removeAppId,
};
