import * as Yup from "yup";
import I18n from "../i18n";

const phoneNumber = () =>
  Yup.string()
    .required(I18n.t("error.required"))
    // .matches(/^[0-9]+$/, I18n.t("error.phoneNumberType"))
    .matches("9", I18n.t("error.phoneNumberValid"))
    .max(10, I18n.t("error.phoneNumberLength"))
    .min(10, I18n.t("error.phoneNumberLength"));

const password = () =>
  Yup.string()
    .required(I18n.t("error.required"))
    .min(5, I18n.t("error.passwordLenght"));

const isAgree = () =>
  Yup.bool().oneOf([true], I18n.t("error.agreementRequired"));

const code = () =>
  Yup.string()
    .required(I18n.t("error.required"))
    .matches(/^[0-9]+$/, I18n.t("error.codeType"))
    .max(6, I18n.t("error.codeLenght"))
    .min(6, I18n.t("error.codeLenght"));

export default { phoneNumber, password, phoneNumber, isAgree, code };
