import client from "./client";

const getActionList = (scenePK) => client.get("action/" + scenePK);

const getAvailableActionList = (scenePK) =>
  client.get("action/available/" + scenePK);

const updateAction = (actionPK, formData) =>
  client.put("action/" + actionPK, formData);

const deleteAction = (actionPK) => client.delete("action/" + actionPK);

const createAction = (scenePK, formData) =>
  client.post("action/" + scenePK, formData);

export default {
  getActionList,
  updateAction,
  deleteAction,
  createAction,
  getAvailableActionList,
};
