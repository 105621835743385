import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import * as Progress from "react-native-progress";

import AppButton from "../../../components/buttons/AppButton";
import colors from "../../../config/colors";
import defaultStyle from "../../../config/styles";
import useApi from "../../../hooks/useApi";
import registrationApi from "../../../api/registration";
import familyData from "../../../data/family";
import AppText from "../../../components/AppText";
import I18n from "../../../i18n";

function ProductRegistration({ moduleInfo, regID }) {
  const [text, setText] = useState();

  const createRegistrationApi = useApi(registrationApi.createRegistration);
  const deleteRegistrationApi = useApi(registrationApi.deleteRegistration);
  const getRegistrationApi = useApi(registrationApi.getRegistration);

  useEffect(() => {
    setText(I18n.t("products.registrationConnecting"));
    createRegistration();
  }, []);

  const createRegistration = async () => {
    let iter = 0;
    const family = await familyData.get();
    const interval = setInterval(async () => {
      if (iter < 30) {
        iter++;
        const response = await createRegistrationApi.request({
          module_info: moduleInfo,
          family_pk: family.pk,
          reg_id: regID,
        });
        if (response.ok) {
          clearInterval(interval);
          setText(I18n.t("products.registrationRegistering"));
          confirmRegistration(response.data);
        } else if (response.status === 401) {
          clearInterval(interval);
          setText(I18n.t("products.registrationUnauthorized"));
        } else if (response.status === 403) {
          clearInterval(interval);
          if (response.data.status === 0)
            setText(I18n.t("products.registrationDuplicate"));
          if (response.data.status === 6)
            setText(I18n.t("products.registrationNonSmart"));
          if (response.data.status === 3 || response.data.status === 4)
            setText(I18n.t("products.registrationExpired"));
        }
      } else {
        clearInterval(interval);
        setText(I18n.t("products.registrationUnsuccessful"));
      }
    }, 5000);
  };

  const confirmRegistration = async (registration) => {
    let iter = 0;
    const interval = setInterval(async () => {
      if (iter < 15) {
        iter++;
        const response = await getRegistrationApi.request(registration.pk);
        if (response.ok)
          if (response.data.device.is_online) {
            clearInterval(interval);
            setText(I18n.t("products.registrationSuccessful"));
          }
      } else {
        clearInterval(interval);
        setText(I18n.t("products.registrationDeleting"));
        await deleteRegistrationApi.request(registration.pk);
        setText(I18n.t("products.registrationUnsuccessful"));
      }
    }, 3000);
  };

  return (
    <View style={styles.container}>
      <AppText
        style={[
          styles.progressText,
          {
            color:
              text === I18n.t("products.registrationSuccessful")
                ? colors.success
                : text === I18n.t("products.registrationUnsuccessful") ||
                  text === I18n.t("products.registrationDuplicate") ||
                  text === I18n.t("products.registrationNonSmart") ||
                  text === I18n.t("products.registrationExpired") ||
                  text === I18n.t("products.registrationUnauthorized")
                ? colors.danger
                : colors.secondary,
          },
        ]}
      >
        {text}
      </AppText>
      <Progress.Bar
        size={200}
        progress={
          text === I18n.t("products.registrationConnecting")
            ? 0.2
            : text === I18n.t("products.registrationRegistering")
            ? 0.4
            : text === I18n.t("products.registrationConfirm")
            ? 0.75
            : text === I18n.t("products.registrationDeleting")
            ? 0.55
            : text === I18n.t("products.registrationSuccessful")
            ? 1
            : text === I18n.t("products.registrationUnsuccessful")
            ? 1
            : text === I18n.t("products.registrationDuplicate")
            ? 1
            : text === I18n.t("products.registrationNonSmart")
            ? 1
            : text === I18n.t("products.registrationExpired")
            ? 1
            : text === I18n.t("products.registrationUnauthorized")
            ? 1
            : 0
        }
        color={
          text === I18n.t("products.registrationSuccessful")
            ? colors.success
            : text === I18n.t("products.registrationUnsuccessful") ||
              text === I18n.t("products.registrationDuplicate") ||
              text === I18n.t("products.registrationNonSmart") ||
              text === I18n.t("products.registrationExpired") ||
              text === I18n.t("products.registrationUnauthorized")
            ? colors.danger
            : colors.secondary
        }
        borderWidth={1}
        thickness={7}
        strokeCap="round"
      />
      <AppText style={styles.text}>
        {I18n.t("products.PlaceNearRouter")}
      </AppText>
      <View style={styles.button}>
        <AppButton
          title={I18n.t("public.accept")}
          color={
            text === I18n.t("products.registrationSuccessful") ||
            text === I18n.t("products.registrationUnsuccessful") ||
            text === I18n.t("products.registrationDuplicate") ||
            text === I18n.t("products.registrationNonSmart") ||
            text === I18n.t("products.registrationExpired") ||
            text === I18n.t("products.registrationUnauthorized")
              ? colors.primary
              : colors.disabled
          }
          onPress={() => (window.location.href = "/")}
          disabled={
            !(
              text === I18n.t("products.registrationSuccessful") ||
              text === I18n.t("products.registrationUnsuccessful") ||
              text === I18n.t("products.registrationDuplicate") ||
              text === I18n.t("products.registrationNonSmart") ||
              text === I18n.t("products.registrationExpired") ||
              text === I18n.t("products.registrationUnauthorized")
            )
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    paddingHorizontal: 20,
  },
  progressText: {
    ...defaultStyle.textStyle,
    fontSize: 18,
    textAlign: "center",
  },
  button: {
    width: "100%",
    marginBottom: 50,
  },
  text: {
    textAlign: "center",
    padding: 20,
  },
});

export default ProductRegistration;
