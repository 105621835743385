import React from "react";
import { StyleSheet, View } from "react-native";

import AppCircleButton from "../../../../components/buttons/AppCircleButton";
import colors from "../../../../config/colors";
import UserWsInstance from "../../../../websocket/user";
import registrationData from "../../../../data/registration";

function SwitchChannel({ channel, reg, getRegData }) {
  const onPress = async (value) => {
    const response = await UserWsInstance.send(
      {
        command: "update_state",
        pk: reg.pk,
        index: channel.index,
        value: value,
        data: {},
      },
      {
        regIDSub: reg.reg_id_sub,
        mdnsDomain: reg.device.mdns_domain,
        localIP: reg.device.local_ip,
      }
    );
    if (response.type === "LocalAPI") {
      if (response.ok) {
        await registrationData.updateChannel(reg.pk, { ...channel, value });
        await getRegData();
      } else {
        await registrationData.offlineReg(reg.pk);
        await getRegData();
      }
    }
  };

  return (
    <View style={styles.container}>
      <AppCircleButton
        onPress={onPress}
        value={channel.value}
        disabled={!reg.device.is_online}
        onColor={colors.primary}
        offColor={colors.medium}
        icon={channel.value ? "lightbulb" : "lightbulb-outline"}
        text={undefined}
        withSound={true}
        width={50}
        height={50}
        type="MaterialIcons"
        debounce={800}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default SwitchChannel;
