import client from "./client";

// const getFamilyDeepList = () => client.get("family/list/deep");

const getFamilyDeep = (pk) => client.get("family/deep/" + pk);

const getFamilyList = () => client.get("family/list");

const getFamily = (pk) => client.get("family/" + pk);

const getFamilyPermission = (pk) => client.get("family/permission/" + pk);

const deleteFamily = (pk) => client.delete("family/" + pk);

const createFamily = (formData) => client.post("family/0", formData);

const updateFamily = (pk, formData) => client.put("family/" + pk, formData);

export default {
  getFamilyPermission,
  getFamilyDeep,
  getFamilyList,
  getFamily,
  updateFamily,
  deleteFamily,
  createFamily,
};
