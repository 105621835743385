import React from "react";
import { StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

function AppGradientView({ style, children }) {
  return (
    <LinearGradient
      colors={["rgba(255,255,255,0.15)", "transparent"]}
      start={[0, 1]}
      end={[1, 0]}
      style={[styles.gradient, style]}
    >
      {children}
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  gradient: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default AppGradientView;
