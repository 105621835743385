import React, { useState, useEffect } from "react";
import {
  GiftedChat,
  Send,
  InputToolbar,
  Bubble,
  Time,
} from "react-native-gifted-chat";
import { KeyboardAvoidingView, StyleSheet, View, Platform } from "react-native";
import "dayjs/locale/fa";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import defaultStyles from "../../config/styles";
import AppIcon from "../../components/AppIcon";
import colors from "../../config/colors";
import useApi from "../../hooks/useApi";
import messageApi from "../../api/message";
import I18n from "../../i18n";

function AccountChat() {
  const getMessageListApi = useApi(messageApi.getMessageList);
  const postMessageApi = useApi(messageApi.postMessage);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getMessageList();
  }, []);

  const getMessageList = async () => {
    const response = await getMessageListApi.request();
    if (response.ok && response.data) {
      let temp = [];
      response.data.forEach((item) => {
        temp.push({
          _id: item.pk,
          text: item.text,
          createdAt: item.created_date,
          sent: item.is_read,
          user: {
            _id: item.sender.is_me ? 1 : 2,
            name: item.sender.first_name + " " + item.sender.last_name,
            avatar: item.sender.profile_pic,
          },
        });
      });
      setMessages(temp);
    }
  };

  const sendMessage = async (message) => {
    const response = await postMessageApi.request({
      text: message[0].text,
    });
    if (response.ok) getMessageList();
  };

  return (
    <View style={styles.container}>
      <GiftedChat
        messages={messages}
        onSend={(message) => sendMessage(message)}
        user={{
          _id: 1,
        }}
        locale="fa"
        dateFormat="l"
        timeFormat="LT"
        maxInputLength={1000}
        placeholder={I18n.t("chat.messageText")}
        textInputStyle={[
          styles.textStyle,
          Platform.OS === "web" ? { outlineStyle: "none" } : null,
        ]}
        renderUsernameOnMessage
        renderInputToolbar={(props) => (
          <InputToolbar
            {...props}
            primaryStyle={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row-reverse",
              minHeight: 50,
            }}
          />
        )}
        renderSend={(props) => (
          <Send
            {...props}
            containerStyle={{
              height: 50,
              width: 50,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AppIcon name="send" size={25} color={colors.primary} />
          </Send>
        )}
        renderBubble={(props) => (
          <Bubble
            {...props}
            textStyle={{
              right: {
                fontFamily: "Font1",
                fontSize: 15,
                textAlign: "right",
              },
              left: {
                fontFamily: "Font1",
                fontSize: 15,
                textAlign: "right",
              },
            }}
            wrapperStyle={{
              right: {
                backgroundColor: colors.primary,
              },
              left: {
                backgroundColor: colors.light,
              },
            }}
          />
        )}
        renderTime={(props) => {
          dayjs.extend(utc);
          const date = dayjs(props.currentMessage.createdAt).utc(true);
          return (
            <Time
              {...props}
              currentMessage={{ ...props.currentMessage, createdAt: date }}
            />
          );
        }}
      />

      {Platform.OS === "android" && <KeyboardAvoidingView behavior="padding" />}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  textStyle: {
    ...defaultStyles.textStyle,
    margin: 0,
    padding: 0,
    marginRight: 15,
    fontSize: 15,
  },
});

export default AccountChat;
