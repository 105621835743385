import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";

import AppText from "../../../../components/AppText";
import colors from "../../../../config/colors";
import AppIcon from "../../../../components/AppIcon";
import AppIconButton from "../../../../components/buttons/AppIconButton";
import useApi from "../../../../hooks/useApi";
import actionApi from "../../../../api/action";

function Switch3gActionCard({ action, handleDelete, getActionList }) {
  const channels = action.registration.device.channels;
  const updateActionApi = useApi(actionApi.updateAction);

  const handleChangeValue = async () => {
    const response = await updateActionApi.request(action.pk, {
      value: action.value === 0 ? 1 : 0,
    });
    if (response.ok) getActionList();
  };

  return (
    <View
      style={[styles.container, updateActionApi.loading && { opacity: 0.3 }]}
    >
      <View style={styles.right}>
        <AppText style={styles.title} numberOfLines={1}>
          {action.registration.name}
        </AppText>
      </View>

      <View style={styles.left}>
        <TouchableOpacity onPress={handleChangeValue}>
          <View style={styles.stateContainer}>
            <AppIcon
              name={action.value ? "lightbulb" : "lightbulb-outline"}
              type="MaterialIcons"
              size={30}
              color={
                channels[0].pk !== action.channel.pk
                  ? colors.light
                  : colors.primary
              }
              style={
                channels[0].pk !== action.channel.pk && {
                  backgroundColor: colors.light,
                }
              }
            />
            <AppIcon
              name={action.value ? "lightbulb" : "lightbulb-outline"}
              type="MaterialIcons"
              size={30}
              color={
                channels[1].pk !== action.channel.pk
                  ? colors.light
                  : colors.primary
              }
              style={
                channels[1].pk !== action.channel.pk && {
                  backgroundColor: colors.light,
                }
              }
            />
            <AppIcon
              name={action.value ? "lightbulb" : "lightbulb-outline"}
              type="MaterialIcons"
              size={30}
              color={
                channels[2].pk !== action.channel.pk
                  ? colors.light
                  : colors.primary
              }
              style={
                channels[2].pk !== action.channel.pk && {
                  backgroundColor: colors.light,
                }
              }
            />
          </View>
        </TouchableOpacity>
        <AppIconButton
          size={30}
          name="delete"
          color={colors.danger}
          style={styles.deleteIcon}
          onPress={handleDelete}
        />
      </View>
      <ActivityIndicator
        animating={updateActionApi.loading}
        color={colors.primary}
        style={styles.loading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    backgroundColor: colors.white,
  },
  title: {
    textAlign: "center",
  },
  stateContainer: {
    flexDirection: "row",
    borderColor: colors.primary,
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    overflow: "hidden",
  },
  left: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  right: {
    flexDirection: "row-reverse",
    alignItems: "center",
    flex: 1,
  },
  durText: {
    fontSize: 15,
    marginHorizontal: 5,
  },
  deleteIcon: {
    marginRight: 20,
  },
  loading: {
    position: "absolute",
    right: "50%",
  },
});

export default Switch3gActionCard;
