import React, { useEffect, useState } from "react";
import { StyleSheet, Modal } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { FlatGrid } from "react-native-super-grid";

import memberApi from "../../../api/member";
import useApi from "../../../hooks/useApi";
import colors from "../../../config/colors";
import MemberQrCodeScanner from "./member/MemberQrCodeScanner";
import MemberCard from "./member/MemberCard";

function FamilyMembers({ familyPK }) {
  const isFocused = useIsFocused();
  const [owner, setOwner] = useState(null);
  const [members, setMembers] = useState([]);
  const [scannerModal, setScannerModal] = useState(false);
  const getMemberListApi = useApi(memberApi.getMemberList);

  useEffect(() => {
    if (isFocused) getMembers();
  }, [isFocused]);

  const getMembers = async () => {
    const response = await getMemberListApi.request(familyPK);
    if (response.ok) {
      setMembers(response.data.members);
      setOwner(response.data.owner);
    }
  };

  if (!owner) return null;

  const handleCloseScanner = () => {
    setScannerModal(false);
    getMembers();
  };

  const memList = owner.is_me
    ? [owner, ...members, { familyPK }]
    : [owner, ...members];

  return (
    <>
      <FlatGrid
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
        itemDimension={130}
        data={memList}
        spacing={10}
        style={styles.gridView}
        renderItem={({ item }) => (
          <MemberCard
            data={item}
            getMembers={getMembers}
            edit={owner.is_me}
            setScannerModal={setScannerModal}
          />
        )}
      />
      <Modal
        animationType="slide"
        transparent
        visible={scannerModal}
        onRequestClose={() => handleCloseScanner()}
      >
        {scannerModal && (
          <MemberQrCodeScanner
            closeModal={handleCloseScanner}
            familyPK={familyPK}
          />
        )}
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  gridView: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
    backgroundColor: colors.light,
  },
});

export default FamilyMembers;
