import client from "./client";

const sendCode = (formData) => client.post("code/send", formData);

const verifyCode = (formData) => client.post("code/verify", formData);

export default {
  sendCode,
  verifyCode,
};
