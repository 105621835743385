import React from "react";
import { Modal, View, StyleSheet, TouchableOpacity } from "react-native";
import colors from "../../../config/colors";

function DeviceModal({ children, visible, setVisible }) {
  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      onRequestClose={() => setVisible(false)}
    >
      <TouchableOpacity
        style={styles.modalContainer}
        onPress={() => setVisible(false)}
      >
        <View style={styles.modal}>{children}</View>
      </TouchableOpacity>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.dark + "CC",
  },
  modal: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "80%",
    width: "90%",
    borderRadius: 10,
  },
});

export default DeviceModal;
