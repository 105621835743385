import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import colors from "../../config/colors";
import AppIcon from "../AppIcon";

const margin = 0;

function AppIconButton({
  name,
  size = 30,
  radius = 1000,
  color_bg = colors.white,
  color = colors.primary,
  border = false,
  onPress,
  style,
  disabled = false,
  ...otherProps
}) {
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        style={[
          styles.button,
          border && {
            backgroundColor: color_bg,
            borderRadius: radius,
            borderColor: color,
            borderWidth: 1,
          },
        ]}
        onPress={onPress}
        activeOpacity={0.65}
        disabled={disabled}
      >
        <AppIcon
          name={name}
          size={size - margin}
          color={color}
          {...otherProps}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: margin,
  },
  button: {
    width: "100%",
  },
});

export default AppIconButton;
