import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View, LogBox } from "react-native";
import mime from "mime";

import AppText from "../../../../components/AppText";
import useApi from "../../../../hooks/useApi";
import otaApi from "../../../../api_local/ota";
import firmwareApi from "../../../../api/firmware";
import colors from "../../../../config/colors";
import AppButton from "../../../../components/buttons/AppButton";
import AppDownloadCard from "../../../../components/AppDownloadCard";
import AppLoadingIndicator from "../../../../components/lotties/AppLoadingIndicator";
import { AppErrorMessage } from "../../../../components/forms";
import UserWsInstance from "../../../../websocket/user";
import I18n from "../../../../i18n";

LogBox.ignoreLogs(["Setting a timer"]);

function RegistrationUpdate({ route }) {
  const { registration } = route.params;
  const checkFirmwareApi = useApi(firmwareApi.checkFirmware);
  const updateFirmwareApi = useApi(firmwareApi.updateFirmware);
  const otaUpdateApi = useApi(otaApi.otaUpdate);
  const [current, setCurrent] = useState({});
  const [latest, setLatest] = useState({});
  const [auth, setAuth] = useState(undefined);
  const [dlFile, setDlFile] = useState(undefined);
  const [step, setStep] = useState("INIT");
  const [updateData, setUpdateData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkFirmware();
  }, []);

  useEffect(() => {
    const callback = {
      command: "firmware_update" + registration.pk,
      func: (updateData) => setUpdateData(updateData),
    };
    UserWsInstance.addCallback(callback);
    return () => {
      UserWsInstance.removeCallback(callback);
    };
  }, []);

  useEffect(() => {
    if (updateData.state === "START") {
      setLoading(true);
      setTimeout(() => {
        if (updateData.state === "START") {
          setUpdateData({});
          setLoading(false);
          checkFirmware();
        }
      }, 180000);
    } else setLoading(false);
    if (updateData.state === "FINISH") checkFirmware();
  }, [updateData]);

  const checkFirmware = async () => {
    const response = await checkFirmwareApi.request(registration.pk);
    if (response.ok) {
      setCurrent(response.data.current);
      setLatest(response.data.latest);
      setAuth(response.data.authorization);
      setStep("INIT");
      if (response.data.current.version >= response.data.latest.version) {
        setStep("UPTD");
      } else if (response.data.current.version < response.data.latest.version) {
        setStep("UPA");
      }
    }
  };

  const handleUpdate = async () => {
    const file = {
      uri: dlFile,
      type: mime.getType(dlFile),
      name: dlFile.split(/[\\/]/).pop(),
    };
    let response = await otaUpdateApi.request(registration.device, file, auth);
    if (response.ok) {
      response = await updateFirmwareApi.request(registration.pk, {
        latest_pk: latest.pk,
      });
      if (response.ok) checkFirmware();
    }
  };

  const handleUpdateWS = async () => {
    await UserWsInstance.send(
      {
        command: "firmware_update",
        pk: registration.pk,
      },
      null
    );
  };

  return (
    <>
      <AppLoadingIndicator visible={otaUpdateApi.loading || loading} />
      <AppErrorMessage
        error={otaUpdateApi.error ? otaUpdateApi.data.error : null}
        visible={otaUpdateApi.error}
      />

      {step === "UPTD" && (
        <View style={styles.container1}>
          <>
            <AppText>LXOS V{current.version}</AppText>
            <AppText>{I18n.t("home.update.deviceIsUptodate")}</AppText>
          </>
        </View>
      )}
      {step === "UPA" && (
        <>
          <ScrollView
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled={true}
          >
            <View style={styles.container1}>
              <AppText>
                {I18n.t("home.update.currentVersion")}{" "}
                {"LXOS V" + current.version}
              </AppText>
              <AppText>
                {I18n.t("home.update.latestVersion")}{" "}
                {"LXOS V" + latest.version}
              </AppText>
            </View>
            <View style={styles.containerWS}>
              <AppText style={styles.title}>
                {I18n.t("home.update.updateChanges")}{" "}
                {"LXOS V" + latest.version}
              </AppText>
              <AppText style={styles.content}>{latest.changes}</AppText>

              <AppText style={styles.title}>
                {I18n.t("home.update.updateNotes")}
              </AppText>
              <AppText style={styles.content}>{latest.notes}</AppText>

              <AppText style={styles.title}>
                {I18n.t("home.update.updateWarnings")}
              </AppText>
              <AppText style={styles.content}>{latest.warnings}</AppText>
            </View>
          </ScrollView>
          <View style={styles.button}>
            {current.version >= 1.5 ? (
              <AppButton
                title={I18n.t("home.update.update")}
                color={colors.darkGreen}
                onPress={handleUpdateWS}
              />
            ) : (
              <AppButton
                title={I18n.t("home.update.update")}
                color={colors.primaryop}
                onPress={() => {
                  setDlFile(undefined);
                  setStep("UPD");
                }}
              />
            )}
          </View>
        </>
      )}
      {step === "UPD" && (
        <>
          <View style={styles.container1}>
            <AppDownloadCard
              fileUrl={latest.file}
              title={"LXOS V" + latest.version}
              setDlFile={setDlFile}
              icon="settings"
            />
          </View>
          <View style={styles.button}>
            <AppButton
              title={I18n.t("home.update.update")}
              color={colors.primary}
              onPress={handleUpdate}
              disabled={dlFile === undefined}
            />
          </View>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container1: {
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 20,
  },
  containerWS: {
    alignItems: "flex-end",
    justifyContent: "flex-start",
    margin: 20,
  },
  title: {
    textAlign: "right",
    fontSize: 16,
    color: colors.primary,
  },
  content: {
    textAlign: "right",
    fontSize: 13,
    margin: 10,
  },
  button: {
    position: "absolute",
    bottom: 50,
    width: "100%",
    padding: 20,
  },
});

export default RegistrationUpdate;
