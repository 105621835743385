import React, { useState, useEffect } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Camera } from "expo-camera";

import AppButton from "../../../../components/buttons/AppButton";
import AppText from "../../../../components/AppText";
import colors from "../../../../config/colors";
import memberApi from "../../../../api/member";
import useApi from "../../../../hooks/useApi";
import AppAlert1B from "../../../../components/AppAlert1B";
import AppScreen from "../../../../components/AppScreen";
import AppQRScanner from "../../../../components/lotties/AppQRScanner";
import I18n from "../../../../i18n";

export default function MemberQrCodeScanner({ closeModal, familyPK }) {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [text, setText] = useState(I18n.t("home.member.qrCodeScanDescription"));
  const [error, setError] = useState(false);
  const createMemberApi = useApi(memberApi.createMember);

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === "granted");
    })();
  }, []);

  const handleBarCodeScanned = async ({ data }) => {
    setScanned(true);
    const response = await createMemberApi.request(familyPK, {
      user_id: data,
    });
    if (response.ok) {
      closeModal();
    } else
      setTimeout(() => {
        setError(true);
        setText(I18n.t("home.member.qrCodeInvalid"));
        setScanned(false);
      }, 1500);
  };

  if (hasPermission === null) {
    return (
      <AppScreen>
        <AppText>{I18n.t("permission.cameraAccess")}</AppText>
      </AppScreen>
    );
  }
  if (hasPermission === false) {
    AppAlert1B(
      I18n.t("public.warning"),
      I18n.t("permission.cammeraAccessFailed"),
      () => closeModal()
    );
    return null;
  }

  return (
    <View style={styles.container}>
      <Camera
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        barCodeScannerSettings={
          Platform.OS === "web"
            ? {
                barCodeTypes: ["qr"],
              }
            : {}
        }
        style={StyleSheet.absoluteFillObject}
        barCodeTypes={["qr"]}
      />
      <View style={styles.anim}>
        <AppQRScanner />
      </View>

      <View style={styles.footer}>
        <View style={styles.textContainer}>
          <AppText
            style={[styles.text, error ? { color: colors.danger } : null]}
          >
            {text}
          </AppText>
        </View>
        <AppButton title={I18n.t("public.cancel")} onPress={closeModal} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  footer: {
    marginHorizontal: 10,
    marginBottom: 30,
  },
  textContainer: {
    backgroundColor: colors.light,
    padding: 10,
    borderRadius: 10,
  },
  text: {
    fontSize: 15,
    textAlign: "center",
  },
  anim: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
