import React from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import AppText from "../../../components/AppText";
import AppButton from "../../../components/buttons/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppErrorMessage,
} from "../../../components/forms";
import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import colors from "../../../config/colors";
import codeApi from "../../../api/code";
import useApi from "../../../hooks/useApi";
import validations from "../../../config/validations";
import I18n from "../../../i18n";

function CodeStep({ context }) {
  const validationSchema = Yup.object().shape({
    code: validations.code(),
  });

  const verifyCodeApi = useApi(codeApi.verifyCode);

  const handleSubmit = async ({ code }) => {
    const response = await verifyCodeApi.request({
      phone_number: context.formData["phoneNumber"],
      country: context.formData["country"].pk,
      reason: context.formData["reason"],
      code: code,
    });
    if (response.ok) {
      context.updateFormData({ code: code });
      context.nextStep();
    }
  };
  return (
    <AppForm
      initialValues={{ code: context.formData.code }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppText style={styles.title}>
        {I18n.t("auth.sendRegistrationCode")}
      </AppText>
      <AppLoadingIndicator visible={verifyCodeApi.loading} />
      <AppErrorMessage
        error={verifyCodeApi.error ? verifyCodeApi.data.error : null}
        visible={verifyCodeApi.error}
      />
      <AppFormField
        name="code"
        iconName="qr-code"
        placeholder={I18n.t("form.code")}
        keyboardType="number-pad"
      />

      <AppText style={styles.subTitle}>
        {I18n.t("auth.registrationCodeSentTo")}
        {context.formData.phoneNumber}
      </AppText>

      <View style={styles.buttonsContainer}>
        <View style={styles.button}>
          <AppSubmitButton title={I18n.t("public.accept")} />
        </View>
        <View style={styles.button}>
          <AppButton
            title={I18n.t("public.back")}
            color={colors.secondary}
            onPress={() => context.previousStep()}
          />
        </View>
      </View>
    </AppForm>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
    fontSize: 25,
    alignSelf: "flex-end",
  },
  subTitle: {
    fontSize: 12,
    textAlign: "center",
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  button: {
    width: "45%",
  },
});

export default CodeStep;
