import React from "react";
import { StyleSheet, Switch } from "react-native";
import colors from "../config/colors";

function AppSwitch({ value, onPress, disabled }) {
  return (
    <Switch
      trackColor={{ false: colors.light, true: colors.primary }}
      thumbColor={disabled ? colors.dark : colors.light}
      ios_backgroundColor={colors.light}
      onValueChange={onPress}
      value={value}
      disabled={disabled}
    />
  );
}

const styles = StyleSheet.create({});

export default AppSwitch;
