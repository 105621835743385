import React from "react";
import { StyleSheet, View } from "react-native";

import AppText from "../../../components/AppText";
import AppButton from "../../../components/buttons/AppButton";
import colors from "../../../config/colors";
import AppImage from "../../../components/AppImage";
import I18n from "../../../i18n";

function ProductBlink({ productDetail, nextStep }) {
  return (
    <View style={styles.container}>
      <View style={styles.animationContainer}>
        <AppImage
          source={{ uri: productDetail.registration_file_1 }}
          style={styles.gif}
          resizeMode="contain"
        />
      </View>

      <AppText style={styles.text1}>
        {productDetail.registration_text_1}
      </AppText>
      <AppText style={styles.text2}>
        {productDetail.registration_text_2}
      </AppText>
      <View style={styles.button}>
        <AppButton
          title={I18n.t("public.accept")}
          color={colors.primary}
          onPress={() => nextStep()}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 20,
  },
  animationContainer: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  gif: {
    width: "80%",
    height: "100%",
  },
  text1: {
    textAlign: "center",
    padding: 20,
    fontSize: 12,
  },
  text2: {
    textAlign: "center",
    padding: 20,
    fontSize: 17,
  },
  button: {
    width: "100%",
    marginBottom: 50,
  },
});

export default ProductBlink;
