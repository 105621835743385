import React from "react";
import { StyleSheet, View } from "react-native";
import * as Progress from "react-native-progress";
import colors from "../config/colors";

function AppWizardIndicator({ now, total }) {
  return (
    <View style={styles.container}>
      <Progress.Bar
        progress={(now + 1) / total}
        width={null}
        color={colors.primary}
        borderWidth={0.5}
        height={5}
        borderRadius={0}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export default AppWizardIndicator;
