import React from "react";
import { StyleSheet, View } from "react-native";
import { useFormikContext } from "formik";

import AppErrorMessage from "./AppErrorMessage";
import AppIcon from "../AppIcon";
import AppText from "../AppText";
import colors from "../../config/colors";
import defaultStyle from "../../config/styles";
import AppSwitch from "../AppSwitch";

function AppSwitchField({
  title,
  name,
  iconName,
  width = "100%",
  onChange = (s) => null,
}) {
  const { errors, touched, setFieldValue, values } = useFormikContext();

  return (
    <>
      <View style={[styles.container, { width: width }]}>
        {iconName && <AppIcon name={iconName} size={20} style={styles.icon} />}
        <AppText style={styles.text}>{title}</AppText>
        <AppSwitch
          value={values[name]}
          onPress={() => {
            setFieldValue(name, !values[name]);
            onChange(!values[name]);
          }}
        />
      </View>
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 13,
    marginVertical: 10,
    borderRadius: 10,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  text: {
    ...defaultStyle.textStyle,
    textAlign: "right",
    flex: 1,
  },
  icon: {
    marginLeft: 10,
  },
});

export default AppSwitchField;
