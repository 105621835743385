import React, { useRef, useEffect, useState } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { useNavigation } from "@react-navigation/native";
import moment from "moment-jalaali";

import AppText from "../../components/AppText";
import { AppListItemAction } from "../../components/lists";
import colors from "../../config/colors";
import UserWsInstance from "../../websocket/user";
import routes from "../../config/routes";
import AppIcon from "../../components/AppIcon";

function SceneListItem({ scene }) {
  const momento = scene.moment_set[0];
  const navigation = useNavigation();
  const swipeRef = useRef(null);
  const [state, setState] = useState(scene.state);
  const launchScene = () => {
    UserWsInstance.send({ command: "launch_scene", pk: scene.pk });
  };

  useEffect(() => {
    setState(scene.state);
  }, [scene.state]);

  useEffect(() => {
    const callback = {
      command: "update_scene" + scene.pk,
      func: (state) => setState(state),
    };
    UserWsInstance.addCallback(callback);
    return () => {
      UserWsInstance.removeCallback(callback);
    };
  }, []);

  return (
    <View style={styles.container}>
      <Swipeable
        ref={swipeRef}
        renderRightActions={() =>
          scene.family.permission.edit && (
            <AppListItemAction
              color={colors.info}
              name="edit"
              onPress={() => {
                swipeRef.current.close();
                navigation.navigate(routes.SCENE_EDIT, { scene });
              }}
            />
          )
        }
      >
        <TouchableOpacity
          onPress={launchScene}
          activeOpacity={0.45}
          style={styles.containerItem}
        >
          <View style={styles.containerRight}>
            <AppText style={styles.text1} numberOfLines={1}>
              {scene.name}
            </AppText>
          </View>
          <View style={styles.containerLeft}>
            {momento.active ? (
              <>
                <View>
                  <AppText style={styles.time}>
                    {moment(momento.time, "YYYY-MM-DDTHH:mm:ssZ").format(
                      "HH:mm"
                    )}
                  </AppText>

                  <AppText style={styles.time}>
                    {moment(momento.time, "YYYY-MM-DDTHH:mm:ssZ").format(
                      "jYYYY/jMM/jDD"
                    )}
                  </AppText>
                </View>
                <AppIcon name="timer" size={50} color={colors.primary} />
              </>
            ) : null}
            <View
              style={[
                styles.state,
                {
                  backgroundColor:
                    state === 1
                      ? colors.success
                      : state === 2
                      ? colors.danger
                      : colors.info,
                },
              ]}
            ></View>
          </View>
        </TouchableOpacity>
      </Swipeable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 7,
    marginHorizontal: 10,
    borderRadius: 10,
    overflow: "hidden",
  },
  containerItem: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: colors.white,
  },

  containerRight: {
    flex: 1,
    padding: 10,
  },
  text1: {
    fontSize: 17,
  },

  containerLeft: {
    flex: 1,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  state: {
    borderRadius: 0,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: 12,
    height: 70,
  },

  time: {
    textAlign: "left",
    color: colors.primary,
  },
});

export default SceneListItem;
