import AppStore from "../data/AppStore";

const key = "authToken";

const storeToken = async (authToken) => {
  try {
    await AppStore.setItem(key, authToken);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};

const getToken = async () => {
  try {
    const token = await AppStore.getItem(key);
    return JSON.parse(token);
  } catch (error) {
    console.log("Error getting the auth token", error);
  }
};

const removeToken = async () => {
  try {
    return await AppStore.deleteItem(key);
  } catch (error) {
    console.log("Error removing the auth token", error);
  }
};

export default { storeToken, getToken, removeToken };
