import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";

import AppText from "../../components/AppText";
import constants from "../../config/constants";
import colors from "../../config/colors";
import AppImageRounded from "../../components/AppImageRounded";
import AppIcon from "../../components/AppIcon";
import AppIconButton from "../../components/buttons/AppIconButton";
import i18n from "../../i18n";
import useApi from "../../hooks/useApi";
import googleAccountApi from "../../api/googleAccount";

WebBrowser.maybeCompleteAuthSession();

function AccountGoogleCard() {
  const [userInfo, setUserInfo] = useState(undefined);
  const getGoogleAccountApi = useApi(googleAccountApi.getGoogleAccount);
  const createGoogleAccountApi = useApi(googleAccountApi.createGoogleAccount);

  const [request, response, promptAsync] = Google.useAuthRequest(
    constants.googleOAuth2Config
  );

  useEffect(() => {
    getGoogleAccount();
  }, []);

  useEffect(() => {
    if (response?.type === "success") {
      createGoogleAccount(response.authentication.accessToken);
    }
  }, [response]);

  const createGoogleAccount = async (accessToken) => {
    const response = await createGoogleAccountApi.request(accessToken);
    if (response?.ok) setUserInfo(response.data);
  };

  const getGoogleAccount = async () => {
    const response = await getGoogleAccountApi.request();
    if (response?.ok) setUserInfo(response.data);
  };

  return (
    <View style={styles.container}>
      {userInfo ? (
        <AppImageRounded uri={userInfo.picture} size={25} />
      ) : (
        <AppIcon size={20} name="google" type="MaterialCommunityIcons" />
      )}
      <View style={styles.email}>
        <AppText
          style={[
            styles.emailText,
            userInfo
              ? { color: colors.dark, textAlign: "center" }
              : { color: colors.medium },
          ]}
        >
          {userInfo ? userInfo.email : i18n.t("form.email")}
        </AppText>
      </View>
      <AppIconButton
        type="FontAwesome"
        name="exchange"
        size={20}
        onPress={() => {
          promptAsync({ showInRecents: true });
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: "100%",
    borderRadius: 10,
    padding: 13,
    marginVertical: 10,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
  },

  email: {
    flex: 1,
    marginHorizontal: 10,
  },
  emailText: {},
});

export default AccountGoogleCard;
