import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import AccountScreen from "../screens/account/AccountScreen";
import AccountQrCodePrinter from "../screens/account/AccountQrCodePrinter";
import defaultStyles from "../config/styles";
import AccountProfile from "../screens/account/AccountProfile";
import AccountSession from "../screens/account/AccountSession";
import AccountChat from "../screens/account/AccountChat";
import AccountPasswordChange from "../screens/account/AccountPasswordChange";
import AccountPhoneNumberChange from "../screens/account/AccountPhoneNumberChange";
import LanguageSelect from "../screens/general/LanguageSelect";
import I18n from "../i18n";

const Stack = createStackNavigator();

function AccountNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultStyles.stackScreenStyle}>
      <Stack.Screen
        name="Account"
        component={AccountScreen}
        options={{
          headerShown: false,
          title: I18n.t("account.account"),
        }}
      />
      <Stack.Screen
        name="Profile"
        component={AccountProfile}
        options={{
          title: I18n.t("account.profile"),
        }}
      />
      <Stack.Screen
        name="QrCode"
        component={AccountQrCodePrinter}
        options={{
          title: I18n.t("account.qrCode"),
        }}
      />

      <Stack.Screen
        name="SessionManagement"
        component={AccountSession}
        options={{
          title: I18n.t("account.sessionManagement"),
        }}
      />
      <Stack.Screen
        name="Chat"
        component={AccountChat}
        options={{
          title: I18n.t("account.onlineChat"),
        }}
      />
      <Stack.Screen
        name="PasswordChange"
        component={AccountPasswordChange}
        options={{
          title: I18n.t("account.changePassword"),
        }}
      />
      <Stack.Screen
        name="PhoneNumberChange"
        component={AccountPhoneNumberChange}
        options={{
          title: I18n.t("account.changePhoneNumber"),
        }}
      />
      <Stack.Screen
        name="LanguageSelect"
        component={LanguageSelect}
        options={{
          title: I18n.t("account.selectLanguage"),
        }}
      />
    </Stack.Navigator>
  );
}

export default AccountNavigator;
