import React from "react";
import { StyleSheet, Platform } from "react-native";
import { WebView } from "react-native-webview";

function AppIframe({ src, height }) {
  if (Platform.OS === "web") return <iframe src={src} height={height} />;
  return (
    <WebView
      source={{
        html: '<iframe width="100%" height="100%" src="' + src + '" />',
      }}
      style={[styles.webView, { height: height }]}
      scrollEnabled={false}
    />
  );
}

const styles = StyleSheet.create({
  webView: {
    marginVertical: 5,
    width: "100%",
    backgroundColor: "transparent",
  },
});

export default AppIframe;
