export default Object.freeze({
  LANGUAGE_SELECT: "LanguageSelect",

  WELCOME: "Welcome",
  LOGIN: "Login",
  SIGNUP: "SignUp",
  PRIVACY: "Privacy",

  HOME: "Home",
  SCENES: "Scenes",
  PRODUCTS: "Products",
  ACCOUNT: "Account",

  FAMILY_SETTINGS: "FamilySettings",
  ZONE_EDIT: "ZoneEdit",

  REGISTRATION_DETAIL: "RegistrationDetail",
  REGISTRATION_EDIT: "RegistrationEdit",
  REGISTRATION_ABOUT: "RegistrationAbout",
  REGISTRATION_UPDATE: "RegistrationUpdate",

  PRODUCT_LIST: "ProductList",
  PRODUCT_DETAIL: "ProductDetail",

  PROFILE: "Profile",
  QR_CODE: "QrCode",
  PASSWORD_CHANGE: "PasswordChange",
  PASSWORD_RECOVER: "PasswordRecover",
  PHONE_NUMBER_CHANGE: "PhoneNumberChange",
  SESSION_MANAGEMENT: "SessionManagement",
  CHAT: "Chat",

  SCENE_EDIT: "SceneEdit",
  ACTION_CREATE: "ActionCreate",
});
