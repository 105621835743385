import React, { useState } from "react";
import { StyleSheet, TextInput, View, Platform } from "react-native";

import colors from "../../config/colors";
import AppIcon from "../AppIcon";
import defaultStyle from "../../config/styles";
import AppText from "../AppText";
import AppIconButton from "../buttons/AppIconButton";

function AppPasswordInput({
  width = "100%",
  iconName,
  prefixLabel,
  ...otherProps
}) {
  const [hide, setHide] = useState(true);
  return (
    <View style={[styles.container, { width: width }]}>
      {iconName && <AppIcon name={iconName} size={20} style={styles.icon} />}
      <TextInput
        placeholderTextColor={colors.medium}
        style={[
          styles.text,
          Platform.OS === "web" ? { outlineStyle: "none" } : null,
        ]}
        autoCorrect={false}
        secureTextEntry={hide}
        {...otherProps}
      />
      {prefixLabel && <AppText style={styles.prefix}>{prefixLabel}</AppText>}
      <AppIconButton
        name={hide ? "ios-eye" : "ios-eye-off"}
        size={20}
        style={styles.prefix}
        onPress={() => setHide(!hide)}
        type="Ionicons"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 13,
    marginVertical: 10,
    borderRadius: 10,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  text: {
    ...defaultStyle.textStyle,
    textAlign: "right",
    flex: 1,
  },
  icon: {
    marginLeft: 10,
  },
  prefix: {
    color: colors.primary,
  },
});

export default AppPasswordInput;
