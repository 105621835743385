import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

const setItem = async (key, data) => {
  if (Platform.OS === "web")
    await AsyncStorage.setItem(key, JSON.stringify(data));
  else await SecureStore.setItemAsync(key, JSON.stringify(data));
};

const getItem = async (key) => {
  let id = null;
  if (Platform.OS === "web") id = await AsyncStorage.getItem(key);
  else id = await SecureStore.getItemAsync(key);
  return id;
};

const deleteItem = async (key) => {
  if (Platform.OS === "web") await AsyncStorage.removeItem(key);
  else await SecureStore.deleteItemAsync(key);
};

export default { setItem, getItem, deleteItem };
