import I18n from "i18n-js";

import fa from "./fa.json";
import en from "./en.json";

I18n.translations = {
  en,
  fa,
};

I18n.fallbacks = true;
export default I18n;
