import { useState } from "react";

import I18n from "../i18n";

const useApi = (apiFunc) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    let response = await apiFunc(...args);

    if (!response.ok) {
      if (response.data === null)
        response = { ...response, data: { error: I18n.t("error.serverLoss") } };
      if (response.status === 429)
        response = {
          ...response,
          data: { error: I18n.t("error.tooManyRequests") },
        };
      if (response.data.error === undefined)
        response = {
          ...response,
          data: { ...response.data, error: I18n.t("error.badRequest") },
        };
    }

    setError(!response.ok);
    setData(response.data);
    setLoading(false);

    return response;
  };

  return { data, error, loading, request };
};

export default useApi;
