import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";

import useApi from "../../hooks/useApi";
import sessionApi from "../../api/session";
import AccountSessionItem from "./AccountSessionItem";
import AppScreen from "../../components/AppScreen";
import AppFlatList from "../../components/AppFlatList";

function AccountSession() {
  const [sessions, setSessions] = useState(undefined);
  const getSessionListApi = useApi(sessionApi.getSessionList);
  const deleteSessionApi = useApi(sessionApi.deleteSession);

  useEffect(() => {
    getMySessionList();
  }, []);

  const getMySessionList = async () => {
    const response = await getSessionListApi.request();
    if (response.ok) setSessions(response.data);
  };

  const handleDelete = async (pk) => {
    const response = await deleteSessionApi.request(pk);
    if (response.ok) getMySessionList();
  };

  if (!sessions) return null;

  return (
    <AppScreen>
      <View style={styles.listContainer}>
        <AppFlatList
          data={sessions}
          keyExtractor={(item) => String(item.pk)}
          renderItem={({ item }) => (
            <AccountSessionItem session={item} handleDelete={handleDelete} />
          )}
        />
      </View>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    width: "95%",
    flex: 1,
  },
});

export default AccountSession;
