import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import AppButton from "../../../../components/buttons/AppButton";
import colors from "../../../../config/colors";
import useApi from "../../../../hooks/useApi";
import actionApi from "../../../../api/action";
import I18n from "../../../../i18n";
import AppIconButton from "../../../../components/buttons/AppIconButton";
import AppText from "../../../../components/AppText";

function Switch2gActionEdit({ reg, scene }) {
  const createActionApi = useApi(actionApi.createAction);
  const [chIndex, setChIndex] = useState(0);
  const navigation = useNavigation();

  const handleSubmit = async (channel, registration) => {
    const response = await createActionApi.request(scene.pk, {
      channel: channel.pk,
      registration: registration.pk,
    });
    if (response.ok) navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <View style={styles.state}>
        <View style={styles.gang}>
          <AppIconButton
            name="lightbulb"
            type="MaterialIcons"
            size={50}
            color={chIndex === 0 ? colors.primary : colors.medium}
            onPress={() => setChIndex(0)}
          />
          <AppIconButton
            name="lightbulb"
            type="MaterialIcons"
            size={50}
            color={chIndex === 1 ? colors.primary : colors.medium}
            onPress={() => setChIndex(1)}
          />
        </View>
        <AppText style={styles.gangText}>
          {I18n.t("public.gang") +
            " " +
            (chIndex === 0 ? I18n.t("number.first") : I18n.t("number.second"))}
        </AppText>
      </View>
      <AppButton
        color={colors.primary}
        onPress={() => handleSubmit(reg.device.channels[chIndex], reg)}
        title={I18n.t("public.accept")}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  state: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.primary,
    paddingTop: 20,
    paddingBottom: 20,
  },
  gang: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
  },
  gangText: {
    textAlign: "center",
  },
});

export default Switch2gActionEdit;
