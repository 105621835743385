import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import * as FileSystem from "expo-file-system";
import * as Progress from "react-native-progress";

import colors from "../config/colors";
import AppText from "./AppText";
import AppOutlineIcon from "./AppOutlineIcon";

function AppDownloadCard({ fileUrl, title, setDlFile, icon }) {
  const [state, setState] = useState({ downloadProgress: 0 });

  useEffect(() => {
    handleDownload();
  }, []);

  const handleDownload = async () => {
    const callback = (downloadProgress) => {
      const progress =
        downloadProgress.totalBytesWritten /
        downloadProgress.totalBytesExpectedToWrite;
      setState({
        downloadProgress: progress,
      });
    };

    const downloadResumable = FileSystem.createDownloadResumable(
      fileUrl,
      FileSystem.cacheDirectory + fileUrl.split(/[\\/]/).pop(),
      {},
      callback
    );

    try {
      const { uri } = await downloadResumable.downloadAsync();
      setDlFile(uri);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.card}>
        <AppOutlineIcon
          name={icon}
          size={65}
          backgroundColor={colors.primary}
          iconColor={colors.white}
        />
        <View style={styles.leftContainer}>
          <AppText style={styles.title}>{title}</AppText>
          <Progress.Bar
            progress={state.downloadProgress}
            width={null}
            color={colors.dark}
            borderWidth={0.5}
            height={1.5}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  card: {
    backgroundColor: colors.white,
    marginHorizontal: 10,
    padding: 10,
    borderRadius: 10,
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  title: {
    marginHorizontal: 10,
  },
  leftContainer: {
    flex: 1,
    marginHorizontal: 10,
  },
});

export default AppDownloadCard;
