import { useContext } from "react";
import UserWsInstance from "../websocket/user";

import AuthContext from "./context";
import authStorage from "./storage";
import familyData from "../data/family";

const useAuth = () => {
  const { token, setToken, setIsFirst } = useContext(AuthContext);

  const logIn = (token) => {
    setIsFirst(true);
    setToken(token);
    authStorage.storeToken(token);
  };

  const logOut = () => {
    setToken(null);
    UserWsInstance.disconnect();
    authStorage.removeToken();
    familyData.remove();
  };

  const getToken = () => {
    return token;
  };

  return { getToken, logIn, logOut };
};

export default useAuth;
