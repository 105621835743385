import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import defaultStyles from "../config/styles";
import HomeScreen from "../screens/home/HomeScreen";
import FamilySettings from "../screens/home/family/FamilySettings";
import ZoneEdit from "../screens/home/family/ZoneEdit";
import RegistrationDetail from "../screens/home/family/registration/RegistrationDetail";
import RegistrationEdit from "../screens/home/family/registration/RegistrationEdit";
import RegistrationAbout from "../screens/home/family/registration/RegistrationAbout";
import RegistrationUpdate from "../screens/home/family/registration/RegistrationUpdate";
import I18n from "../i18n";

const Stack = createStackNavigator();

function HomeNavigator() {
  return (
    <Stack.Navigator screenOptions={defaultStyles.stackScreenStyle}>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: false,
          title: I18n.t("home.home"),
        }}
      />
      <Stack.Screen
        options={{
          title: I18n.t("home.familySetting"),
        }}
        name="FamilySettings"
        component={FamilySettings}
      />
      <Stack.Screen
        options={{
          title: I18n.t("home.zoneInfo"),
        }}
        name="ZoneEdit"
        component={ZoneEdit}
      />

      <Stack.Screen
        options={{
          title: I18n.t("home.deviceDetail"),
        }}
        name="RegistrationDetail"
        component={RegistrationDetail}
      />
      <Stack.Screen
        options={{
          title: I18n.t("home.deviceEdit"),
        }}
        name="RegistrationEdit"
        component={RegistrationEdit}
      />
      <Stack.Screen
        options={{
          title: I18n.t("home.deviceAbout"),
        }}
        name="RegistrationAbout"
        component={RegistrationAbout}
      />
      <Stack.Screen
        options={{
          title: I18n.t("home.deviceUpdate"),
        }}
        name="RegistrationUpdate"
        component={RegistrationUpdate}
      />
    </Stack.Navigator>
  );
}

export default HomeNavigator;
