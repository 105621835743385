import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import * as Yup from "yup";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";

import AppText from "../../../components/AppText";
import AppButton from "../../../components/buttons/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppErrorMessage,
  AppPasswordField,
} from "../../../components/forms";
import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import colors from "../../../config/colors";
import userApi from "../../../api/user";
import useApi from "../../../hooks/useApi";
import useAuth from "../../../auth/useAuth";
import validations from "../../../config/validations";
import appId from "../../../identifier/appId";
import I18n from "../../../i18n";

function SignupStep({ context }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(I18n.t("error.required")),
    lastName: Yup.string().required(I18n.t("error.required")),
    password: validations.password(),
  });

  const { logIn } = useAuth();
  const navigation = useNavigation();
  const signupApi = useApi(userApi.signup);

  const handleSubmit = async ({ firstName, lastName, password }) => {
    const response = await signupApi.request({
      phone_number: context.formData["phoneNumber"],
      code: context.formData["code"],
      reason: context.formData["reason"],
      country: context.formData["country"].pk,
      first_name: firstName,
      last_name: lastName,
      password: password,
      app_id: await appId.getAppId(),
      device_name: Constants.deviceName,
      app_version: Constants.manifest.version,
      platform: Platform.OS,
    });
    if (response.ok) logIn(response.data.token);
  };
  return (
    <AppForm
      initialValues={{
        firstName: "",
        lastName: "",
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AppText style={styles.title}>
        {I18n.t("auth.finalizeRegistration")}
      </AppText>
      <AppLoadingIndicator visible={signupApi.loading} />
      <AppErrorMessage
        error={signupApi.error ? signupApi.data.error : null}
        visible={signupApi.error}
      />
      <AppFormField
        name="firstName"
        iconName="person"
        placeholder={I18n.t("form.firstName")}
      />
      <AppFormField
        name="lastName"
        iconName="person-search"
        placeholder={I18n.t("form.lastName")}
      />
      <AppPasswordField
        name="password"
        iconName="lock"
        placeholder={I18n.t("form.password")}
      />

      <View style={styles.buttonsContainer}>
        <View style={styles.button}>
          <AppSubmitButton title={I18n.t("public.accept")} />
        </View>
        <View style={styles.button}>
          <AppButton
            title={I18n.t("public.back")}
            color={colors.secondary}
            onPress={() => context.previousStep()}
          />
        </View>
      </View>
    </AppForm>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
    fontSize: 25,
    alignSelf: "flex-end",
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  button: {
    width: "45%",
  },
  textContainer: {
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
  },
});

export default SignupStep;
