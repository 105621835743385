import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  Platform,
} from "react-native";
import * as ImagePicker from "expo-image-picker";

import colors from "../../config/colors";
import AppIcon from "../AppIcon";
import AppImage from "../AppImage";
import I18n from "../../i18n";

function AppCircleImagePickerInput({
  value,
  size,
  onChange,
  disabled = false,
}) {
  const [image, setImage] = useState();

  useEffect(() => {
    (async () => {
      setImage(value);

      if (Platform.OS === "web") return;

      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert(I18n.t("permission.galleryAccessFailed"));
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
    });

    if (result.cancelled) return;

    if (Platform.OS === "web") {
      const file = dataURLtoFile(result.uri, getFilename(result.uri));
      onChange(file);
      setImage(result.uri);
      return;
    }

    let uriParts = result.uri.split(".");
    let fileType = uriParts[uriParts.length - 1];
    onChange({
      uri: result.uri,
      name: `photo.${fileType}`,
      type: `image/${fileType}`,
    });
    setImage(result.uri);
  };

  const getFilename = (dataUrl) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];

    return Math.round(+new Date() / 1000) + "." + mime.split("/").pop();
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <View style={[styles.container, { height: size, width: size }]}>
      {image ? (
        <AppImage style={styles.image} source={{ uri: image }} />
      ) : (
        <View style={styles.WoImage}>
          <AppIcon name="image" color={colors.primary} size={size / 3} />
        </View>
      )}
      {!disabled && (
        <TouchableWithoutFeedback onPress={pickImage}>
          <View style={styles.button}>
            <AppIcon name="edit" size={25} color={colors.white} />
          </View>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "black",
    borderRadius: 1000,
    borderWidth: 2,
    borderColor: colors.primary,
    overflow: "hidden",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  WoImage: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.light,
  },
  button: {
    position: "absolute",
    bottom: 0,
    backgroundColor: colors.primary,
    width: "100%",
    height: "20%",
    alignItems: "center",
    overflow: "hidden",
    opacity: 0.7,
  },
});

export default AppCircleImagePickerInput;
