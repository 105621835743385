import client from "./client";

const getZone = (pk) => client.get("zone/" + pk);
const updateZone = (pk, formData) => client.put("zone/" + pk, formData);
const deleteZone = (pk) => client.delete("zone/" + pk);

const getZoneList = (familyPK) => client.get("zone/list/" + familyPK);
const createZone = (familyPK, formData) =>
  client.post("zone/" + familyPK, formData);

export default {
  getZone,
  updateZone,
  deleteZone,
  getZoneList,
  createZone,
};
