import colors from "./colors";
import { DefaultTheme } from "@react-navigation/native";

export default {
  stackScreenStyle: {
    headerTitleStyle: {
      fontFamily: "Font1",
      color: colors.white,
      textAlign: "right",
    },
    headerBackTitleStyle: {
      fontFamily: "Font1",
      color: colors.white,
      fontSize: 12,
    },
    headerTintColor: colors.white,
    headerStyle: { backgroundColor: colors.primary },
  },

  tabBarStyle: {
    labelStyle: { fontFamily: "Font1" },
  },

  navigationStyle: {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.primary,
      background: colors.light,
    },
  },

  textStyle: {
    color: colors.dark,
    fontSize: 15,
    fontFamily: "Font1",
    textAlign: "right",
  },
};
