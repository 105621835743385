import React, { useEffect } from "react";
import { StyleSheet, View, Linking, Platform } from "react-native";
import { startActivityAsync, ActivityAction } from "expo-intent-launcher";
import uuid from "react-native-uuid";

import AppText from "../../../components/AppText";
import useApi from "../../../hooks/useApi";
import registrationApi from "../../../api_local/registration";
import AppRadarScanner from "../../../components/lotties/AppRadarScanner";
import AppButton from "../../../components/buttons/AppButton";
import colors from "../../../config/colors";
import I18n from "../../../i18n";

function ProductConnection({ productDetail, setModuleInfo, nextStep }) {
  const getModuleInfoApi = useApi(registrationApi.getModuleInfo);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Platform.OS !== "web") getModuleInfo();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getModuleInfo = async () => {
    const response = await getModuleInfoApi.request(
      productDetail.firmware.local_url
    );
    if (response.ok)
      if (response.data !== undefined)
        if (response.data.mac_address !== undefined) {
          setModuleInfo(response.data);
          nextStep();
        }
  };

  return (
    <View style={styles.container}>
      <View style={styles.animationContainer}>
        <AppRadarScanner />
      </View>

      <AppText style={styles.text1}>
        {I18n.t("products.connectPhoneToAp")}
        {productDetail.firmware.ssid_prefix + "xxxx"}
      </AppText>

      {Platform.OS === "web" && (
        <AppText style={styles.text2}>
          {I18n.t("products.goToWifiSetupPage")}
        </AppText>
      )}

      <View style={styles.button}>
        {Platform.OS !== "web" ? (
          <AppButton
            title={I18n.t("products.wifiSettings")}
            color={colors.primaryop}
            onPress={() =>
              Platform.OS === "ios"
                ? Linking.openURL("App-Prefs:WIFI")
                : Platform.OS === "android"
                ? startActivityAsync(ActivityAction.WIFI_SETTINGS)
                : null
            }
          />
        ) : (
          <AppButton
            title={I18n.t("products.enterWiFiCredentials")}
            color={colors.darkGreen}
            onPress={() => {
              const regID = uuid.v4();
              window.open(
                productDetail.firmware.local_url +
                  "/registration?reg_id=" +
                  regID
              );
            }}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 20,
  },
  animationContainer: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  text1: {
    marginBottom: 50,
    textAlign: "center",
    padding: 20,
    fontSize: 17,
  },
  text2: {
    marginBottom: 50,
    textAlign: "center",
    padding: 20,
    fontSize: 12,
  },
  button: {
    width: "100%",
    marginBottom: 50,
  },
});

export default ProductConnection;
