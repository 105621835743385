import React, { Component } from "react";
import { View, StyleSheet, TouchableHighlight } from "react-native";
import Swipeable from "react-native-gesture-handler/Swipeable";

import AppIcon from "../AppIcon";
import AppText from "../AppText";
import colors from "../../config/colors";
import AppImage from "../AppImage";

class AppListItem extends Component {
  constructor(props) {
    super(props);
    this.swipeableRef = React.createRef();
  }
  cloasSwipeable = () => this.swipeableRef.current.close();

  render() {
    const {
      title,
      subTitle,
      imageUrl,
      imageSize = 70,
      LeftComponent = null,
      arrowIconName = "chevron-left",
      IconComponent,
      onPress,
      space,
      renderRightActions,
    } = this.props;
    return (
      <Swipeable
        renderRightActions={renderRightActions}
        ref={this.swipeableRef}
      >
        <TouchableHighlight
          underlayColor={colors.light}
          onPress={onPress}
          activeOpacity={0.8}
        >
          <View style={[styles.container, space && { marginTop: 20 }]}>
            {IconComponent}
            {imageUrl && (
              <AppImage
                style={{
                  width: imageSize,
                  height: imageSize,
                  borderRadius: imageSize,
                }}
                source={{ uri: imageUrl }}
              />
            )}
            <View style={styles.detailsContainer}>
              <AppText style={styles.title} numberOfLines={1}>
                {title}
              </AppText>
              {subTitle && (
                <AppText style={styles.subTitle} numberOfLines={2}>
                  {subTitle}
                </AppText>
              )}
            </View>
            <View style={styles.LeftComponent}>{LeftComponent}</View>
            <AppIcon color={colors.medium} name={arrowIconName} size={25} />
          </View>
        </TouchableHighlight>
      </Swipeable>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row-reverse",
    padding: 10,
    backgroundColor: colors.white,
  },
  detailsContainer: {
    flex: 1,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  subTitle: {
    color: colors.medium,
    fontSize: 13,
  },
  title: {
    fontSize: 15,
  },
  LeftComponent: {
    marginLeft: 5,
  },
});

export default AppListItem;
