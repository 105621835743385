import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native";
import * as Yup from "yup";

import colors from "../../../config/colors";
import zoneApi from "../../../api/zone";
import useApi from "../../../hooks/useApi";
import AppLoadingIndicator from "../../../components/lotties/AppLoadingIndicator";
import {
  AppErrorMessage,
  AppForm,
  AppFormField,
} from "../../../components/forms";
import AppAlert2B from "../../../components/AppAlert2B";
import I18n from "../../../i18n";
import routes from "../../../config/routes";
import AppIconButton from "../../../components/buttons/AppIconButton";

function ZoneEdit({ route, navigation }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(I18n.t("error.required")),
  });

  const { familyPK, zonePK } = route.params;
  const [zone, setZone] = useState(undefined);
  const getZoneApi = useApi(zoneApi.getZone);
  const updateZoneApi = useApi(zoneApi.updateZone);
  const createZoneApi = useApi(zoneApi.createZone);
  const deleteZoneApi = useApi(zoneApi.deleteZone);

  useEffect(() => {
    if (zonePK) getZone();
    navigation.setOptions({
      title: zonePK ? I18n.t("home.zoneInfo") : I18n.t("home.zoneCreate"),
    });
  }, []);

  useEffect(() => {
    if (zone?.permission)
      navigation.setOptions({
        headerRight: () =>
          zone.permission.delete && (
            <AppIconButton
              name="delete-forever"
              color={colors.white}
              style={styles.delete}
              onPress={handleRemove}
              size={25}
            />
          ),
      });
  }, [zone]);

  const getZone = async () => {
    const response = await getZoneApi.request(zonePK);
    if (response.ok) setZone(response.data);
    else setZone(null);
  };

  if (zonePK && zone === null) return null;
  if (zonePK && zone === undefined) return <AppLoadingIndicator visible />;

  const handleSubmit = async ({ name }) => {
    const formData = new FormData();
    formData.append("name", name);
    if (zonePK) await updateZoneApi.request(zone.pk, formData);
    else await createZoneApi.request(familyPK, formData);
    handleClose();
  };

  const handleClose = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: routes.HOME }],
    });
  };

  const handleRemove = async () => {
    return AppAlert2B(
      I18n.t("confirm.deleteZoneTilte"),
      I18n.t("confirm.deleteZone"),
      () => deleteZone(),
      () => null
    );
  };

  const deleteZone = async () => {
    const response = await deleteZoneApi.request(zone.pk);
    if (response.ok) handleClose();
  };

  return (
    <AppForm
      initialValues={{
        name: zonePK ? zone.name : "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      submitOnBack={zonePK ? (zone.permission.edit ? true : false) : true}
      confirm={!zonePK}
    >
      <AppLoadingIndicator
        visible={createZoneApi.loading || updateZoneApi.loading}
      />
      <AppErrorMessage
        error={
          createZoneApi.error
            ? createZoneApi.data.error
            : updateZoneApi.error
            ? updateZoneApi.data.error
            : null
        }
        visible={createZoneApi.error || updateZoneApi.error}
      />
      <AppFormField
        name="name"
        iconName="location-on"
        placeholder={I18n.t("form.zoneName")}
        editable={zonePK ? zone.permission.edit : true}
      />
    </AppForm>
  );
}

const styles = StyleSheet.create({
  delete: {
    marginRight: 10,
  },
});

export default ZoneEdit;
