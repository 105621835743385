import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Linking,
  Platform,
} from "react-native";
import * as Yup from "yup";
import Constants from "expo-constants";

import AppScreen from "../../components/AppScreen";
import AppText from "../../components/AppText";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
  AppCheckField,
  AppErrorMessage,
  AppPickerField,
  AppPasswordField,
} from "../../components/forms";
import colors from "../../config/colors";
import userApi from "../../api/user";
import useApi from "../../hooks/useApi";
import countryApi from "../../api/country";
import routes from "../../config/routes";
import useAuth from "../../auth/useAuth";
import validations from "../../config/validations";
import appId from "../../identifier/appId";
import constants from "../../config/constants";
import I18n from "../../i18n";

function LoginScreen({ navigation }) {
  const validationSchema = Yup.object().shape({
    phoneNumber: validations.phoneNumber(),
    password: validations.password(),
    isAgree: validations.isAgree(),
  });

  const [countries, setCountries] = useState([]);
  const [selCountry, setSelCountry] = useState({});
  const { logIn } = useAuth();
  const loginApi = useApi(userApi.login);
  const getCountryListApi = useApi(countryApi.getCountryList);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    const response = await getCountryListApi.request();
    if (response.ok) {
      setSelCountry(response.data[0]);
      setCountries(response.data);
    }
  };

  const handleSubmit = async ({ phoneNumber, password, country }) => {
    const response = await loginApi.request({
      phone_number: phoneNumber,
      password: password,
      country: country.pk,
      app_id: await appId.getAppId(),
      device_name: Constants.deviceName,
      app_version: Constants.manifest.version,
      platform: Platform.OS,
    });
    if (response.ok) logIn(response.data.token);
  };

  if (countries.length === 0) return null;

  return (
    <AppScreen loading={loginApi.loading}>
      <AppForm
        initialValues={{
          phoneNumber: "",
          password: "",
          isAgree: false,
          country: countries[0],
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <AppText style={styles.title}>{I18n.t("auth.loginToAccount")}</AppText>
        <AppErrorMessage
          error={loginApi.error ? loginApi.data.error : null}
          visible={loginApi.error}
        />
        <AppPickerField
          name="country"
          placeholder="کشور"
          items={countries}
          nameLabel="name"
          indexLabel="pk"
          imageLabel="country_pic"
          onSelect={setSelCountry}
        />
        <AppFormField
          name="phoneNumber"
          iconName="phone"
          placeholder={I18n.t("form.phoneNumber")}
          keyboardType="number-pad"
          prefixLabel={selCountry.code}
          enDigit
        />
        <AppPasswordField
          name="password"
          iconName="lock"
          placeholder={I18n.t("form.password")}
        />
        <AppCheckField
          name="isAgree"
          title={
            <>
              {I18n.t("auth.agreement")}{" "}
              <AppText
                style={styles.review}
                onPress={() => Linking.openURL(constants.websiteUrlAgreement)}
              >
                {I18n.t("auth.rules")}
              </AppText>
              {" - "}
              <AppText
                style={styles.review}
                onPress={() => navigation.navigate(routes.PRIVACY)}
              >
                {I18n.t("auth.privacy")}
              </AppText>{" "}
              {I18n.t("auth.agreementOf")}
            </>
          }
        />

        <View style={styles.buttonsContainer}>
          <AppSubmitButton title={I18n.t("public.accept")} />
          <View style={styles.textContainer}>
            <AppText style={styles.text}>
              {I18n.t("auth.dontRemPassword")}
            </AppText>
            <TouchableOpacity
              onPress={() => navigation.navigate(routes.PASSWORD_RECOVER)}
            >
              <AppText style={styles.textButton}>
                {" "}
                {I18n.t("auth.passwordRecover")}
              </AppText>
            </TouchableOpacity>
          </View>

          <View style={styles.textContainer}>
            <AppText style={styles.text}>
              {" "}
              {I18n.t("auth.notRegistered")}
            </AppText>
            <TouchableOpacity
              onPress={() => navigation.navigate(routes.SIGNUP)}
            >
              <AppText style={styles.textButton}>
                {" "}
                {I18n.t("auth.registerNewUser")}
              </AppText>
            </TouchableOpacity>
          </View>
        </View>
      </AppForm>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  title: {
    marginBottom: 20,
    fontSize: 25,
    alignSelf: "flex-end",
  },
  buttonsContainer: {
    width: "100%",
    marginTop: 20,
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingVertical: 5,
  },
  text: {
    fontSize: 12,
  },
  textButton: {
    color: colors.primaryop,
    marginRight: 10,
    fontSize: 12,
  },
  review: {
    fontSize: 13,
    color: colors.primary,
    textDecorationLine: "underline",
  },
});

export default LoginScreen;
